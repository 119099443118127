import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,

} from "antd";
import moment from "moment";
import * as Main from '../../../core/Main';
import * as Service from "../../../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from '../../../t9UIComponents/t9Button';
import MediaLibraryComponent from "../../../components/MediaLibraryComponent";

const debug = debugLib("app:pages/company/banner/list/Modal");

const BannerPostModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [fileinfo, setFileinfo] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});

  const [BannerPostForm] = Form.useForm();

  useEffect(() => {
    setInitialFormValues({});
    setFileinfo({});
    BannerPostForm.resetFields();
    if (selectedRecord.banner_id > 0) {
      let data = {
        filename: selectedRecord.banner,
        filepath: selectedRecord.banner,
      };
      setFileinfo(data);
      setInitialFormValues(selectedRecord);
    }
  }, [selectedRecord]);

  useEffect(() => {
    BannerPostForm.resetFields();
  }, [initialFormValues]);

  return (
    <Modal
      title={
        selectedRecord.banner_id === 0 ? t("add_item") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={BannerPostForm}
        name="BannerPostForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          formData.item_feature_image = fileinfo.filename
            ? fileinfo.filename
            : null;
          formData.fileinfo = fileinfo.filename ? fileinfo : null;
          handleFormSubmit(formData);
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={t("banner")}
              name="banner"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <MediaLibraryComponent
                showImage
                selectCallback={async (mediaObj) => {
                  setFileinfo({
                    filename: mediaObj.filename,
                    filepath: mediaObj.filepath,
                  });
                  BannerPostForm.setFieldsValue({ banner: mediaObj.filepath });
                }}
                mediaObj={fileinfo}
              />
            </Form.Item>
            <Form.Item
              label={t("sorting")}
              name="sorting"
              rules={[{ required: true, message: t("select_required") }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label={t("is_active")}
              name="is_active"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <Radio.Group>
                <Radio value={1}>Active</Radio>
                <Radio value={0}>not Active </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BannerPostModal;
