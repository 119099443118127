import React, { useState, useEffect } from "react";
import {
  Spin,
  Table,
  Tag,
  Form,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  filter,
  isEmpty,
  keyBy,
  map,
} from "lodash";
import dayjs from "dayjs";
import SearchBar from "../../../components/SearchBar";
import * as Main from "../../../core/Main";

const tableIDName = "company_user_booking";
const FILTERABLE_FIELDS = ["item_name", "item_id"];

const EventUserList = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filterDataList, setFilterDataList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const { orderItemList, checkInList } = props;

  useEffect(() => {
    if (!isEmpty(orderItemList)) {
      const filterList = filter(orderItemList, (rc) => rc.item_type === Main.ITEM_TYPE.EVENT);
      const keyByCheckInList = keyBy(checkInList, "event_enrollment_id");
      const dataSource = map(filterList, (eachOrderItem) => {
        const {
          item_id
        } = eachOrderItem;
        const checkInRc = keyByCheckInList[item_id];
        return {
          ...eachOrderItem,
          check_in_status: !!checkInRc,
          check_in_time: checkInRc ? checkInRc.check_in_time : null,
        };
      });
      setDataList(dataSource);
      setFilterDataList(dataSource);
    }
  }, [props]);

  const columns = [
    {
      title: t("id"),
      dataIndex: "item_id",
      sorter: (a, b) => a.item_id - b.item_id
    },
    // {
    //   title: t("status"),
    //   dataIndex: "status",
    //   render: (value, record, index) => {
    //     const item_id = record.item_id;
    //     const enrollment = enrollmentMap[item_id];
    //     return displayStatus(enrollment ? enrollmentMap[item_id].status : " ");
    //   },
    // },
    {
      title: t("event_name"),
      dataIndex: "item_name",
    },
    {
      title: t("event_date_time"),
      render: (value, record, index) => {
        return dayjs.unix(record.start_time).format("YYYY-MM-DD HH:mm:ss")
          + " - " + dayjs.unix(record.end_time).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    {
      title: t("no_of_participant"),
      dataIndex: "quantity",
    },
    {
      title: t("check_in"),
      dataIndex: "check_in_status",
      render: (value) => {
        const displayStr = value ? t("check_in") : t("not_check_in");
        const tagColor = value ? "green" : "red";
        return <Tag color={tagColor}>{displayStr}</Tag>;
      },
    },
    {
      title: t("check_in_time"),
      dataIndex: "check_in_time",
      render: (value) => {
        if (!value) return "-";
        return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
      }

    },
    // {
    //   title: t("update_time"),
    //   dataIndex: "utime",
    //   render: (value) => {
    //     return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    //   },
    // },
    {
      title: t("create_time"),
      dataIndex: "ctime",
      render: (value) => {
        return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  return (
    <Form>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} align="middle">
          <Col>{t("search_keywords")}</Col>
          <Col>
            <SearchBar
              originalDataList={dataList}
              filterDataList={dataList}
              setDataList={setFilterDataList}
              filterField={FILTERABLE_FIELDS}
              placeholder="search_keywords"
              suffix={<SearchOutlined style={{ color: "#888888" }} />}
            />
          </Col>
        </Row>
        <Table
          rowKey={tableIDName}
          loading={loading}
          columns={columns}
          dataSource={filterDataList}
          pagination
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </Form>

  );
};
export default EventUserList;
