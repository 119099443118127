import React, { Component, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Form,
  Input,
  Tag,
  Row,
  Col,
  Card,
  message,
  notification
} from "antd";
import { isEmpty } from "lodash";
import { QrReader } from "react-qr-reader";

const EventQRReader = (props) => {
  const [data, setData] = useState("No result");
  const { onScan, scanDelay, facingMode } = props;

  return (
    <div className="container" style={{ width: "100%" }}>
      <div className="QRtitle" style={{ margin: "20px 0" }}>Scan to Check-in</div>
      <div style={{
        width: "100%",
        marginBottom: "50px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

      }}
      >
        <QrReader
          videoContainerStyle={{
            width: "100%",
            border: "10px solid #1E73BE",
            borderRadius: "15px",

          }}
          videoStyle={{
            width: "100% ",
            objectFit: "cover"

          }}
          constraints={{
            facingMode,
          }}
          onResult={(result, error) => {
            if (isEmpty(result)) {
              return;
            }
            const {
              text = "",
            } = result;

            if (!text) {
              return;
            }
            onScan(text);
          }}
          scanDelay={scanDelay}
        />
      </div>
    </div>

  );
};

export default EventQRReader;
