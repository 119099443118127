import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  TimePicker,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useSelector } from "react-redux";
import CompanyUserBookingForm from "./CompanyUserBookingForm";
import MediaLibraryComponent from "../../../components/MediaLibraryComponent";
import TinymceWrapper from "../../../components/TinymceWrapper";
import CategoryCascader from "../../../components/CategoryCascader";
import * as Service from "../../../core/Service";

const CompanyUserBookingModal = (props) => {
  const { t } = useTranslation();
  const title = t("booking_now");
  const config = useSelector((state) => state.app.config);
  const {
    selectedRecord, modalVisible, setModalVisible, handleFormSubmit, openModal
  } = props;
  const [CategoryInfoForm] = Form.useForm();

  return (
    <Modal
      title={title}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        props.openModal(false);
      }}
      width="90%"
      style={{ maxWidth: 800 }}
    >
      <CompanyUserBookingForm
        selectedRecord={selectedRecord}
        openModal={openModal}
        modalVisible={modalVisible}
      />
    </Modal>
  );
};

export default CompanyUserBookingModal;
