/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Form,
  Spin,
  Row,
  Col,
  Divider,
  Space,
  Table,
  Radio,
  message,
  Dropdown,
  Menu,
  Button,
  notification,
} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import * as Service from "../../../core/Service";
import COLOR from "../../../theme/color";
import { FONT } from "../../../theme";
import FormUploadFile from "../../../components/form/FormUploadFile";
import { openNotificationWithIcon } from "../../../components/form/ErrorMessage";
import defaultImage from "../../../assets/empty_image.png";

const OrderApprovementDocument = (props) => {
  const {
    modalVisible, setModalVisible, selectedRecord, refreshOrderList
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const config = useSelector((state) => state.app.config);
  const [imgSrc, setImgSrc] = useState("");
  const [orderInfoRc, setOrderInfoRc] = useState({
    orderRc: {},
    orderItemList: [],
  });
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(0);
  const [imageURL, setImageURL] = useState({});

  const getConfigFilePath = (filepath) => {
    if (_.isNil(filepath) || filepath === "") return null;
    let path = config.STATIC_SERVER_URL + "/" + filepath;
    return path;
  };

  useEffect(() => {
    setImgSrc("");
  }, [modalVisible]);

  useEffect(() => {
    form.resetFields();
    getAllData();
  }, [selectedRecord]);

  useEffect(() => {
    if (orderStatus === "placed") {
      getPaymentMethod();
    }
  }, [orderStatus]);

  const getAllData = async () => {
    try {
      setLoading(true);
      let { order_id } = selectedRecord;
      if (_.isUndefined(order_id) || order_id === 0) {
        return;
      }
      let resp = await Service.call("get", `/api/order/info/${order_id}`);
      const { orderRc, orderItemArr } = resp.data;
      let childOrderItemArr = _.remove(orderItemArr, (value) => {
        return value.parent_order_item_id !== 0;
      });
      let keyByIdOrderItemArr = _.keyBy(orderItemArr, "order_item_id");
      let groupByParentOrderItem = {};
      if (!_.isEmpty(childOrderItemArr)) {
        groupByParentOrderItem = _.groupBy(
          childOrderItemArr,
          "parent_order_item_id"
        );
      }
      _.each(Object.keys(keyByIdOrderItemArr), (eachKey) => {
        keyByIdOrderItemArr[eachKey] = {
          ...keyByIdOrderItemArr[eachKey],
          bookingProduct: groupByParentOrderItem[eachKey] || [],
        };
      });
      setOrderInfoRc({
        orderRc,
        orderItemList: Object.values(keyByIdOrderItemArr),
      });
      let { filepath, status } = orderRc;
      if (!_.isEmpty(filepath)) {
        setImgSrc(`${config.ADMIN_ORIGIN}/${filepath}`);
      } else {
        setImgSrc("");
      }
      setOrderStatus(status);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPaymentMethod = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", `/api/company/payment/method`);
      if (resp && resp.status === 1) {
        const { companyPaymentMethodList } = resp.data;
        const activePaymentOptions = _.filter(companyPaymentMethodList, [
          "is_active",
          1,
        ]);
        setPaymentOptions(activePaymentOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentOptionChange = (e) => {
    const payment = e.target.value;
    setSelectedPaymentOption(payment);
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("items_name"),
        dataIndex: "item_name",
        align: "left",
      },
      {
        title: t("booking_products"),
        dataIndex: "bookingProduct",
        align: "left",
        render: (value) => {
          return _.join(
            _.map(value, (eachBookingProduct) => {
              return `${eachBookingProduct.quantity} x ${eachBookingProduct.item_name}`;
            }),
            ", "
          );
        },
      },
      {
        title: t("booking_items_date"),
        dataIndex: "item_desc",
        align: "left",
      },
    ];
    return columns;
  };

  const setPaymentTableHeader = () => {
    const columns = [
      {
        title: t("payment_method"),
        dataIndex: "payment_name",
        align: "left",
      },
      {
        title: t("price"),
        dataIndex: "ptx_final_price",
        align: "left",
      },
      {
        title: t("payment_confirmed_date"),
        dataIndex: "ptx_complete_time",
        align: "left",
        render: (value) => {
          if (!value) return "-";
          return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    ];
    return columns;
  };

  const setPaymentOptionTableHeader = () => {
    const columns = [
      {
        title: t("select_payment_method"),
        align: "left",
        render: () => (
          <div
            style={{
              borderRadius: 4,
              border: `1px solid ${COLOR.border}`,
              padding: 24,
            }}
          >
            <Radio.Group
              onChange={handlePaymentOptionChange}
              value={selectedPaymentOption}
              required
            >
              <Space direction="vertical">
                {_.map(paymentOptions, (option) => (
                  <Radio
                    key={option.company_payment_method_id}
                    value={option.company_payment_method_id}
                  >
                    {option.payment_name}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        ),
      },
    ];
    return columns;
  };

  const displayStatusBar = (status) => {
    let textColor = COLOR.text.captionText;
    let barColor = COLOR.disableBorder;
    switch (status) {
      case "placed":
        if (
          _.includes(
            ["placed", "pending", "pending_approve", "payment_confirmed"],
            orderStatus
          )
        ) {
          textColor = COLOR.text.primaryText;
          barColor = COLOR.brandPrimary;
        }
        break;
      case "pending":
        if (
          _.includes(
            ["pending", "pending_approve", "payment_confirmed"],
            orderStatus
          )
        ) {
          textColor = COLOR.text.primaryText;
          barColor = COLOR.brandPrimary;
        }
        break;
      case "confirmed":
        if (_.includes(["payment_confirmed"], orderStatus)) {
          textColor = COLOR.text.primaryText;
          barColor = COLOR.brandPrimary;
        }
        break;
      default:
        break;
    }
    return (
      <>
        <div style={{ height: 4, background: barColor }} />
        <div style={{ ...FONT.titleText, color: textColor, marginTop: 12 }}>
          {_.toUpper(t(status))}
        </div>
      </>
    );
  };

  const onImageFileChange = async (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      if (response.status > 0) {
        openNotificationWithIcon("success", t("common:upload_success"), "");

        message.success(t("uploaded"));

        let { filepath, filename } = info.file.response;

        setImageURL({ filepath, filename });
        // setFileList(info);
      }
    }
  };

  const getStatus = (status, key) => {
    if (key === "cancelled") {
      if (status === "payment_confirmed") {
        return "payment_refund";
      }
      return key;
    }
    switch (status) {
      case "placed":
        return "pending_approve";
      case "pending":
      case "pending_approve":
        return "payment_confirmed";

      case "payment_confirmed":
        return "payment_refund";
      default:
        return null;
    }
  };

  const confirmSubmit = async (key) => {
    try {
      setLoading(true);
      let url = `/api/order/status`;
      if (!selectedPaymentOption && orderStatus === "placed" && key !== "cancelled") {
        return message.error(t("select_required"));
      }
      const patchObj = {
        order_id: selectedRecord.order_id,
        status: getStatus(orderStatus, key),
        token_used: orderInfoRc.orderRc.token_used || 0,
        company_payment_method_id:
          orderInfoRc.orderRc.payment_method_id || selectedPaymentOption || 0,
        filepath: imageURL.filepath || "",
      };
      let resp = await Service.call("patch", url, patchObj);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      setModalVisible(false);
      refreshOrderList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderMenuItems = () => {
    const item = { label: t("cancel"), key: "cancelled" };
    return (
      <Menu className="menu-dropdown-btn">
        <Menu.Item
          disabled={!_.includes(
            [
              "payment_confirmed",
              "payment_refund",
              "placed",
              "pending",
              "payment_refund_token",
              "payment_refund_offline"
              // "pending_approve",
              // "expired",
              // "cancelled",
              // "payment_failed",
            ],
            orderStatus
          )}
          onClick={() => confirmSubmit(item.key)}
        >
          {item.label}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col span={8}>{displayStatusBar("placed")}</Col>
        <Col span={8}>{displayStatusBar("pending")}</Col>
        <Col span={8}>{displayStatusBar("confirmed")}</Col>
      </Row>
      <Row style={{ margin: "0 0 8px", fontSize: "20px" }}>
        <Col style={{ fontWeight: "700", margin: "0 5px" }}>
          {t("order_key_title")}
        </Col>
        <Col style={{ color: "#1E73BE" }}>{orderInfoRc.orderRc.order_key}</Col>
      </Row>

      <Row style={{ margin: "0 0 21px" }}>
        <Col style={{ margin: "0 5px", color: "#828282" }}>
          {t("order_created_date")}
        </Col>
        <Col style={{ color: "#090909" }}>
          {dayjs.unix(orderInfoRc.orderRc.ctime).format("YYYY-MM-DD")}
        </Col>
      </Row>
      <div
        style={{
          ...FONT.bodyText1Bold,
          color: COLOR.text.primaryText,
          margin: "24px 0",
        }}
      >
        {t("item_detail")}
      </div>
      <Table
        rowKey="order_item_id"
        columns={setTableHeader()}
        dataSource={orderInfoRc.orderItemList}
        footer={null}
        pagination={false}
        bordered={false}
        scroll={{
          x: "max-content",
        }}
      />
      {orderStatus === "placed" ? (
        <Table
          className="order-list-payment-option-table"
          rowKey="payment_name"
          columns={setPaymentOptionTableHeader()}
          dataSource={[{}]}
          footer={null}
          pagination={false}
          bordered={false}
          scroll={{
            x: "max-content",
          }}
        />
      ) : (
        <Table
          rowKey="payment_name"
          columns={setPaymentTableHeader()}
          dataSource={orderInfoRc.orderRc.paymentRecordList || []}
          footer={null}
          pagination={false}
          bordered={false}
          scroll={{
            x: "max-content",
          }}
        />
      )}

      <div
        style={{
          ...FONT.bodyText1Bold,
          color: COLOR.text.primaryText,
          margin: "24px 0",
        }}
      >
        {t("order_summary")}
      </div>
      <Row
        justify="space-between"
        style={{ fontWeight: "700", margin: "0 0 21px" }}
      >
        <Col span={8}>{t("sub_total")}</Col>
        <Col style={{ textAlignLast: "end" }} span={8}>
          {`$${orderInfoRc.orderRc.original_price}`}
        </Col>
      </Row>

      <Row justify="space-between" style={{ margin: "0 0 21px" }}>
        <Col span={8}>{t("discount")}</Col>
        <Col style={{ textAlignLast: "end" }} span={8}>
          {`$${orderInfoRc.orderRc.discount + orderInfoRc.orderRc.order_discount}`}
        </Col>
      </Row>

      <Row justify="space-between" style={{ margin: "0 0 21px" }}>
        <Col span={8}>{t("token_used")}</Col>
        <Col style={{ textAlignLast: "end" }} span={8}>
          {`$${orderInfoRc.orderRc.token_used}`}
        </Col>
      </Row>

      <Divider />
      <Row justify="space-between">
        <Col span={8}>{t("total_price")}</Col>
        <Col
          style={{ textAlignLast: "end", color: "#1E73BE", fontWeight: "700" }}
          span={8}
        >
          {`$${orderInfoRc.orderRc.final_price}`}
        </Col>
      </Row>

      <Divider />
      <Row>
        <Col>
          <Space>
            {`${t("client_upload_image")}:`}
            {!_.isEmpty(imgSrc)
              ? orderInfoRc.orderRc.filename
              : t("no_upload_media")}
          </Space>
        </Col>
      </Row>
      {!_.isEmpty(imgSrc) && (
        <>
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <img
              src={imgSrc}
              alt="receipt_img"
              style={{
                aspectRatio: 1,
                objectFit: "contain",
                width: "-webkit-fill-available",
              }}
            />
          </div>
        </>
      )}
      <Divider />
      <Row>
        <Col>
          <Space>
            {`${t("admin_upload_image")}:`}
            {imageURL.filename}
          </Space>
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginTop: 16 }}>
        <FormUploadFile
          key="image_upload"
          type="one"
          imageURL={getConfigFilePath(imageURL.filepath) || defaultImage}
          onChange={(info) => onImageFileChange(info)}
          containerStyle={{
            border: "1px solid #B0AFAF",
            minHeight: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        />
      </div>
      <Row justify="end" style={{ marginTop: 24 }}>
        <Col className="confirm-dropdown-btn">
          {orderStatus === "payment_confirmed" ? (
            <Button
              style={{
                borderRadius: "4px",
                overflow: "auto",
                width: "100px",
                background: "#308dde",
                color: "white",
              }}
              onClick={() => confirmSubmit()}
            >
              {t("refund")}
            </Button>
          ) : (
            <Dropdown.Button
              disabled={!_.includes(
                [
                  "placed",
                  "pending",
                  "pending_approve",
                  "payment_confirmed"
                ],
                orderStatus
              )}
              loading={loading}
              icon={<DownOutlined />}
              overlay={renderMenuItems()}
              onClick={() => confirmSubmit()}
            >
              {t("confirm")}
            </Dropdown.Button>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default OrderApprovementDocument;
