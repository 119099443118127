import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  notification,
  Spin
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { formItemLayout, tailLayout } from "../../../components/ModalLayout";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";

const EventAttendanceReportModal = (props) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [periodList, setPeriodList] = useState([]);
  const [groupByList, setGroupList] = useState([]);
  const [keyByList, setKeyByList] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await Service.call("get", "/api/event");
      console.log("resp >>>", resp);
      if (resp.status === 1) {
        setEventList(resp.data);
        const groupbyPeriodList = _.groupBy(resp.period, "event_id");
        setGroupList(groupbyPeriodList);
        setKeyByList(_.keyBy(resp.period, "event_date_id"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (postObj) => {
    let clonePostObj = _.clone(postObj);
    await generateEventAttendanceReport(clonePostObj);
  };

  const generateEventAttendanceReport = async (postObj) => {
    try {
      // let event_date_id = selectedTime[0];
      const { event_id, event_date_id} = postObj;
      let resp = await Service.call(
        "get",
        `/api/report/event/attendance?event_id=${event_id}&event_date_id=${event_date_id}`
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }


      let dataList = _.map(resp.data, (eachRecord) => {
        let {
          email,
          nickname,
          reference_id,
          university_name,
          programme_name,
          study_mode,
          guest_number,
          seat_number,
          prize_winner,
          remarks,
          check_in_status,
          check_in_time,
        } = eachRecord;

        return {
          email,
          nickname,
          reference_id,
          university_name,
          programme_name,
          study_mode,
          guest_number,
          seat_number,
          prize_winner,
          remarks,
          check_in_status: check_in_status ? "Y" : "N",
          check_in_time: check_in_status ? moment.unix(check_in_time).format("YYYY-MM-DD HH:mm:ss") : 0,
        };
      });

      if (_.isEmpty(dataList)) {
        return notification.info({
          message: t("info"),
          description: t("no_record"),
        });
      }
      Main.exportCsvFile(dataList, [[selectedEvent, selectedTime, "", "", "", "", "", "", "", "", "", ""],
        [
          "Email",
          "Student Name",
          "Student ID",
          "University Name",
          "Programme Name",
          "Study Mode",
          "Guest Number",
          "Seat Number",
          "Prize Winner",
          "Special Arrangement",
          "Check in Status",
          "Check in Time"
        ]
      ], "AttendanceReport", "A3");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        name="event_related_controls"
        onFinish={onFinish}
      >

        <Form.Item
          label={t("event_name")}
          name="event_id"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Select
            showSearch
            placeholder="Select an Event"
         //   optionFilterProp="event_name"
            onChange={(value, option) => {
              form.resetFields(["event_date_id"]);
              setSelectedEvent(option.name);
              const selectedPeriodList = groupByList[value];
              setPeriodList(selectedPeriodList);
            }}
            filterOption={(input, option) => (option?.name ?? "").toString().toLowerCase().includes(input.toLowerCase())}
          >
            {_.map(eventList, (eachEvent) => {
              const {
                event_id,
                event_name
              } = eachEvent;
              return (
                <Option
                  key={event_id}
                  value={event_id}
                  name={event_name}
                >
                  {event_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("event_time")}
          name="event_date_id"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Select
            disabled={_.isNil(selectedEvent)}
            mode="multiple"
            allowClear
            placeholder="Select Timeslot(s)"
            onChange={(value) => {
              setSelectedTime(value);
              console.log("selectedTime >>>>", value);

              // Single value
              if (!_.isArray(value)) {
                const period = keyByList[value];
                if (!_.isEmpty(period)) {
                  console.log(period.event_start_date + "   " + period.event_end_date);
                  setSelectedTime(dayjs.unix(period.event_start_date).format("YYYY-MM-DD HH:mm:ss") + " - " + dayjs.unix(period.event_end_date).format("YYYY-MM-DD HH:mm"));
                }
              } else {
                // multiple value
                const rs = _.map(value, (dateId) => {
                  const period = keyByList[dateId];
                  let startTime = dayjs.unix(period.event_start_date).format("YYYY-MM-DD HH:mm:ss");
                  let endTime = dayjs.unix(period.event_end_date).format("YYYY-MM-DD HH:mm:ss");
                  return `${startTime} - ${endTime}`;
                }).join(", ");

                setSelectedTime(rs);
              }
            }}
          >
            {_.map(periodList, (eachPeriod) => {
              const {
                event_date_id,
                event_start_date,
                event_end_date,
                wholePeriod = dayjs.unix(event_start_date).format("YYYY-MM-DD HH:mm:ss") + " - " + dayjs.unix(event_end_date).format("YYYY-MM-DD HH:mm")
              } = eachPeriod;
              // console.log("eachPeriod >>>", eachPeriod);
              return (
                <Option key={event_date_id} value={event_date_id} name={wholePeriod}>
                  {wholePeriod}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          {...tailLayout}
          style={{ marginTop: 20, width: "100%", textAlign: "center" }}
          wrapperCol={{ offset: 0 }}
        >
          <Button type="primary" htmlType="submit">
            Export
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EventAttendanceReportModal;
