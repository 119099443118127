import React, { useState, useEffect, useRef } from "react";
import {
  Divider,
  notification,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import OrderInfoTable from "./OrderInfoTable";
import useFetch from "../../../hooks/useFetch";
import OrderApprovementDocument from "./OrderApprovementDocument";
import useQuery from "../../../hooks/useQuery";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "order_list";
const openKeys = "sales";

const { RangePicker } = DatePicker;
const { Option } = Select;

const OrderInfoList = (props) => {
  const query = useQuery();
  const queryOrderId = +query.get("order_id");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedField, setSelectedField] = useState({
    user: null,
    date: [
      moment().subtract(1, "M").startOf("d"),
      moment(),
    ],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    route: "/api/order/list",
    whereClause: {
      start_time: _.head(selectedField.date).unix(),
      end_time: _.last(selectedField.date).unix(),
      offset: (currentPage - 1) * recordPerPage,
      company_user_id: selectedField.user,
      pagination_size: recordPerPage,
    }
  });

  const { t } = useTranslation();

  const title = t("order_list");

  useEffect(() => {
    if (queryOrderId) {
      setSelectedRecord({ order_id: queryOrderId });
      setModalVisible(true);
    }
  }, [queryOrderId]);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[50, 20]}>
        <Col>
          <span>{`${t("date")}：`}</span>
          <RangePicker
            allowClear={false}
            allowEmpty={false}
            placeholder={[t("start"), t("end")]}
            value={selectedField.date}
            onChange={(dates) => {
              setSelectedField({
                ...selectedField,
                date: dates,
              });
              setCurrentPage(1);
              setRefresh(true);
            }}
          />
        </Col>
        <Col>
          <span>{`${t("company_user")}：`}</span>
          <Select
            allowClear
            value={selectedField.user}
            style={{ width: 400 }}
            onChange={(value) => {
              setSelectedField({
                ...selectedField,
                user: value,
              });
              setCurrentPage(1);
              setRefresh(true);
            }}
          >
            {_.map(dataList.companyUserList, (eachUser) => {
              let {
                company_user_id,
                nickname,
                first_name,
                last_name,
              } = eachUser;
              return (
                <Option key={eachUser.company_user_id} value={eachUser.company_user_id}>
                  {`(${company_user_id}) ${first_name} ${last_name} (${nickname})`}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Divider />
      <OrderInfoTable
        dataObj={dataList}
        adminChangeData={() => {
          setRefresh(true);
        }}
        setLoading={setLoading}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading || refresh}
        currentPage={currentPage}
        setCurrentPage={(page, pageSize) => {
          setCurrentPage(page);
          setRecordPerPage(pageSize);
          setRefresh(true);
        }}
        pagination_size={recordPerPage}
      />
      <Modal
        title={<div style={{ margin: "0 0 0 40px", fontWeight: "bolder" }}>Order List</div>}
        footer={null}
        visible={modalVisible}
        bodyStyle={{ margin: "0 30px" }}
        width={1200}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRecord({});
        }}
        destroyOnClose
      >
        <OrderApprovementDocument
          setModalVisible={setModalVisible}
          selectedRecord={selectedRecord}
          openModal={(value) => {
            setModalVisible(value);
          }}
          modalVisible={modalVisible}
          refreshOrderList={() => setRefresh(true)}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default OrderInfoList;
