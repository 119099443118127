import React, { useState, useEffect } from "react";
import { Row, Col, InputNumber, Grid } from "antd";
import _ from "lodash";
import COLOR from "../../theme/color";
import minusIcon from "../../assets/remove.svg";
import plusIcon from "../../assets/plus.svg";

const { useBreakpoint } = Grid;

const ProductButton = ({
  setSelectedProductList,
  selectedProduct = {},
  selected,
  item,
}) => {
  const [inputNumber, setInputNumber] = useState(null);
  const { product_item_id, price, product_name, ignore_stock, stock_number } =
    item;

  useEffect(() => {
    setInputNumber(selectedProduct.quantity || 0);
  }, []);
  useEffect(() => {
    if (selected && inputNumber > 0) {
      const newItems = { ...item, quantity: inputNumber };
      let newSelectedProductList = [];
      setSelectedProductList((prev) => {
        const newArr = _.reject(prev, { product_item_id });
        newSelectedProductList = _.orderBy(
          [...newArr, newItems],
          ["product_item_id"],
          ["asc"]
        );
        return newSelectedProductList;
      });
    }
  }, [inputNumber]);

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        borderRadius: 4,
        border: "1px solid #DCDCDC",
        padding: "0 16px",
        marginTop: 16,
      }}
    >
      <Col span={10} style={{ color: COLOR.text.blackText }}>
        {product_name}
      </Col>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{ marginRight: 14, color: COLOR.text.captionText }}
        >{`+ $${_.multiply(price, inputNumber)}`}</span>
        <span>
          <QuantityInput
            inputNumber={inputNumber}
            setInputNumber={setInputNumber}
            plusRuleSet={ignore_stock || inputNumber < stock_number}
            max={!ignore_stock ? stock_number : undefined}
            productButton
          />
        </span>
      </Col>
    </Row>
  );
};

export const QuantityInput = (props) => {
  const {
    inputNumber,
    setInputNumber,
    plusRuleSet,
    removeRuleSet,
    min,
    max,
    style = {},
  } = props;

  const screens = useBreakpoint();
  const isMobile = !screens.md;

  const inputNumberFunction = (type) => {
    if (type === "plus" && plusRuleSet) {
      setInputNumber((prev) => prev + 1);
      return;
    }
    if (type === "remove" && (removeRuleSet || inputNumber !== 0)) {
      setInputNumber((prev) => prev - 1);
    }
  };

  return (
    <Row
      gutter={16}
      justify="space-around"
      style={{
        margin: "10px 0",
        borderRadius: 10,
        ...style,
      }}
      className="product-button-quantity-input"
    >
      <Col
        onClick={(e) => {
          e.stopPropagation();
          inputNumberFunction("remove");
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img src={minusIcon} alt="" />
      </Col>
      <Col style={{ maxWidth: isMobile && 60 }}>
        <InputNumber
          onClick={(e) => e.stopPropagation()}
          bordered={false}
          onChange={(value) => {
            setInputNumber(_.isInteger(value) ? value : 0);
          }}
          min={min || 0}
          max={max}
          value={inputNumber}
          style={{
            textAlign: "center",
            width: "100%",
            backgroundColor: "transparent",
            color: isMobile ? COLOR.text.blackText : COLOR.text.captionText,
          }}
        />
      </Col>
      <Col
        onClick={(e) => {
          e.stopPropagation();
          inputNumberFunction("plus");
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img src={plusIcon} alt="" />
      </Col>
    </Row>
  );
};

export default ProductButton;
