import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Input,
  notification,
  Row,
  Col,
  PageHeader,
  Spin,
  InputNumber,
} from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import TinymceWrapper from "../TinymceWrapper";

const debug = require("debug")("app:admin:client:src:EventInfo");

const { TabPane } = Tabs;
const RegistrationStatus = { APPROVE: "approve", REJECT: "reject" };
const title = "Add Category";
const selectedKey = "event";
const openKeys = "event_management";

const EventCategoryForm = (props) => {
  const { dataObj, onSuccess = () => { } } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [resetForm, setResetForm] = useState(true);
  const [description, setDescription] = useState(null);

  const onFinish = async (formObj) => {
    try {
      setLoading(true);
      const action = _.isEmpty(dataObj) ? "put" : "patch";
      const url = "/api/event/category";
      let obj = {
        ...formObj,
        description,
      };
      const resp = await Service.call(action, url, obj);
      if (resp.status === 1) {
        notification.success({
          message: t("success"),
        });
        onSuccess();
        // clear form
        setDescription(null);
        form.resetFields();
        return;
      }
      notification.error({
        message: t("failed"),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setResetForm(true);
  }, [dataObj]);

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
      setDescription(null);
      if (!_.isEmpty(dataObj)) {
        // console.log(dataObj);
        form.setFieldsValue(dataObj);
        setDescription(dataObj.description);
      }
      setResetForm(false);
    }
  }, [resetForm]);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" form={form} name="form" onFinish={onFinish}>
        <Form.Item noStyle name="event_category_id" />
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <Form.Item
              label={t("event_category_name")}
              name="title"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <Input placeholder={t("static_content")} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              // name="description"
              label={t("event_category_description")}
            >
              <TinymceWrapper
                initialValue={description}
                onChange={setDescription}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("sorting")}
              name="sorting"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <InputNumber placeholder={0} min="0" />
            </Form.Item>
          </Col>
        </Row>

        <Space>
          <Button onClick={() => setResetForm(true)}>{t("reset")}</Button>
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit">
              {t("submit")}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Spin>
  );
};

export default EventCategoryForm;
