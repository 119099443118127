import React, { useEffect, useState } from "react";
import { Button, Calendar, Col, Modal, notification, Row, Space } from "antd";
import calender_hk from "antd/es/date-picker/locale/zh_CN"; // set calender language
import calender_en from "antd/es/date-picker/locale/en_US"; // set calender language
import { useTranslation } from "react-i18next";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import COLOR from "../../theme/color";
import dayjs from "dayjs";
import _ from "lodash";
import * as Service from "../../core/Service";
import ProductButton, { QuantityInput } from "./ProductButton";
import { LoadingOutlined } from "@ant-design/icons";

const CalendarTimeSlotModal = ({
  type,
  currentIndex,
  modalVisible,
  setModalVisible,
  setConfirmTimeSlot,
  confirmTimeSlot = [],
  selectedTimeSlot = {},
  selectedRoom,
  date,
}) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(calender_en);
  const [selectedDate, setSelectedDate] = useState(date.endOf("day")); // Calendar
  const [nextYearIndex, setNextYearIndex] = useState();
  const [timeslot, setTimeslot] = useState([]);
  const [isCalenderConfirm, setIsCalenderConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(selectedTimeSlot);
  const [productList, setProductList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState(
    selectedTimeSlot.bookingProduct || []
  );

  const [capacityAmount, setCapacityAmount] = useState(selectedTimeSlot?.capacity || 1);
  const [avaCapacity, setAvaCapacity] = useState(null);

  useEffect(() => {
    if (i18n.language === "hk") {
      moment.locale("zh-hk");
      setLocale(calender_hk);
    } else {
      moment.locale("en-nz");
      setLocale(calender_en);
    }
  }, [i18n.language]);

  useEffect(() => {
    getBookingTimeslotDetail();
  }, [selectedDate, selectedRoom]);

  useEffect(() => {
    getCapacityAvailability();
  }, [timeslot, selectedTime]);

  const resetCalendar = () => {
    setSelectedTime({});
    setSelectedProductList([]);
    setSelectedDate(moment().endOf("day"));
  };

  const getBookingTimeslotDetail = async () => {
    try {
      setLoading(true);
      const resp = await Service.call(
        "post",
        "/api/booking_info/timeslot/detail",
        {
          booking_item_id: _.toInteger(selectedRoom),
          start_time: selectedDate.startOf("day").unix(),
          end_time: selectedDate.endOf("day").unix(),
        }
      );

      if (resp?.status === 1) {
        const { bookingProductArr, timeslot } =
          resp.data;
        setProductList(bookingProductArr);
        setTimeslot(timeslot);
        setIsCalenderConfirm(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current <= moment().subtract(1, "d").endOf("day");
  };

  const onPanelChange = (value) => {
    setSelectedDate(moment(value).endOf("day"));
    setSelectedTime({});
  };

  const checkTimeslotAvailability = (id, start_time, end_time, status) => {
    if (end_time < dayjs().unix()) {
      return notification.error({ message: t("booking_is_expired") });
    }
    if (!status) {
      return notification.error({ message: t("booking_is_full") });
    }
    setSelectedTime({ id, start_time, end_time });
  };


  const getCapacityAvailability = () => {
    const { capacity, usedCapacity } =
      _.find(timeslot, (time) => time?.id === selectedTime?.id) || {};
    const avaCapacity = capacity - usedCapacity || 0;
    return setAvaCapacity(avaCapacity);
  };

  const handleAddTimeSlot = () => {
    const newItems = { ...selectedTime, bookingProduct: selectedProductList, capacity: capacityAmount };
    let newSelectedTimeSlotList = [];
    setConfirmTimeSlot((prev) => {
      const newArr = _.isUndefined(currentIndex)
        ? prev
        : _.filter(prev, (item) => prev.indexOf(item) !== currentIndex);

      newSelectedTimeSlotList = _.orderBy(
        [...newArr, newItems],
        ["start_time"],
        ["asc"]
      );

      return newSelectedTimeSlotList;
    });
  };

  const headerRender = ({ value, onChange }) => {
    let months = [];
    let monthsRequired = 12;

    for (let i = 0; i < monthsRequired; i++) {
      months.push(moment().add(i, "months").format("MMM"));
    }

    // const thisYear = moment().endOf("day").format("YYYY");
    // const nextYear = moment().endOf("day").add(1, "year").format("YYYY");

    months.find((month, index) => {
      if (
        month ===
        moment().endOf("day").add(1, "year").set("month", 0).format("MMM")
      ) {
        setNextYearIndex(index);
      }
    });

    return (
      <>
        <Row
          justify="space-between"
          style={{ fontWeight: "bold", marginBottom: 12 }}
        >
          <Col
            onClick={() => {
              const newValue = value.endOf("day").subtract(1, "month");
              if (newValue < moment().endOf("day")) {
                onChange(moment().endOf("day"));
              } else {
                onChange(newValue);
              }
            }}
          >
            <Space>
              <LeftOutlined />
              {t("last")}
            </Space>
          </Col>
          <Col>{selectedDate.format("MMMM YYYY")}</Col>
          <Col
            onClick={() => {
              const newValue = value.endOf("day").add(1, "month");
              onChange(newValue);
            }}
          >
            <Space>
              {t("next")}
              <RightOutlined />
            </Space>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Modal
      className="calendar-timeslot-modal"
      visible={modalVisible}
      onCancel={() => {
        type === "add" && resetCalendar();
        setModalVisible(false);
      }}
      footer={null}
    >
      <Calendar
        className="booking-calendar"
        fullscreen={false}
        locale={locale}
        value={selectedDate}
        disabledDate={disabledDate}
        onSelect={onPanelChange}
        headerRender={headerRender}
        style={{ marginTop: 32 }}
      />
      {
        loading ? (
          <Row justify="center">
            <LoadingOutlined style={{ fontSize: "24px", color: "#08c" }} />
          </Row>
        ) : (
          <>
            <Row gutter={[16, 16]} align="middle">
              {
                _.isEmpty(timeslot)
                  ? <div style={{ textAlign: "center", width: "100%" }}>{t("no_timeslot_available")}</div>
                  : (
                    <>
                      {_.map(timeslot, (item) => {
                        let {
                          id,
                          start_time,
                          end_time,
                          section_price,
                          status,
                          capacity,
                          usedCapacity,
                        } = item;
                        if (end_time < dayjs().unix()) {
                          status = false;
                        }
                        const disabled =
                          end_time < dayjs().unix() || !status || capacity === usedCapacity || selectedTimeSlot.id === item.id ||
                          _.find(confirmTimeSlot, (time) => time.id === item.id);
                        return (
                          <Col xs={8} key={`time-slot-button-${item.id}`} style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                              disabled={disabled}
                              onClick={() =>
                                checkTimeslotAvailability(id, start_time, end_time, status)
                              }
                              style={{
                                color: disabled
                                  ? "rgba(0, 0, 0, 0.25)"
                                  : id === selectedTime?.id
                                    ? "white"
                                    : COLOR.text.primaryText,
                                background: disabled
                                  ? "#f5f5f5"
                                  : id === selectedTime?.id
                                    ? COLOR.brandPrimary
                                    : "white",
                                height: "100%",
                                padding: "4px 8px",
                                borderRadius: 8,
                              }}
                            >
                              <Row align="middle" justify="center">
                                <Col span={24}>
                                  {`${moment.unix(start_time).format("HH:mm")} - ${moment
                                    .unix(end_time)
                                    .format("HH:mm")}`}
                                </Col>
                                <Col span={24}>{`$${section_price}`}</Col>
                              </Row>
                            </Button>
                          </Col>
                        );
                      })}
                    </>
                  )
              }
            </Row>
            {!_.isEmpty(selectedTime) && (
              <Row justify="space-between" align="middle" style={{ padding: "24px 0 12px" }}>
                <Col>{t("capacity_option")} </Col>
                <Col>
                  <QuantityInput
                    inputNumber={capacityAmount}
                    setInputNumber={setCapacityAmount}
                    plusRuleSet={capacityAmount < avaCapacity}
                    max={avaCapacity}
                    min={1}
                    style={{ borderRadius: 20, border: "1px solid rgb(220, 220, 220)", margin: 4, padding: 4 }}
                  />
                </Col>
              </Row>
            )}

            {!_.isEmpty(productList) && !_.isEmpty(selectedTime) && (
              <div style={{ padding: "12px 0 24px" }}>
                <div>{t("select_rental_items")}</div>
                {_.map(productList, (item, val) => {
                  const { product_item_id } = item;
                  return (
                    <Col span={24} key={`product-section-${val}`}>
                      <ProductButton
                        selected={_.find(productList, {
                          product_item_id,
                        })}
                        setTargetItems={setProductList}
                        setSelectedProductList={setSelectedProductList}
                        selectedProduct={_.find(selectedProductList, {
                          product_item_id,
                        })}
                        item={item}
                      />
                    </Col>
                  );
                })}
              </div>
            )}

            <Row justify="center">
              <Button
                className="add-btn"
                disabled={_.last(timeslot)?.end_time <= moment().unix() || _.isEmpty(timeslot)}
                style={{
                  border: `1px solid ${COLOR.brandPrimary}`,
                  background: COLOR.brandPrimary,
                  width: "80%",
                  color: "white",
                  borderRadius: 4,
                }}
                onClick={() => {
                  handleAddTimeSlot();
                  type === "add" && resetCalendar();
                  setModalVisible(false);
                }}
              >
                {t("add")}
              </Button>
            </Row>
          </>
        )
      }
    </Modal>
  );
};

export default CalendarTimeSlotModal;
