import React, { useState, useEffect } from "react";
import {
  Button, Layout, message, Modal, Popconfirm, Table, Tag, Tooltip, notification
} from "antd";
import {
  EditOutlined,

  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  MailOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import EventForm from "./EventForm";
import { Link, useHistory } from "react-router-dom";
import SendEmailToolTip from "./SendEmailToolTip";
import EventCategoryForm from "./EventCategoryForm";

const EventCategoryTable = (props) => {
  const tableIDName = "event_category_id";
  const admin = useSelector((state) => state.app.admin);
  const { t } = useTranslation();
  const history = useHistory();

  const {
    loading,
    setLoading,
    dataList,
    getAllData = () => null,
  } = props;

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title="Edit">
                <Link to={{
                  pathname: `/company/event/category/${value}`
                }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  handleIsActiveChange(value, is_active);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={record.is_active ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Delete Event Category">
                <Popconfirm
                  placement="top"
                  title="Are you sure to delete event category ?"
                  disabled={record.is_deleted === 1}
                  onConfirm={async () => {
                    await deleteEvent(record);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    shape="circle"
                    disabled={record.is_deleted === 1}
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t("title"),
        dataIndex: "title",
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("sorting"),
        dataIndex: "sorting",
        sorter: (a, b) => a.sorting - b.sorting,
      },
      {
        title: t("update_time"),
        dataIndex: "ctime",
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.ctime - b.ctime
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.ctime - b.ctime
      }
    ];
    return columns;
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    let displayTime = "";
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = "-";
    }
    return displayTime;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Active";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Inactive";
        tagColor = "red";
        break;
      case -1:
        displayStr = "Disable";
        tagColor = "#9A9A9A";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const deleteEvent = async (record) => {
    setLoading(true);
    try {
      let { event_category_id } = record;

      let resp = await Service.call('delete', `/api/event/category`, {event_category_id});
      if (resp.status === 1) getAllData()
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (event_category_id, is_active) => {
    setLoading(true);
    try {
      let postData = {
        event_category_id,
        is_active
      };
      let submit = await Service.call(
        "patch",
        "/api/event/category",
        postData
      );
      if (submit.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(`${submit.errorCode}`),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        size="small"
      />

    </Layout>
  );
};

export default EventCategoryTable;
