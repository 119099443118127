import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Button,
  Row,
  Select,
  Checkbox,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Modal,
  Space,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import * as Service from "../../core/Service";
import SendEmailPopup from "./SendEmailPopup";
import ImportEventUserCsvFile from "./ImportEventUserCsvFile";
import UpdateEventUserCsvFile from "./UpdateEventUserCsvFile";

const { Option } = Select;
const { confirm } = Modal;
const MODAL_ACTION = {
  REJECT: {
    key: -1,
    name: "reject"
  },
  APPROVE: {
    key: 1,
    name: "approve"
  },
  CHECK_IN: {
    key: 2,
    name: "check-in"
  },
};

const CHECK_IN_STATUS = {
  CHECK_IN: 1
};

const EventEnrollmentList = (props) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("all");
  const [loading, setLoading] = useState(true);
  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const {
    event_key,
  } = props;
  const selectedUsersAreCheckedIn = _.findIndex(selectedUsers,
    (user) => user.check_in_status === 0) === -1;
  const selectedUsersAreApproved = _.findIndex(selectedUsers,
    (user) => user.status !== 1) === -1;
  const selectedUsersAreRejected = _.findIndex(selectedUsers,
    (user) => user.status !== -1) === -1;
  const selectedUsersAreNotApproved = _.findIndex(selectedUsers,
    (user) => user.status === 1) === -1;

  useEffect(() => {
    getEnrollmentList();
  }, [event_key]);

  const getEnrollmentList = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/event/enrollment/list", { event_key });
      if (resp.status === 1) {
        const filterParentList = _.filter(resp.data.enrollmentList, (rc) => rc.parent_company_user_id === 0);
        setDataList(filterParentList);
        setFilterList(filterParentList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    if (value === "all") {
      setFilterList(dataList);
    } else {
      const filterData = _.filter(dataList, ["registration_status", value]);
      setFilterList(filterData);
    }
  };

  const handleActionsModal = (action) => {
    confirm({
      centered: true,
      title: `${t(action.name)} ${t("selected user")}`,
      icon: <QuestionCircleOutlined style={{ color: "#888888" }} />,
      okText: t("Yes"),
      cancelText: t("No"),
      okButtonProps: {
        type: "primary",
        className: "action-btn"
      },
      cancelButtonProps: {
        type: "primary",
        ghost: true,
        className: "action-btn"
      },
      async onOk() {
        // api
        switch (action.key) {
          case MODAL_ACTION.APPROVE.key:
            await patchEnrollmentStatus(action.key);
            manualSendEmail(action.key);
            break;
          case MODAL_ACTION.REJECT.key:
            await patchEnrollmentStatus(action.key);
            manualSendEmail(action.key);
            break;
          case MODAL_ACTION.CHECK_IN.key:
            await enrollmentCheckIn();
            break;
          default:
            break;
        }
        // updateUserRegistrationStatus(event_register_user_id, status)
        // console.log({ action, selectedUsers });
      },
      onCancel() {
        console.log("cancel ", action);
      },
    });
  };

  const manualSendEmail = async (action) => {
    try {
      console.log({ Option, selectedUsers});

      const dataObj = {
        list: [],
        event_id: selectedUsers[0].event_id,
        action
      };

      _.each(Option, (type) => {
        _.each(selectedUsers, (user) => {
          dataObj.list.push({
            type,
            event_enrollment_id: user.event_enrollment_id,
          });
        });
      });

      const resp = await Service.call("post", "/api/event/enrollment/approve_reject_email", dataObj);
      console.log(`resp`);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }

      return notification.success({
        message: "Email Sent",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedUsers = (value, record) => {
    setSelectedUsers(record);
  };

  const patchEnrollmentStatus = async (patchStatus) => {
    try {
      setLoading(true);
      const patchData = {
        event_enrollment_id_list: _.map(selectedUsers, "event_enrollment_id"),
        status: patchStatus,
        event_key
      };
      const resp = await Service.call("patch", "/api/event/enrollment/status", patchData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setSelectedUsers([]);
      getEnrollmentList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enrollmentCheckIn = async () => {
    try {
      setLoading(true);
      const patchData = {
        event_enrollment_id_list: _.map(selectedUsers, "event_enrollment_id"),
        status: CHECK_IN_STATUS.CHECK_IN,
        event_key
      };
      const resp = await Service.call("patch", "/api/event/enrollment/check_in", patchData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setSelectedUsers([]);
      getEnrollmentList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="event-tabs-page">
      <Select
        defaultValue={status}
        style={{ width: 120 }}
        onChange={handleStatusChange}
      >
        <Option value="approved">{t("approved")}</Option>
        <Option value="pending">{t("pending")}</Option>
        <Option value="rejected">{t("rejected")}</Option>
        <Option value="all">{t("all")}</Option>
      </Select>
      <ImportEventUserCsvFile
        event_key={event_key}
        getEnrollmentList={getEnrollmentList}
      />
      {/* <UpdateEventUserCsvFile event_key={event_key} /> */}
      <Row style={{ width: "100%" }}>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers) || selectedUsersAreApproved}
          onClick={() => handleActionsModal(MODAL_ACTION.APPROVE)}
        >
          {t("approve_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers) || selectedUsersAreRejected}
          onClick={() => handleActionsModal(MODAL_ACTION.REJECT)}
        >
          {t("reject_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers) || selectedUsersAreCheckedIn || selectedUsersAreNotApproved}
          onClick={() => handleActionsModal(MODAL_ACTION.CHECK_IN)}
        >
          {t("check-in_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={() => setSendEmailModalVisible(true)}
        >
          {t("email_selected_user")}
        </Button>
      </Row>
      <EnrollmentListTable
        sendEmailModalVisible={sendEmailModalVisible}
        setSendEmailModalVisible={setSendEmailModalVisible}
        selectedUsers={selectedUsers}
        handleSelectedUsers={handleSelectedUsers}
        data={filterList}
        loading={loading}
      />
    </div>
  );
};


const EnrollmentListTable = ({
  sendEmailModalVisible,
  setSendEmailModalVisible,
  handleSelectedUsers,
  selectedUsers,
  data,
  loading
}) => {
  const { t } = useTranslation();
  const tableIDName = "event_enrollment_id";

  const deleteEnrollmentRecord = async (id) => {
    console.log({ id });
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = value;
    switch (statusValue) {
      case 1:
        displayStr = "Approved";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Pending";
        tagColor = "cyan";
        break;
      case -1:
        displayStr = "Rejected";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayCheckIn = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Yes";
        tagColor = "green";
        break;
      case 0:
        displayStr = "No";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    let displayTime = "";
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = "-";
    }
    return displayTime;
  };

  const showApproveRejectModal = (id) => {
    confirm({
      centered: true,
      title: (
        <Space size={4} style={{ color: "#888888" }}>
          {t("approve_or_reject")}
          <span style={{ color: "#000" }}>
            ID
            {" "}
            {id}
          </span>
          ?
        </Space>
      ),
      icon: <QuestionCircleOutlined style={{ color: "#888888" }} />,
      okText: t("approved"),
      okButtonProps: {
        ghost: true,
        className: ["approve-button", "action-btn"]
      },
      cancelButtonProps: {
        type: "danger",
        ghost: true,
        className: "action-btn"
      },
      cancelText: t("rejected"),
      onOk() {
        // fetch api
        console.log("approved");
      },
      onCancel() {
        // fetch api
        console.log("rejected");
      },
    });
  };

  const columns = [
    // {
    //   title: "",
    //   render: (value, record) => {
    //     return (
    //       <Checkbox
    //         onChange={(e) => handleSelectedUsers(e.target.checked, record)}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: t("operation"),
    //   dataIndex: tableIDName,
    //   render: (value, record) => {
    //     return (
    //       <>
    //         <Tooltip title="Edit">
    //           <Link
    //             to={{
    //               pathname: `/company/event/${record.event_key}`,
    //             }}
    //           >
    //             <Button
    //               shape="circle"
    //               style={{ marginRight: 4 }}
    //               icon={<EditOutlined />}
    //             />
    //           </Link>
    //         </Tooltip>
    //         {/* <Popconfirm
    //           title={
    //             record.is_active
    //               ? t("confirm_deactivate")
    //               : t("confirm_activate")
    //           }
    //           onConfirm={() => {
    //             let is_active = record.is_active ? 0 : 1;
    //             handleIsActiveChange(record.event_id, is_active);
    //           }}
    //           okText={t("yes")}
    //           cancelText={t("no")}
    //         > */}
    //         <Tooltip
    //           title={record.status === "approved" ? t("reject") : t("approve")}
    //         >
    //           <Button
    //             shape="circle"
    //             style={{ marginRight: 4 }}
    //             icon={
    //               record.status === "approved" ? (
    //                 <CloseCircleOutlined />
    //               ) : (
    //                 <CheckCircleOutlined />
    //               )
    //             }
    //             onClick={() =>
    //               showApproveRejectModal(
    //                 _.toString(record.event_register_user_id).padStart(4, "0")
    //               )
    //             }
    //           />
    //         </Tooltip>
    //         {/* </Popconfirm> */}
    //         <Tooltip title="Delete">
    //           <Popconfirm
    //             placement="top"
    //             title="Are you sure to delete this enrollment record ?"
    //             disabled={record.is_deleted === 1}
    //             onConfirm={async () => {
    //               await deleteEnrollmentRecord(record?.event_register_user_id);
    //             }}
    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <Button
    //               shape="circle"
    //               disabled={record.is_deleted === 1}
    //               icon={<DeleteOutlined />}
    //             />
    //           </Popconfirm>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    {
      title: "ID",
      dataIndex: "event_enrollment_id",
      render: (value) => <div>{_.toString(value).padStart(4, "0")}</div>,
      sorter: (a, b) => a.event_register_user_id - b.event_register_user_id,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (value) => displayStatus(value),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: t("check-in"),
      dataIndex: "check_in_status",
      render: (value) => displayCheckIn(value),
      sorter: (a, b) => a.is_check_in - b.is_check_in,
    },
    {
      title: t("event_start_time"),
      dataIndex: "event_start_time",
      render: (value) => displayMoment(value),
      sorter: (a, b) => a.event_start_time - b.event_start_time,
    },
    {
      title: t("event_end_time"),
      dataIndex: "event_end_time",
      render: (value) => displayMoment(value),
      sorter: (a, b) => a.event_end_time - b.event_end_time,
    },
    {
      title: t("registration_time"),
      dataIndex: "ctime",
      render: (value) => displayMoment(value),
      sorter: (a, b) => a.registration_time - b.registration_time,
    },
    {
      title: t("company_name"),
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
    },
    {
      title: t("email_address"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("phone_number"),
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
      title: t("first_name"),
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: t("last_name"),
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: t("nickname"),
      dataIndex: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: t("position"),
      dataIndex: "position",
      sorter: (a, b) => a.position.localeCompare(b.position),
    },
    {
      title: t("referral_code"),
      dataIndex: "referral_code",
      sorter: (a, b) => a.referral_code.localeCompare(b.referral_code),
    },
    {
      title: t("contact_no"),
      dataIndex: "contact_no",
      sorter: (a, b) => a.contact_no.localeCompare(b.contact_no),
    },
    {
      title: t("salutation"),
      dataIndex: "salutation",
      sorter: (a, b) => a.salutation.localeCompare(b.salutation),
    },
    {
      title: t("reference_id"),
      dataIndex: "reference_id",
      sorter: (a, b) => a.reference_id.localeCompare(b.reference_id),
    },
    {
      title: t("university_name"),
      dataIndex: "university_name",
      sorter: (a, b) => a.university_name.localeCompare(b.university_name),
    },
    {
      title: t("study_mode"),
      dataIndex: "study_mode",
      sorter: (a, b) => a.study_mode.localeCompare(b.study_mode),
    },
    {
      title: t("programme_name"),
      dataIndex: "programme_name",
      sorter: (a, b) => a.programme_name.localeCompare(b.programme_name),
    },
    {
      title: t("guest_number"),
      dataIndex: "guest_number",
      sorter: (a, b) => a.guest_number.localeCompare(b.guest_number),
    },
    {
      title: t("seat_number"),
      dataIndex: "seat_number",
      sorter: (a, b) => a.seat_number.localeCompare(b.seat_number),
    },
    {
      title: t("remarks"),
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
    {
      title: t("prize_winner"),
      dataIndex: "prize_winner",
      sorter: (a, b) => a.prize_winner.localeCompare(b.prize_winner),
    },
    {
      title: t("is_delete"),
      dataIndex: "is_delete",
      render: (value) => <div>{(value === 1) ? "Yes" : "No"}</div>,
      sorter: (a, b) => a.is_delete - b.is_delete,
    },
  ];

  const rowSelection = {
    selectedRowKeys: _.map(selectedUsers, "event_enrollment_id"),
    onChange: handleSelectedUsers,
    selections: [
      Table.SELECTION_ALL,
      {
        key: "SELECTION_NONE",
        text: t("clear_all"),
        onSelect: () => {
          handleSelectedUsers([], []);
        },
      },
    ]
    // getCheckboxProps: (record) => ({
    //   disabled: record.status !== 0,
    //   name: record.status,
    // }),
  };

  return (
    <div className="event-enrollment-list">
      <div style={{ paddingTop: 32, position: "relative" }}>
        <div style={{ position: "absolute", top: 6, fontWeight: "bold" }}>
          {selectedUsers?.length > 0 ? `Selected ${selectedUsers.length} items` : ''}
        </div>
        <Table
          loading={loading}
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={data}
          columns={columns}
          size="small"
          bordered
          rowSelection={rowSelection}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100', '200', '500'] }}
        />
      </div>
      <SendEmailPopup
        modalVisible={sendEmailModalVisible}
        setModalVisible={setSendEmailModalVisible}
        selectedUsers={selectedUsers}
      />
    </div>
  );
};

export default EventEnrollmentList;
