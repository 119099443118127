import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BlogPostListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Banner";
  const tableIDName = "banner_id";
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL } = app.config;
  const {
    dataObj, setModalVisible, setSelectedRecord, loading, handleDeleteRecord, isActiveChangeCallBack, setRulesetModalVisible
  } = props;

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  isActiveChangeCallBack(record.banner_id, is_active);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={record.is_active ? t("deactivate") : t("activate")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => handleDeleteRecord(record.banner_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        }
      },
      {
        title: t('banner'),
        dataIndex: 'banner',
        render: (value, record, key) => {
          return (
            <div>
              <img
                src={`${STATIC_SERVER_URL}/${value}`}
                alt={value}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: 300,
                  maxHeight: 100
                }}
              />
            </div>
          );
        }
      },
      {
        title: t('is_active'),
        dataIndex: 'is_active',
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value)
      },
      {
        title: t("sorting"),
        dataIndex: "sorting",
        sorter: (a, b) => a.sorting - b.sorting
      }
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize);
          }
        }}
        loading={loading}
      />
    </Layout>

  )
}

export default BlogPostListTable;
