import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  DatePicker,
  Select,
  notification
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import * as Service from "../core/Service";
import { formItemLayout, tailLayout } from "./ModalLayout";

const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const CompanyUserMembershipForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    userOption: [],
    membershipOption: []
  });
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    getAllData,
    setModalVisible,
    userList,
    membershipList,
    selectedRc,
    companyUserMembershipList,
  } = props;


  useEffect(() => {
    let userOption = [];
    let membershipOption = [];
    let defaultUserList = _.cloneDeep(userList);
    form.resetFields();
    if (selectedRc.company_user_membership_id > 0) {
      form.setFieldsValue({
        ...selectedRc,
        start_end_time: [moment.unix(selectedRc.start_time), moment.unix(selectedRc.end_time)]
      });
    } else {
      const companyUserMembershipIdArr = _.map(companyUserMembershipList, "company_user_id");
      defaultUserList = _.filter(userList, (rc) => !_.includes(companyUserMembershipIdArr, rc.company_user_id));
    }
    _.map(defaultUserList, (rc) => {
      userOption.push({ label: `(${rc.company_user_id})${rc.nickname}`, value: rc.company_user_id });
    });
    _.map(membershipList, (rc) => {
      membershipOption.push({ label: `(${rc.membership_plan_id}) ${rc.name}`, value: rc.membership_plan_id });
    });

    setOptions({
      userOption,
      membershipOption
    });
  }, [props, selectedRc]);

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      let method = "post";
      let url = "/api/company/user/membership";
      if (selectedRc.company_user_membership_id > 0) {
        method = "patch";
      }
      const postData = {
        ...formData,
        start_time: moment(_.first(formData.start_end_time)).startOf("day").unix(),
        end_time: moment(_.last(formData.start_end_time)).endOf("day").unix(),
        company_user_membership_id: selectedRc.company_user_membership_id
      };
      // console.log("postData >>>", postData);
      let resp = await Service.call(method, url, postData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      getAllData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="userMembershipForm"
      layout="vertical"
      {...formItemLayout}
    >
      <Form.Item
        name="company_user_id"
        label={t("company_user")}
        rules={[{ required: selectedRc.company_user_membership_id > 0 }]}
      >
        <Select
          options={options.userOption}
          disabled={selectedRc.company_user_membership_id > 0}
        />
      </Form.Item>
      <Form.Item
        name="membership_plan_id"
        label={t("membership_plan")}
        rules={[{ required: true, message: t("input_required")}]}
      >
        <Select
          options={options.membershipOption}
        />
      </Form.Item>
      <Form.Item
        name="start_end_time"
        label={t("start_end_time")}
        rules={[{ required: true, message: t("input_required") }]}
      >
        <RangePicker
          disabledDate={(current) => {
            return current <= moment().startOf("day");
          }}
        />
      </Form.Item>
      <Form.Item
        {...tailLayout}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyUserMembershipForm;
