import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Tabs,
  Spin,
  Button,
  Badge,
  Table,
  Modal,
  notification,
  Form,
  Input,
  Select
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
// import { StaticPageEditForm } from '../../components/form/StaticPageEditForm';
import StaticPageEditForm from "../../components/form/StaticPageEditForm";

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;

const Setting = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

  }, []);


  return (
    <NavAndSideFrame {...props} title={t("setting")} selectedKey="setting">
      <Spin spinning={loading}>
        <Tabs type="card">
          {/* <TabPane tab={t('logo')} key="logo">
          <TabComponent metaKey="site_logo" pathname="/company/site_logo_form" />
        </TabPane> */}
          <TabPane tab={t("company_info")} key="company">
            <TabComponent metaKey="company" />
          </TabPane>

          <TabPane tab="社交媒體" key="media">
            <TabComponent metaKey="media" />
          </TabPane>

          <TabPane tab="關於我們" key="about_us">
            <TabComponent metaName="about_us" t={t} metaKey="about_us" />
          </TabPane>

          <TabPane tab="介紹" key="introduction">
            <TabComponent metaName="introduction" t={t} metaKey="introduction" />
          </TabPane>

          <TabPane tab="平面圖" key="floor">
            <TabComponent metaName="floor" t={t} metaKey="floor" />
          </TabPane>

          <TabPane tab="條款" key="tc">
            <TabComponent metaName="tc" t={t} metaKey="tc" />
          </TabPane>

          {/* <TabPane tab="靜態內容" key="static">
          <TabComponent metaName="static" t={t} metaKey="static" pathname="/admin/static_content_form" />
        </TabPane> */}
        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

const TabComponent = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    metaKey,
  } = props;

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Service.call("get", `/api/admin/meta_by_key?meta_key=${metaKey}`);
    if (!_.isEmpty(result.data)) setShowAddButton(false);
    else setShowAddButton(true);

    setDataSource(result.data);
    setLoading(false);
  };

  const getColumns = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "site_meta_id",
        key: "site_meta_id",
        render: (value, record, index) => {
          let { site_meta_id, meta_key } = record;

          return (
            <span>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                style={{ color: "#00AA00" }}
                onClick={() => {
                  switch (meta_key) {
                    case "site_logo": //  Media
                      history.push(`${props.pathname}?meta_key=${metaKey}&id=${site_meta_id}`);
                      break;

                    default: //  Input Form
                      setRecordId(value);
                      setSelectedFormData(record);
                      setModalVisible(true);
                      break;
                  }
                }}
              />
              {/* <Button shape="circle" icon={<DeleteOutlined />} style={{color: '#AA0000' }} onClick={() => onDelete(site_meta_id, record)} /> */}
            </span>
          );
        }
      },
      {
        title: t("key"),
        dataIndex: "meta_key",
        key: "meta_key",
      },
      {
        title: t("value"),
        dataIndex: "meta_value",
        key: "meta_value",
      },
    ];

    return columns;
  };

  const onDelete = (id, record) => {
    confirm({
      title: `確認剛除${record.meta_key}？`,
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk: () => {
        confirmDelete(id);
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  };

  const confirmDelete = async (site_meta_id) => {
    let result = await Service.call("delete", "/api/admin/meta", { site_meta_id });

    if (!result || result.status <= 0) {
      notification.error({
        message: "刪除失敗，請稍後再試",
        description: t(result.errorCode),
      });
      return;
    }

    notification.success({
      message: "成功刪除記綠",
      description: "",
    });

    fetchData();
  };

  return (
    <Spin spinning={loading}>
      {showAddButton && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            switch (metaKey) {
              // case 'company_info_name_en':
              // case 'company_info_name_zh_hant':
              // case 'company_info_address_en':
              // case 'company_info_address_zh_hant':
              // break;

              case "site_logo":
                history.push(`${props.pathname}?meta_key=${metaKey}`);
                break;

              default:
                notification.info({ message: t("not_supported") });
                break;
            }
          }}
          style={{ marginBottom: 10 }}
        >
          {t("create")}
        </Button>
      )}
      <Table rowKey={() => "site_meta"} scroll={{ x: true }} dataSource={dataSource} columns={getColumns()} />

      <Modal
        title={recordId > 0 ? t("edit") : t("create")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <StaticPageEditForm
          selectedFormData={selectedFormData}
          recordId={recordId}
          closeMethod={async () => {
            fetchData();
            setModalVisible(false);
          }}
          type="admin"
        />
        {/* <SiteMetaForm
          recordId={recordId}
          selectedFormData={selectedFormData}
          closeMethod={async () => {
            fetchData();
            setModalVisible(false);
          }}
        /> */}
      </Modal>
    </Spin>
  );
};

const SiteMetaForm = (props) => {
  const { t } = useTranslation();

  const { recordId, selectedFormData, closeMethod } = props;

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={selectedFormData}
      onFinish={async (values) => {
        let method = "post";
        if (recordId !== 0) { //  Edit
          method = "patch";
          values.site_meta_id = recordId;
        }

        let result = await Service.call(method, "/api/admin/meta", values);
        // console.log('result', result)

        if (result.status !== 1) {
          return notification.error({ message: "提交失敗", description: t(result.errorCode) });
        }

        notification.success({ message: "提交成功" });
        closeMethod();
      }}
    >
      <Form.Item
        label={t("key")}
        name="meta_key"
        rules={[{ required: true }]}
      >
        <Select disabled={recordId}>
          <Option value="company_info_name_zh_hant">{`${t("company_name")} (${t("zh_hant")})`}</Option>
          <Option value="company_info_name_en">{`${t("company_name")} (${t("en")})`}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t("value")}
        name="meta_value"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t("submit")}
      </Button>
    </Form>
  );
};

export default Setting;
