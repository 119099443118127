import _ from 'lodash';
import * as types from './types';
// import AsyncStorage from '@react-native-community/async-storage';
// import * as Main from '../../core/Main';

export function setConfig(appConfig) {
  return {
    type: types.SET_CONFIG,
    data: appConfig,
  };
}

export function setAdmin(admin) {
  return {
    type: types.SET_ADMIN,
    data: admin
  };
}

export function setIsAdmin(boolean) {
  return {
    type: types.SET_IS_ADMIN,
    data: boolean
  }
}

export function setCompanyAdmin(company_admin) {
  return {
    type: types.SET_COMPANY_ADMIN,
    data: company_admin
  }
}

export function setCompanyUser(company_user) {
  return {
    type: types.SET_COMPANY_USER,
    data: company_user
  }
}

export function setIsCompanyUser(boolean) {
  return {
    type: types.SET_IS_COMPANY_USER,
    data: boolean
  }
}

export function setUser(user) {
  return {
    type: types.SET_USER,
    data: user
  }
}

export function setAuth(state) {
  return {
    type: types.SET_AUTH,
    data: state
  };
}

export function setOrigin(admin) {
  return {
    type: types.SET_ORIGIN,
    data: admin
  };
}

export function setLoading(sider_status) {
  return {
    type: types.SET_LOADING,
    data: sider_status
  };
}

export function setCompany(company) {
  return {
    type: types.SET_COMPANY,
    data: company
  };
}

// export function setSider(sidebarVisible) {
//   return {
//     type: types.SET_SIDER,
//     data: sidebarVisible
//   };
// }

export function toggleSider(visible) {
  return {
    type: types.SET_SIDER,
    data: visible,
  };
}

export function setTimeZoneOffset(offset) {
  return {
    type: types.SET_TIME_ZONE_OFFSET,
    data: offset
  };
}
