import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider, notification } from "antd";
import { useSelector } from "react-redux";
import _ from 'lodash';
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import DisplayListTable from "./DisplayListTable";
import DisplayListModel from "./DisplayListModel";

const selectedKey = "display_list";
const openKeys = "display"
const DisplayList = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [bookingItemList, setBookingItemList] = useState([]);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const API_ORIGIN = useSelector((state) => state.app.config.API_ORIGIN);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    const response = await Service.call("get", "/api/display");
    let { status, errorMessage, data } = response;
    if (status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(status.errorCode)
      });
    }

    const resBookingItem = await Service.call("get", "/api/booking_item/list");

    if (resBookingItem.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(resBookingItem.errorCode)
      });
    }

    setBookingItemList(resBookingItem.data.bookingItemList);

    data = await Promise.all(_.map(data, async (display) => {
      const res = await Service.call("get", "/api/display/booking_item", { display_id: display.display_id });
      if (res.status !== 1) {
        setLoading(false);
        return notification.error({
          message: t("error"),
          description: t(res.errorCode)
        });
      }
      display.booking_item = await Promise.all(_.map(res.data, async (item) => {
        let findItem = _.findLast(resBookingItem.data.bookingItemList, (i) => {
          if (i.booking_item_id === item.booking_item_id) {
            return i;
          }
        });
        let item_name = findItem?.item_name;
        let { display_booking_item_ref_id } = item;
        return { display_booking_item_ref_id, item_name };
      }));
      return display;
    }));

    setDataList(data);
    setLoading(false);
  };

  const handleFormSubmit = (submitRecord) => {
    if (selectedRecord.display_id === 0) {
      handlePutRecord(submitRecord);
    } else if (selectedRecord.display_id > 0) {
      handlePatchRecord(submitRecord);
    }
  };

  const handlePutRecord = async (submitRecord) => {
    setLoading(true);
    let {
      display_name, booking_item, background_image, text_color, is_active
    } = submitRecord;

    let putData = {
      display_name,
      background_image: background_image || " ",
      text_color,
      is_active
    };

    const response = await Service.call("put", "/api/display", putData);
    let { display_id } = response.response;

    _.forEach(booking_item, async (item) => {
      let putItemData = {
        display_id,
        booking_item_id: item.booking_item_id
      };

      await Service.call("put", "/api/display/booking_item", putItemData);
    });

    getAllData();
    setLoading(false);
    setModalVisible(false);
  };

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true);
    let {
      display_name, booking_item, background_image, text_color, is_active
    } = submitRecord;

    let { display_id } = selectedRecord;

    let PatchData = {
      display_id,
      display_name,
      background_image: background_image || " ",
      text_color,
      is_active
    };

    const response = await Service.call("Patch", "/api/display", PatchData);

    if (response.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(response.errorCode)
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
    }

    let oldBookingItem = selectedRecord.booking_item;

    if (oldBookingItem && booking_item.length > 0) {
      _.forEach(oldBookingItem, async (item) => {
        let { display_booking_item_ref_id } = item;
        await Service.call("delete", "/api/display/booking_item", { display_booking_item_ref_id });
      });
    }

    _.forEach(booking_item, async (item) => {
      let putItemData = {
        display_id,
        booking_item_id: item.booking_item_id
      };
      await Service.call("put", "/api/display/booking_item", putItemData);
    });

    getAllData();
    setLoading(false);
    setModalVisible(false);
  };

  return (
    <NavAndSideFrame title={t("display_list")} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ display_id: 0 });
          setModalVisible(true);
        }}
      >
        {t("add_display")}
      </Button>
      <Divider />
      <DisplayListTable
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        API_ORIGIN={API_ORIGIN}
        loading={loading}
        dataObj={dataList}
      />
      <DisplayListModel
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        bookingItemObj={bookingItemList}
        API_ORIGIN={API_ORIGIN}
        setModalVisible={
          (value) => {
            setModalVisible(value);
            getAllData();
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />

    </NavAndSideFrame>
  );
};

export default DisplayList;
