import React, { useState, useEffect } from "react";
import { Divider, notification, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BookingItemRuleSetTable from "./BookingItemRuleSetTable";
import BookingItemRuleSetModal from "./BookingItemRuleSetModal";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "booking_config";
const openKeys = "booking_management";

const BookingItemRuleSetList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const title = t("timeslot_ruleset");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let url = "/api/booking_item/timeslot";
      let resp = await Service.call("get", url);

      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      let { data } = resp;
      setDataList(data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let method = submitRecord.booking_item_timeslot_id === 0 ? "put" : "patch";
      let resp = await Service.call(method, "/api/booking_item/timeslot", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (booking_item_timeslot_id, value) => {
    setLoading(true);
    let postData = {
      booking_item_timeslot_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/booking_item/timeslot/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode)
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleDuplicateRecord = async (booking_item_timeslot_id) => {
    setLoading(true);
    let postData = {
      booking_item_timeslot_id
    };

    let submit = await Service.call("post", "/api/booking_item/timeslot/duplicate", postData);

    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode)
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ booking_item_timeslot_id: 0 });
          setModalVisible(true);
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />
      <BookingItemRuleSetTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        // deleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(booking_item_timeslot_id, value) => {
          handleIsActiveChange(booking_item_timeslot_id, value);
        }}
        handleDuplicateRecord={handleDuplicateRecord}
        loading={loading}
      />
      <BookingItemRuleSetModal
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={handleFormSubmit}
      />
    </NavAndSideFrame>
  );
};

export default BookingItemRuleSetList;
