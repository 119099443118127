import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Spin, notification, message, Radio, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import * as Service from "../../core/Service";
import { formItemLayout, tailLayout } from "../ModalLayout";

const columns = [
  {
    title: "Username",
    dataIndex: "username",
    width: "200px"
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "200px"
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    width: "200px"
  }
];

const LinkToSaForm = (props) => {
  const {
    dataObj,
    setLinkToSaModalVisible,
    getAllData,
    saUserList
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(dataObj);
  }, [dataObj]);
  const onFinish = async (postObj) => {
    setLoading(true);
    if (_.isEmpty(dataObj)) {
      return message.error(t("user ID not Found"));
    }
    postObj.company_user_id = dataObj.company_user_id;
    let result = await Service.call("patch", "/api/company/sa/user/ref_id", postObj);
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(result.errorCode),
      });
    }
    notification.success({ message: "Success!" });
    getAllData();
    setLinkToSaModalVisible(false);
    setLoading(false);
  };

  let potentialUsers = generatePotentialUsers(saUserList, dataObj);

  if (_.isEmpty(potentialUsers)) {
    return (
      <div>
        {t("cannot_find_matching-sa-users")}
      </div>
    );
  }

  return (
    <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        size="small"
        layout="horizontal"
        onFinish={onFinish}
      >
        <div style={{ marginBottom: 20 }}>
          {t("select_sa_user_to_link")}
        </div>
        <Form.Item
          name="sa_user_id"
          rules={[{ required: true, message: t("selectLabel") }]}
        >
          <Table
            dataSource={potentialUsers}
            columns={columns}
            rowSelection={{
              type: "radio",
              onSelect: (row) => form.setFieldsValue({ sa_user_id: row.user_id })
            }}
            rowKey={(record) => record.user_id}
          />
        </Form.Item>
        <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default LinkToSaForm;

const generatePotentialUsers = (saUserList, dataObj) => {
  let potentialUsers = [];

  _.forEach(saUserList, (user) => {
    if (user.username === dataObj.username
      || user.mobile === dataObj.mobile
      || user.email === dataObj.email) {
      potentialUsers.push(user);
    }
  });

  return potentialUsers;
};
