import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import * as Service from "../../../core/Service";
import EventForm from "../../../components/Event/EventForm";
import { useHistory} from "react-router-dom";

const title = "Add Event";
const selectedKey = "event_list";
const openKeys = "event_management";
const EventFormContainer = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [periodList, setPeriodList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getEventCategoryList();
  }, []);

  const getEventCategoryList = async () => {
    try {
      let resp = await Service.call("get", "/api/event/category");
      const {data} = resp;
      const list = data
        .filter((item) => item.is_active === 1)
        .map((item) => ({ value: item.event_category_id, label: item.title }));
      setCategories(list);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };
  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <EventForm
        loading={loading}
        categories={categories}
        getEventCategoryList={getEventCategoryList}
        onSuccess={(event_key) => history.push(`/company/event/${event_key}`)}
      />

    </NavAndSideFrame>
  );
};
export default EventFormContainer;
