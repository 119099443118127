import React, { useEffect, useState } from "react";
import _ from "lodash";

import {
  Col,
  InputNumber,
  Row,
  Space,
  Switch,
  Select,
  notification,
  Dropdown,
  Checkbox,
  Button
} from "antd";

import { COLOR } from "../../theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { days as dayList } from "./BookingItemRuleSet/BookingItemRuleSetModal";

const CheckboxGroup = Checkbox.Group;

const TimeSlotSection = ({
  days,
  timeSlotList,
  setTimeSlotList,
  selectedRecord,
  getBookingConfigRuleset,
  section_duration
}) => {
  const { t } = useTranslation();

  const emptyTimeSlot = {
    booking_item_timeslot_ruleset_id: 0,
  };

  useEffect(() => {
    if (
      selectedRecord.booking_item_timeslot_id !== 0 &&
      !_.isUndefined(selectedRecord.booking_item_timeslot_id)
    ) {
      getBookingConfigRuleset();
    }
  }, [selectedRecord]);

  // if switch is on, generate a new timeslot, otherwise, remove existing timeslot
  const handleSwitchChange = (checked, row) => {
    let cloned = _.clone(timeSlotList);
    if (!checked) {
      delete cloned[row];
    } else {
      cloned[row] = [{ ...emptyTimeSlot }];
    }
    setTimeSlotList(cloned);
  };

  // handle section price change of each timeslot
  const handlePriceChange = (value, row, i) => {
    const cloned = _.clone(timeSlotList);
    let newTimeSlot = { ...cloned[row][i], section_price: value };
    cloned[row][i] = newTimeSlot;
    setTimeSlotList(cloned);
  };

  const addTimeSlot = (day) => {
    let clonedTimeSlotList = _.clone(timeSlotList);
    if (clonedTimeSlotList[day] instanceof Array) {
      clonedTimeSlotList[day].push({ ...emptyTimeSlot });
    } else {
      clonedTimeSlotList[day] = [{ ...emptyTimeSlot }];
    }
    setTimeSlotList(clonedTimeSlotList);
  };

  // remove timeslot
  const removeTimeSlot = (day, i) => {
    let cloned = _.clone(timeSlotList);
    cloned[day].splice(i, 1);
    setTimeSlotList(cloned);
  };

  const generateTimeOptions = (isStart) => {
    const sectionInterval = 15;
    let timeOptions = [];

    let currentTime = isStart ? 0 : section_duration;

    while (currentTime <= 24 * 60) {
      const timeInString = convertTimeInMinutesToString(currentTime);
      timeOptions.push({ label: timeInString, value: timeInString });
      currentTime += sectionInterval;
    }

    if (isStart) {
      timeOptions.pop();
    }

    return timeOptions;
  };

  const checkTimeSlotOverlap = (dayTimeSlots, idx, newTimeSlot) => {
    let timeSlotOverlap = false;
    _.forEach(dayTimeSlots, (oldTimeSlot, index) => {
      if (index !== idx) {
        let oldTimeSlotStartTime = convertTimeStringToMinutes(oldTimeSlot.start_time);
        let oldTimeSlotEndTime = convertTimeStringToMinutes(oldTimeSlot.end_time);

        let newTimeSlotStartTime = convertTimeStringToMinutes(newTimeSlot.start_time);
        let newTimeSlotEndTime = typeof newTimeSlot.end_time === "string" ? convertTimeStringToMinutes(newTimeSlot.end_time) : null;

        let startTimeIncorrect = (newTimeSlotStartTime >= oldTimeSlotStartTime && newTimeSlotStartTime < oldTimeSlotEndTime) || (oldTimeSlotStartTime >= newTimeSlotStartTime && oldTimeSlotStartTime < newTimeSlotEndTime);
        let endTimeIncorrect = newTimeSlotEndTime && ((newTimeSlotEndTime > oldTimeSlotStartTime && newTimeSlotEndTime <= oldTimeSlotEndTime) || (oldTimeSlotEndTime > newTimeSlotStartTime && oldTimeSlotEndTime <= newTimeSlotEndTime));

        if (startTimeIncorrect || endTimeIncorrect) {
          timeSlotOverlap = true;
        }
      }
    });

    return timeSlotOverlap;
  };

  const checkTimeSlotIsValid = (timeslot) => {
    if (!timeslot.end_time) {
      return true;
    }

    if (convertTimeStringToMinutes(timeslot.start_time) < convertTimeStringToMinutes(timeslot.end_time)) {
      return true;
    }

    return false;
  };

  const handleTimeChange = (value, row, idx, isStart) => {
    const clonedTimeSlotList = _.cloneDeep(timeSlotList);
    let newTimeSlot = {
      ...clonedTimeSlotList[row][idx]
    };
    if (isStart) {
      newTimeSlot.start_time = value;
    } else {
      newTimeSlot.end_time = value;
    }

    if (!checkTimeSlotIsValid(newTimeSlot)) {
      return notification.warning({
        message: t("warning"),
        description: t("end_time_is_before_start_time")
      });
    }

    if (checkTimeSlotOverlap(clonedTimeSlotList[row], idx, newTimeSlot)) {
      return notification.warning({
        message: t("warning"),
        description: t("time_slot_overlap")
      });
    }

    clonedTimeSlotList[row][idx] = newTimeSlot;
    setTimeSlotList(clonedTimeSlotList);
  };

  const handleApplyCopy = (copyFromIndex, copyToIndexes) => {
    const clonedTimeSlotList = _.cloneDeep(timeSlotList);

    _.forEach(copyToIndexes, (copyToIndex) => {
      let copiedTimeSlots = [];
      _.forEach(timeSlotList[copyFromIndex], (_timeSlot) => {
        copiedTimeSlots.push({
          booking_item_timeslot_ruleset_id: 0,
          start_time: _timeSlot.start_time,
          end_time: _timeSlot.end_time,
          section_price: _timeSlot.section_price
        });
      });
      clonedTimeSlotList[copyToIndex] = copiedTimeSlots;
    });

    setTimeSlotList(clonedTimeSlotList);
  };

  const renderSchedule = (rows) => {
    return (
      <>
        <div className="book-schedule">
          {_.range(rows).map((row, i) => {
            const switchIsChecked = !(_.isUndefined(timeSlotList?.[row]) || _.isEmpty(timeSlotList?.[row]));
            return (
              <Row key={`row-${i}`} align="middle" style={{ borderBottom: `0.5px solid ${COLOR.gray.gray200}` }}>
                <Col span={4}>
                  <Space>
                    <Switch
                      checked={switchIsChecked}
                      onChange={(checked) => handleSwitchChange(checked, row)}
                    />
                    <span style={{ fontWeight: "bold" }}>
                      {_.capitalize(days[row])}
                    </span>
                  </Space>
                </Col>
                <Col span={20} className="grid-row" style={{}}>
                  {
                    !switchIsChecked
                      ? (<div style={{ color: COLOR.gray.gray600 }}>Unavailable</div>)
                      : (
                        <div>
                          {_.map(timeSlotList?.[row], (slot, idx) => {
                            return (
                              <TimeSlotForm
                                key={idx}
                                timeslot={slot}
                                isFirstTimeSlot={idx === 0}
                                onChangeSectionPrice={(value) => handlePriceChange(value, row, idx)}
                                generateTimeOptions={generateTimeOptions}
                                onChangeStartTime={(value) => handleTimeChange(value, row, idx, true)}
                                onChangeEndTime={(value) => handleTimeChange(value, row, idx, false)}
                                onClickDelete={() => removeTimeSlot(row, idx)}
                                onClickAdd={() => addTimeSlot(row)}
                                handleApplyCopy={handleApplyCopy}
                                dayIndex={row}
                              />
                            );
                          })}
                        </div>
                      )
                  }
                </Col>
              </Row>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <div className="schedule-container">
      {renderSchedule(days.length)}
    </div>
  );
};

export default TimeSlotSection;

const TimeSlotForm = ({
  timeslot,
  isFirstTimeSlot,
  onChangeSectionPrice,
  generateTimeOptions,
  onChangeStartTime,
  onChangeEndTime,
  onClickDelete,
  onClickAdd,
  handleApplyCopy,
  dayIndex
}) => {
  const { t } = useTranslation();

  const startTimeOptions = generateTimeOptions(true);
  const endTimeOptions = generateTimeOptions(false);

  return (
    <div style={{
      ...styles.alignItemsCenter,
      justifyContent: "space-between",
      margin: "10px 0"
    }}
    >
      <div
        style={{
          ...styles.alignItemsCenter
        }}
      >
        <div style={{
          ...styles.alignItemsCenter,
          marginRight: 64
        }}
        >
          <Select
            style={{ width: 102 }}
            className="custom-select"
            options={startTimeOptions}
            value={timeslot.start_time || null}
            onChange={onChangeStartTime}
          />
          <div style={{ margin: "0 12px" }}>-</div>
          <Select
            style={{ width: 102 }}
            className="custom-select"
            options={endTimeOptions}
            value={timeslot.end_time || null}
            onChange={onChangeEndTime}
            disabled={!timeslot.start_time}
          />
        </div>
        <div style={{
          ...styles.alignItemsCenter,
          marginRight: 64
        }}
        >
          <div style={{ marginRight: 16 }}>
            <span style={{ color: COLOR.negative.negative500, marginRight: 4 }}>
              *
            </span>
            <span>
              {t("price_per_section")}
            </span>
          </div>
          <InputNumber
            style={{ borderRadius: 4 }}
            min={0}
            value={timeslot.section_price}
            onChange={onChangeSectionPrice}
          />
        </div>
      </div>
      <div style={{
        ...styles.alignItemsCenter
      }}
      >
        <TrashIcon
          style={{ marginRight: 16, cursor: "pointer" }}
          onClick={onClickDelete}
        />
        <div style={{
          ...styles.alignItemsCenter,
          width: 80
        }}
        >
          {isFirstTimeSlot
            && (
              <>
                <AddIcon
                  onClick={onClickAdd}
                  style={{ marginRight: 16, cursor: "pointer" }}
                />
                <CopyDropdown
                  dayIndex={dayIndex}
                  handleApplyCopy={handleApplyCopy}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const CopyDropdown = ({
  dayIndex,
  handleApplyCopy
}) => {
  const { t } = useTranslation();
  const [selectedDayIndexes, setSelectedDayIndexes] = useState([]);

  const options = _.map(dayList, (_day, index) => {
    return {
      label: _.capitalize(_day),
      value: index,
      disabled: index === dayIndex
    };
  });

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentNode}
      overlay={(
        <div
          className="time-slot-copy-dropdown"
        >
          <div style={{ marginBottom: 6, color: COLOR.gray.gray500 }}>
            {t("copy_times_to")}
          </div>
          <CheckboxGroup
            onClick={(e) => e.stopPropagation()}
            options={options}
            onChange={(checkedValue) => setSelectedDayIndexes(checkedValue)}
          />
          <Button
            type="primary"
            shape="round"
            style={{ marginTop: 6 }}
            onClick={() => handleApplyCopy(dayIndex, selectedDayIndexes)}
          >
            {t("apply")}
          </Button>
        </div>
      )}
    >
      <CopyIcon
        style={{ cursor: "pointer" }}
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  );
};

const styles = {
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  }
};

export const convertTimeInMinutesToString = (time) => {
  const hour = Math.floor(time / 60).toString().padStart(2, "0");
  const minutes = (time % 60).toString().padStart(2, "0");
  return hour + ":" + minutes;
};

export const convertTimeStringToMinutes = (timeString) => {
  let splitString = _.split(timeString, ":");
  let hour = +splitString[0];
  let min = +splitString[1];
  return hour * 60 + min;
};
