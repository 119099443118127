import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  Popconfirm,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useSelector } from "react-redux";

import {TranslationFormItemInput, TranslationFormItemEditor} from "../../../components/TranslationWrapper";
import MediaLibraryComponent from "../../../components/MediaLibraryComponent";
import TinymceWrapper from "../../../components/TinymceWrapper";
import CategoryCascader from "../../../components/CategoryCascader";
import * as Service from "../../../core/Service";

const {TextArea} = Input;

const CategoryInfoModal = (props) => {
  const { t } = useTranslation();
  const config = useSelector((state) => state.app.config);
  const {
    selectedRecord, modalVisible, setModalVisible, handleFormSubmit
  } = props;
  const [CategoryInfoForm] = Form.useForm();

  const [fileinfo, setFileinfo] = useState([]);
  const [defaultImg, setDefaultImg] = useState(null);
  let sorting = useRef({});
  const [sortState, setSortState] = useState({});
  const [loading, setLoading] = useState(false);
  const [categoryDesc, setCategoryDesc] = useState(null);

  useEffect(() => {
    CategoryInfoForm.resetFields();
    setCategoryDesc("");
    if (selectedRecord.product_category_id !== 0) {
      getCategoryImage();
      let parent_product_category_id = !_.isEmpty(selectedRecord.parent_index)
        ? _.concat(
          _.map(_.split(selectedRecord.parent_index, "-"), _.toInteger)
        )
        : null;
      setCategoryDesc(selectedRecord.description);
      CategoryInfoForm.setFieldsValue({
        ...selectedRecord,
        product_category_id: selectedRecord.parent_product_category_id,
        parent_product_category_id,
      });
    }
  }, [selectedRecord]);

  const getCategoryImage = async () => {
    setLoading(true);
    try {
      let resp = await Service.call(
        "get",
        `/api/product_category/image/${selectedRecord.product_category_id}`
      );
      setFileinfo(resp.data);
      let [defaultImage] = _.filter(resp.data, { is_default: 1 });
      if (defaultImage) {
        setDefaultImg(defaultImage.product_category_image_id);
      }

      let sort = {};
      _.each(resp.data, (eachImage) => {
        sort[eachImage.product_category_image_id] = eachImage.sorting;
      });
      sorting.current = sort;
      setSortState(sorting.current);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const categoryImageColumns = [
    {
      title: t("image"),
      render: (value, record) => {
        let path = `${config.STATIC_SERVER_URL}/${record.filepath}`;
        return <img src={path} width="100px" height="100px" alt="item" />;
      },
    },
    {
      title: t("is_default"),
      dataIndex: "is_default",
      render: (value, record) => (
        <Switch
          checked={defaultImg === record.product_category_image_id}
          onChange={(event) => {
            if (event) {
              setDefaultImg(record.product_category_image_id);
            } else {
              setDefaultImg(null);
            }
          }}
        />
      ),
    },
    {
      title: t("sorting"),
      dataIndex: "sorting",
      render: (value, record) => (
        <Input
          value={sortState[record.product_category_image_id]}
          onChange={(e) => {
            setSortState({
              ...sortState,
              [record.product_category_image_id]: _.toInteger(e.target.value),
            });
          }}
        />
      ),
    },
    {
      title: t("delete"),
      dataIndex: "operation",
      render: (value, record) => (
        <Popconfirm
          title="confirm_delete"
          onConfirm={() => onDeleteImage(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ color: "#AA0000" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const onDeleteImage = async (record) => {
    setLoading(true);
    try {
      await Service.call("post", "/api/product_category_image", record);
      await getCategoryImage();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      let { parent_product_category_id } = formData;
      await handleFormSubmit({
        ...formData,
        short_desc: '',
        short_desc_2: '',
        product_category_id: selectedRecord.product_category_id,
        filepath: fileinfo.filepath || null,
        parent_product_category_id: _.isUndefined(
          parent_product_category_id
        )
          ? 0
          : _.last(parent_product_category_id),
        parent_index: _.join(parent_product_category_id, "-"),
        is_default: defaultImg,
        image_sorting: sortState,
        description: categoryDesc || '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        selectedRecord.product_category_id === 0
          ? t("add_category")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
      width="90%"
      destroyOnClose
    >
      <Form
        form={CategoryInfoForm}
        name="CategoryInfoForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <TranslationFormItemInput
          name="category_name"
          label={`${t("category_name")} ${t("title")}`}
          header={`${t("category_name")} ${t("translation")}`}
        />

        {/* <Form.Item
          label={t("short_desc")}
          name="short_desc"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={t("short_desc_2")}
          name="short_desc_2"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <TextArea />
        </Form.Item> */}

        {/* <Form.Item
          label={t("desc")}
        >
          <TinymceWrapper
            initialValue={categoryDesc}
            onChange={(value) => {
              setCategoryDesc(value);
            }}
          />
        </Form.Item> */}

        {/* <CategoryCascader
          id={selectedRecord.product_category_id}
          action="/api/product_category/list"
          modalVisible={modalVisible}
          primary_id="product_category_id"
          parent_primary_id="parent_product_category_id"
        /> */}

        <Form.Item
          label={t("sorting")}
          name="sorting"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <InputNumber maxLength={10} />
        </Form.Item>

        <Form.Item
          label={t("preorder_day")}
          name="preorder_day"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <InputNumber maxLength={10} />
        </Form.Item>
        {/* {selectedRecord.product_category_id !== 0 && (
          <>
            <Form.Item label={t("banner")}>
              <MediaLibraryComponent
                showImage={false}
                selectCallback={async (mediaObj) => {
                  try {
                    setLoading(true);
                    await Service.call("put", "/api/product_category_image", {
                      ...mediaObj,
                      product_category_id: selectedRecord.product_category_id,
                    });
                    await getCategoryImage();
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </Form.Item>
            <Table
              loading={loading}
              columns={categoryImageColumns}
              dataSource={fileinfo}
              pagination={false}
            />
          </>
        )} */}

        <Form.Item style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryInfoModal;
