import React, { useState, useEffect } from "react";
import {
  Spin,
  Table,
  Tag,
  Form,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  map,
  toInteger,
  groupBy,
  isEmpty,
  compact,
  filter
} from "lodash";
import dayjs from "dayjs";
import SearchBar from "../../../components/SearchBar";
import * as Main from "../../../core/Main";

const tableIDName = "company_user_booking";
const FILTERABLE_FIELDS = ["item_name", "item_id", "item_category_name"];

const BookingUserList = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filterDataList, setFilterDataList] = useState([]);
  const [userInfoMap, setUserInfoMap] = useState([]);
  const [userBooking, setUserBooking] = useState([]);
  const [orderItemMap, setOrderItemMap] = useState([]);
  const [rentalItemMap, setRentalItemMap] = useState([]);
  const [dataList, setDataList] = useState([]);
  const {
    orderItemList
  } = props;

  useEffect(() => {
    if (!isEmpty(orderItemList)) {
      const filterList = filter(orderItemList, (rc) => rc.item_type === Main.ITEM_TYPE.BOOKING
        || rc.item_type === Main.ITEM_TYPE.PRODUCT);
      const childItemList = groupBy(filterList, "parent_order_item_id");
      let dataSource = map(filterList, (eachOrderItem) => {
        const {
          parent_order_item_id,
          order_item_id
        } = eachOrderItem;
        if (parent_order_item_id !== 0) return null;
        return {
          ...eachOrderItem,
          rental_items: childItemList[order_item_id] || []
        };
      });
      dataSource = compact(dataSource);
      setDataList(dataSource);
      setFilterDataList(dataSource);
    }
  }, [props]);

  const columns = [
    {
      title: t("id"),
      dataIndex: "item_id",
      sorter: (a, b) => a.item_id - b.item_id
    },
    // {
    //   title: t("status"),
    //   dataIndex: "status",
    //   render: (value) => displayStatus(value),
    //   sorter: (a, b) => a.status - b.status
    // },
    {
      title: t("category_name"),
      dataIndex: "item_category_name",
      render: (value) => <Tag color="blue">{value}</Tag>
    },
    {
      title: t("booking_item"),
      dataIndex: "item_name",
      render: (value) => {
        return <Tag color="blue">{value}</Tag>;
      },
    },
    {
      title: t("rental_items"),
      dataIndex: "rental_items",
      render: (list) => {
        if (isEmpty(list)) return "-";
        return map(list, (eachItem) => {
          const {
            item_name
          } = eachItem;
          return <Tag color="blue">{item_name}</Tag>;
        });
      },
    },
    {
      title: t("start_time"),
      dataIndex: "start_time",
      render: (value) => {
        if (!value) return "-";
        return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
      },
      sorter: (a, b) => a.start_time - b.start_time
    },
    {
      title: t("end_time"),
      dataIndex: "end_time",
      render: (value) => {
        if (!value) return "-";
        return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
      },
      sorter: (a, b) => a.start_time - b.start_time
    },
    {
      title: t("update_time"),
      dataIndex: "utime",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.utime - b.utime
    },
    {
      title: t("create_time"),
      dataIndex: "ctime",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.ctime - b.ctime
    },
  ];
  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = toInteger(value);
    switch (statusValue) {
      case 10:
        displayStr = t("available");
        tagColor = "green";
        break;
      case -1:
        displayStr = t("rejected");
        tagColor = "red";
        break;
      case 2:
        displayStr = t("pending");
        tagColor = "#13C2C2";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Form>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} align="middle">
          <Col>{t("search_keywords")}</Col>
          <Col>
            <SearchBar
              originalDataList={dataList}
              filterDataList={dataList}
              setDataList={setFilterDataList}
              filterField={FILTERABLE_FIELDS}
              placeholder="search_keywords"
              suffix={<SearchOutlined style={{ color: "#888888" }} />}
            />
          </Col>
        </Row>
        <Table
          rowKey={tableIDName}
          loading={loading}
          columns={columns}
          dataSource={filterDataList}
          pagination
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </Form>
  );
};

export default BookingUserList;
