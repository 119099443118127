import React, { Component, useEffect, useState } from "react";
import {
  Upload, message, Button
} from "antd";
import {
  UploadOutlined, InboxOutlined
} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import debugLib from "debug";
import { useTranslation } from "react-i18next";

const debug = debugLib("app:components/form/FormUploadFile");

const { Dragger } = Upload;

function FormUploadFile(props) {
  const {
    type,
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    fileList,
    disabled,
    fileObj = {},
    fileType = "image",
    style = {},
    containerStyle
  } = props;

  let DisplayContent;

  const { t } = useTranslation();

  const uploadProps = {
    name: "file",
    // multiple: true,
    action: fileType === "attachment" ? "/api/admin/attachment" : "/api/admin/media",
    beforeUpload: (file) => {
      if (fileType === "attachment" && file.type === "application/pdf") {
        message.success(t("Uploading"));
        return true;
      }
      if (file.type !== "image/png"
      && file.type !== "image/jpeg"
       && file.type !== "image/svg+xml") {
        message.error(t("only_jpg_or_png_can_be_uploaded"));
        return false;
      }
      message.success(t("Uploading"));
      return true;
    },
    onError(err) {
      console.error(err);
      message.error(`上載失敗，請重試`);
    }
  };

  if (type === "one") {
    DisplayContent = (
      <UploadOne
        uploadProps={uploadProps}
        fileList={fileList}
        imageURL={imageURL}
        data={data}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        disabled={disabled}
        style={style}
        containerStyle={containerStyle}
        fileObj={fileObj}
        fileType={fileType}
      />
    );
  } else {
    DisplayContent = (
      <UploadWidget
        uploadProps={uploadProps}
        imageURL={imageURL}
        data={data}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        disabled={disabled}
        style={style}
        containerStyle={containerStyle}
        fileObj={fileObj}
        fileType={fileType}
      />
    );
  }

  return DisplayContent;
}

function UploadOne(props) {
  console.log("UploadOne");
  const { t, i18n } = useTranslation();
  const {
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    fileList,
    uploadProps,
    disabled,
    style,
    containerStyle,
    fileObj,
    fileType
  } = props;

  const [image, setImage] = useState("");
  const [list, setfileList] = useState([]);

  useEffect(() => {
    setImage(imageURL);
  }, [imageURL]);
  return (
    <Dragger
      {...uploadProps}
      data={data}
      showUploadList={false}
      onChange={onChange}
      listType="text"
      onPreview={onPreview}
      onRemove={onRemove}
      disabled={disabled}
      style={{...containerStyle}}
    >
      {
        fileType === "attachment" && !_.isEmpty(fileObj) ? (
          <div>
            {t("pdf_upload")}
          </div>
        )
          : image
            ? (
              <div>
                <img src={image} style={{...style, maxWidth: "100%"}} alt="" />
              </div>
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("click_or_drag_the_picture_to_upload")}</p>
                <p className="ant-upload-hint">
                  {t("only_jpg_or_png_can_be_uploaded")}
                </p>
              </div>
            )
      }
    </Dragger>
  );
}

function UploadWidget(props) {
  console.log("UploadWidget");
  const { t, i18n } = useTranslation();
  const {
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    uploadProps,
    disabled,
    style,
    containerStyle
  } = props;

  useEffect(() => {}, [imageURL]);

  return (
    <Dragger
      {...uploadProps}
      showUploadList={false}
      data={data}
      onChange={onChange}
      listType="picture"
      onPreview={onPreview}
      onRemove={onRemove}
      disabled={disabled}
      style={{...containerStyle}}
    >
      {
        imageURL
          ? (
            <div>
              <img src={imageURL} style={{...style, maxWidth: "100%"}} alt="" />
            </div>
          ) : (
            <div>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("click_or_drag_the_picture_to_upload")}</p>
              <p className="ant-upload-hint">
                {t("only_jpg_or_png_can_be_uploaded")}
              </p>
            </div>
          )
      }
    </Dragger>
  );
}

export default FormUploadFile;
