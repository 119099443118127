import React, { useState, useEffect } from "react";
import {
  RangePicker,
  Modal,
  Spin,
  Table,
  Tooltip,
  Button,
  Popconfirm,
  notification,
  Divider,
  Tag,
  Form,
  Row,
  Col,
  DatePicker,
  Select
} from "antd";
import {
  SearchOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  isEmpty,
  filter
} from "lodash";
import dayjs from "dayjs";
import T9Button from "../../../t9UIComponents/t9Button";
import SearchBar from "../../../components/SearchBar";
import * as Main from "../../../core/Main";

const tableIDName = "company_user_token";
const FILTERABLE_FIELDS = ["item_name"];

const TokenUserList = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [userTokenMap, setUserTokenMap] = useState({});
  const [filterDataList, setFilterDataList] = useState([]);
  const [dateRange, setDateRange] = useState([]);


  const {tokenLogList} = props;

  useEffect(() => {
    setDataList(tokenLogList);
    setFilterDataList(tokenLogList);
  }, [props]);

  useEffect(() => {
    if (dateRange.length === 2) {
      const filteredData = dataList.filter((item) => {
        const itemDate = new Date(item.ctime * 1000);
        return itemDate >= dateRange[0] && itemDate <= dateRange[1];
      });
      setFilterDataList(filteredData);
    }
  }, [dateRange]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };


  const columns = [
    // {
    //   title: t("id"),
    //   dataIndex: "order_item_id",
    //   sorter: (a, b) => a.order_item_id - b.order_item_id
    // },
    {
      title: t("status"),
      dataIndex: "status",
      render: (value) => displayStatus(value),
      sorter: (a, b) => a.is_active - b.is_active
    },
    {
      title: t("Type"),
      render: (value) => displayIsType(value),
      dataIndex: "type",
    },
    {
      title: t("token_change"),
      dataIndex: "token_change",
      sorter: (a, b) => a.token_after - a.token_before - (b.token_after - b.token_before),
      render: (value, record) => displayTokenChange(value, record),
    },
    {
      title: t("token_before"),
      dataIndex: "token_before",
      sorter: (a, b) => a.token_before - b.token_before,
    },
    {
      title: t("token_after"),
      dataIndex: "token_after",
      sorter: (a, b) => a.token_after - b.token_after,
    },
    {
      title: t("expire_time"),
      dataIndex: "token_expiry_time",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.token_expiry_time - b.token_expiry_time
    },
    {
      title: t("update_time"),
      dataIndex: "utime",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.utime - b.utime
    },
    {
      title: t("create_time"),
      dataIndex: "ctime",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.ctime - b.ctime
    },
  ];

  const displayTokenChange = (value, record) => {
    const diff = record.token_after - record.token_before;

    if (diff < 0) return <div style={{ color: "red" }}>{`${value}`}</div>;
    if (diff > 0) return <div style={{ color: "green" }}>{`+${value}`}</div>;
    return <div>{`${value}`}</div>;
  };

  const displayIsType = (value) => {
    let displayStr = "";
    let tagColor = "green";
    switch (value) {
      case "order":
      case "order_create":
        displayStr = t("order_create");
        tagColor = "green";
        break;
      case "order_cancel":
        displayStr = t("order_cancel");
        tagColor = "red";
        break;
      case "top_up":
        displayStr = t("top_up");
        tagColor = "blue";
        break;
      case "add_by_admin":
        displayStr = t("add_by_admin");
        tagColor = "yellow";
        break;
      case "buy_token":
        displayStr = t("buy_token");
        tagColor = "purple";
        break;
      case "order_item_refund":
        displayStr = t("order_item_refund");
        tagColor = "orange";
        break;
      case "order_refund":
        displayStr = t("order_refund");
        tagColor = "orange";
        break;
      case "token_expired":
        displayStr = t("token_expired");
        tagColor = "orange";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = value;
    switch (statusValue) {
      case "success":
        displayStr = t("success");
        tagColor = "green";
        break;
      case "fail":
        displayStr = t("fail");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };
  return (
    <Form>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} align="middle">
          <Col>
            <span>{`${t("date")}：`}</span>
            <DatePicker.RangePicker
              allowClear={false}
              value={dateRange}
              onChange={handleDateRangeChange}
            />
          </Col>
        </Row>

        <Divider />
        <Table
          rowKey={tableIDName}
          loading={loading}
          columns={columns}
          dataSource={filterDataList}
          pagination
          scroll={{ x: "max-content" }}
        />
      </Spin>
    </Form>
  );
};
export default TokenUserList;
