import { Checkbox, Modal, Space, notification, Spin} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MailOutlined } from "@ant-design/icons";
import * as Service from "../../core/Service";
import _ from "lodash";

const SendEmailPopup = ({ modalVisible, setModalVisible, selectedUsers }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSendEmail = async () => {
    // fetch api
    setLoading(true);
    try {
      console.log({ options, selectedUsers});
      // Check has selection
      if (options.length === 0) {
        return notification.error({
          message: t("error"),
          description: t("not have choice"),
        });
      }
      const dataObj = {
        list: [],
        event_id: selectedUsers[0].event_id,
      };

      _.each(options, (type) => {
        _.each(selectedUsers, (user) => {
          dataObj.list.push({
            type,
            event_enrollment_id: user.event_enrollment_id,
          });
        });
      });

      const resp = await Service.call("post", "/api/event/enrollment/email", dataObj);
      console.log(`resp`);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }

      return notification.success({
        message: t("Success"),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  const onCancel = () => {
    setModalVisible(false);
  }

  const selectOptions = [
    {
      label: (
        <Space>
          <MailOutlined />
          {t("invitation_mail_to_selected_user")}
        </Space>
      ),
      value: "invitation",
    },
    // {
    //   label: (
    //     <Space>
    //       <MailOutlined />
    //       {t("reminder_mail_to_selected_user")}
    //     </Space>
    //   ),
    //   value: "reminder",
    // },
  ];
  return (
    <Modal
      title={t("send_mail")}
      centered
      visible={modalVisible}
      onOk={handleSendEmail}
      onCancel={onCancel}
      className="send-email-modal"
      style={{maxWidth: 352}}
    >
      <Spin spinning={loading}>
        <Checkbox.Group options={selectOptions} onChange={setOptions} />
      </Spin>
    </Modal>
  );
};

export default SendEmailPopup;
