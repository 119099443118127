import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
// import MediaLibraryComponent from "./MediaLibraryComponent";

const debug = require("debug")("app:src:components:TinymceWrapper");

const TinymceWrapper = (props) => {
  const editorRef = useRef(null);
  const [currentEditor, setCurrentEditor] = useState("");
  const config = useSelector((state) => state.app.config);
  const [showModal, setShowModal] = useState(false);
  const { TINYMCE_API_KEY } = config;

  const { initialValue, onChange } = props;

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const image_upload_handler = async(blobInfo, success, failure, progress) => {
    let fromdata = new FormData();
    fromdata.append("scope", "public");
    fromdata.append("file", blobInfo.blob(), blobInfo.filename());

    const response = await axios.post("/api/admin/media", fromdata);
    let image_path = response.data.filepath;
    image_path = config.API_ORIGIN + "/" + image_path;
    success(image_path);
  };

  function handleEditorChange(e) {
    // console.log(
    //   'Content was updated:',
    //   e.target.getContent()
    // );
    onChange(e.target.getContent());
  }

  // const selectMedia = (media) => {
  //   console.log("Media", media);
  //   let rawHTML = `<img src="${config.STATIC_SERVER_URL}/${media.filepath}" alt="${media.alt}" />`;
  //   currentEditor.insertContent(rawHTML);
  // };

  return (
    <>
      <Editor
        apiKey={TINYMCE_API_KEY}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={initialValue}
        init={{
          // selector: 'textarea',  // change this value according to your HTML
          height: 500,
          min_height: 200,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | image | code | help",

          // image_list: [
          //   {title: 'My image 1', value: 'https://www.media_library.com/my1.gif'},
          //   {title: 'My image 2', value: 'http://www.moxiecode.com/my2.gif'}
          // ],
          image_title: true,
          images_upload_handler: image_upload_handler,
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          // setup: function (editor) {
          //   editor.ui.registry.addButton("media_library", {
          //     text: "Media Library",
          //     onAction: function () {
          //       setCurrentEditor(editor);
          //       setShowModal(true);
          //     },
          //   });
          // },
        }}
        onEditorChange={(newValue, editor) => onChange(newValue)}
      />
      {/* <MediaLibraryComponent
        showModal={showModal}
        cancelCallback={() => setShowModal(false)}
        fromEditor
        selectCallback={(mediaObj) => {
          selectMedia(mediaObj);
          setShowModal(false);
        }}
      /> */}
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
};

export default TinymceWrapper;
