import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Card,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import * as Service from "../core/Service";

const UserAccountSettingResetPasswordForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { initialValues, isAdmin } = props;
  const company_admin = useSelector((state) => state.app.company_admin)
  const { key } = queryString.parse(location.search);
  const history = useHistory();

  const [UserForm] = Form.useForm();

  useEffect(() => {
    UserForm.resetFields();
  }, []);

  const onFinish = async (formData) => {

    let patchData = {...formData, company_admin_id: company_admin.company_admin_id };
    // console.log("formData", formData)
    if (company_admin.company_admin_id > 0) {
      let response = await Service.call(
        "patch",
        `/api/company/admin/pw`,
        patchData
      );
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label={t("edit_password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("confirm_password")}
              name="confirm_password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("密碼不相同");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserAccountSettingResetPasswordForm;
