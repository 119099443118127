import React, { useState, useEffect } from 'react';
import _, { initial } from 'lodash';
import {
  Button, Divider, Form, Input, Tag, Row, Col, Card, Tooltip, Select, notification
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Main from '../../../core/Main';
import * as Service from '../../../core/Service';
import NavAndSideFrame from '../../../components/NavAndSideFrame';

const { Option } = Select;

const UserAccountForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation()
  const { initialValues } = props;
  const company_admin = useSelector((state) => state.app.company_admin);

  const [UserForm] = Form.useForm();
  const [number, setNumber] = useState("");

  useEffect(() => {
    UserForm.resetFields()
    let [mobile_prefix, mobile] = _.split(initialValues.mobile, "-");
    setNumber(mobile);
    initialValues.mobile_prefix = mobile_prefix;
    initialValues.mobile = mobile;
    UserForm.setFieldsValue(initialValues);
  }, [initialValues])

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      UserForm.setFieldsValue({ mobile: value })
    }
  };

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t('yes');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("no");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const onFinish = async (postData) => {
    let patchData = {
      ...postData,
      company_admin_id: company_admin.company_admin_id,
      mobile: `${postData.mobile_prefix}-${postData.mobile}`,
    };
    let response = _.noop();
    try {
      response = Service.call("patch", "/api/company/admin", patchData);
    } catch (error) {
      // console.log(error)
    }
    if (response.status === -1) {
      notification.error({
        message: t("error"),
        description: t(response.errorCode) 
      });
      return;
    }
    notification.success({
      message: t("success"),
    });
  }

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('company_name')}
            >
              {initialValues.company_name}
            </Form.Item>

            <Form.Item
              label={t('username')}
              name="username"
            >
              <Input maxLength={40} disabled />
            </Form.Item>

            <Form.Item
              label={t('first_name')}
              name="first_name"
            >
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item
              label={t('last_name')}
              name="last_name"
            >
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item
              label={t('gender')}
              name="gender"
            >
              <Select>
                <Option value="M">{t('male')}</Option>
                <Option value="F">{t('female')}</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t('nickname')}
              name="nickname"
            >
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item
              label={t('email')}
              name="email"
            >
              <Input maxLength={255} />
            </Form.Item>

            <Form.Item
              label={t('moblie')}
              name="mobile"
            >
              <Tooltip>
                <Input
                  value={number}
                  onChange={onNumberChange}
                  addonBefore={(
                    <Form.Item name="mobile_prefix" noStyle>
                      <Select style={{ width: 80 }}>
                        <Option value="+852">+852</Option>
                      </Select>
                    </Form.Item>
                  )}
                  maxLength={15}
                  style={{ width: '100%' }}
                />
              </Tooltip>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col md={24} lg={8}>
        <Card>
          <h4>{t('last_active_time')}</h4>
          <p>{moment.unix(initialValues.utime).format('YYYY-MM-DD HH:mm')}</p>
          <h4>{t('create_time')}</h4>
          <p>{moment.unix(initialValues.create_time).format('YYYY-MM-DD HH:mm')}</p>
          <h4>{t('is_verified_email')}</h4>
          <p>{displayStatus(initialValues.is_verified_email)}</p>
          <h4>{t('is_verified_mobile')}</h4>
          <p>{displayStatus(initialValues.is_verified_mobile)}</p>
        </Card>
      </Col>
    </Row>
  )
}

export default UserAccountForm;
