import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  notification,
  Row,
  Col,
  Select,
  Spin,
  message,
  Button,
  Empty,
} from "antd";
import _, {
  groupBy,
  keyBy,
  map,
  includes,
  isEmpty,
  startsWith,
  endsWith,
  split,
  inRange,
} from "lodash";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import * as Service from "../../../core/Service";
// import Button from "../../../t9UIComponents/t9Button";
import QRcodeDummy from "../../../assets/QRcodeDummy.svg";
import EventQRReader from "./EventQRReader";
import EventQRScanner from "./EventQRScanner";
import { FONT, COLOR } from "../../../theme";

const selectedKey = "check_in";
const openKeys = "event_management";

const { Option } = Select;

const NON_DISPLAY_KEY = [
  "ctime",
  "utime",
  "company_user_id",
  "event_id",
  "event_user_id",
  "event_date_id",
  "status",
  "company_admin_id",
  "company_user_id",
  "parent_company_user_id",
  "is_delete",
  "event_enrollment_id",
  "check_in_status"
];

const TIME_KEY = [
  "event_start_time",
  "event_end_time"
];

const FACING_MODE = {
  BACK: "environment",
  FRONT: "user"
};


const EventCheckIn = (props) => {
  const { t } = useTranslation();
  // const isDesktop = screens.xl;
  const [modalVisible, setModalVisible] = useState(false);
  const [scannerType, setScannerType] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE.BACK);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [selectedEnrollmentRc, setSelectedEnrollmentRc] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [numOfCamera, setNumOfCamera] = useState(1);
  // const [dataList, setDataList] = useState({
  //   eventUserInfo: {},
  //   eventEnrollmentList: []
  // })
  const [dataList, setDataList] = useState({

  });
  const [loading, setLoading] = useState(false);
  const title = t("check_in");
  const disableConfirmButton = !selectedEnrollment || selectedEnrollmentRc?.status !== 1;

  const resetState = () => {
    setDataList({
      eventInfoList: [],
      keyByEventInfo: {},
      GroupByEnrollmentList: {},
      keyByEnrollmentList: {},
    });
    setSelectedEvent({});
    setSelectedEnrollment(null);
    setSelectedEnrollmentRc({});
    setShowWarning(null);
    setModalVisible(false);
  };

  const onScan = async (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (!(startsWith(data, "v") && endsWith(data, "|") && split(data, ".").length > 1)) {
        message.error("QR Code not valid");
        return;
      }

      const resp = await Service.call("post", "/api/event/verify", {
        qrCodeData: data
      });

      if (!resp || resp.status !== 1) {
        return message.error(t(resp.errorCode));
      }
      const {
        eventInfoList,
        eventEnrollmentList
      } = resp.data;
      console.log("resp >>>", resp);
      setDataList({
        eventInfoList,
        keyByEventInfo: keyBy(eventInfoList, "event_id"),
        GroupByEnrollmentList: groupBy(eventEnrollmentList, "event_id"),
        keyByEnrollmentList: keyBy(eventEnrollmentList, "event_enrollment_id"),
      });
      setModalVisible(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmCheckIn = async () => {
    if (selectedEnrollmentRc.check_in_status === 1) {
      return notification.warning({
        message: t("warning"),
        description: t("user_checked_in_already")
      });
    }

    setLoading(true);
    try {
      const resp = await Service.call("post", "/api/event/check_in", {
        event_id: selectedEvent.eventInfo.event_id,
        event_enrollment_id: selectedEnrollment,
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
        description: t("check_in_success")
      });
      resetState();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const backReader = () => {
    setScannerType("reader");
    setFacingMode(FACING_MODE.BACK);
  };

  const backScanner = () => {
    setScannerType("scanner");
    setFacingMode(FACING_MODE.BACK);
  };

  const frontReader = () => {
    setScannerType("reader");
    setFacingMode(FACING_MODE.FRONT);
  };

  const frontScanner = () => {
    setScannerType("scanner");
    setFacingMode(FACING_MODE.FRONT);
  };

  let getNumOfCamera = async () => {
    if (!navigator?.mediaDevices?.enumerateDevices()) {
      return 1;
    }
    let permission = await navigator?.mediaDevices?.getUserMedia({ video: true });

    if (permission) {
      let devices = await navigator?.mediaDevices?.enumerateDevices();
      let result = 0;
      _.forEach(devices, (device) => {
        if (device?.kind === "videoinput") {
          result += 1;
        }
      });
      return result;
    }
    return 1;
  };

  let updateNumOfCamera = async () => {
    let number = await getNumOfCamera();
    setNumOfCamera(number);
  };

  useEffect(() => {
    updateNumOfCamera();
  }, []);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[16, 24]}>
        <div style={{}}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={frontReader}
            >
              {t("scan_to_check_in_front_camera")}
            </Button>
          </Col>
        </div>
        <div style={{ justifyContent: "center" }}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={frontScanner}
            >
              {t("scan_to_check_in_plugin_front_camera")}
            </Button>
          </Col>
        </div>
        {
          numOfCamera > 1 && (
            <>
              <div style={{ justifyContent: "center" }}>
                <Col span={8}>
                  <Button
                    type="primary"
                    onClick={backReader}
                  >
                    {t("scan_to_check_in_back_camera")}
                  </Button>
                </Col>
              </div>
              <div style={{ justifyContent: "center" }}>
                <Col span={8}>
                  <Button
                    type="primary"
                    onClick={backScanner}
                  >
                    {t("scan_to_check_in_plugin_back_camera")}
                  </Button>
                </Col>
              </div>
            </>
          )
        }
      </Row>

      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {scannerType === "reader" && facingMode === FACING_MODE.BACK && !modalVisible && (
            <EventQRReader
              onScan={onScan}
              scanDelay={2000}
              footer={null}
              facingMode={FACING_MODE.BACK}
            />
          )}
          {scannerType === "reader" && facingMode === FACING_MODE.FRONT && !modalVisible && (
            <EventQRReader
              onScan={onScan}
              scanDelay={2000}
              footer={null}
              facingMode={FACING_MODE.FRONT}
            />
          )}

          {scannerType === "scanner" && facingMode === FACING_MODE.BACK && !modalVisible && (
            <EventQRScanner
              onScan={onScan}
              footer={null}
              facingMode={FACING_MODE.BACK}
            />
          )}

          {scannerType === "scanner" && facingMode === FACING_MODE.FRONT && !modalVisible && (
            <EventQRScanner
              onScan={onScan}
              footer={null}
              facingMode={FACING_MODE.FRONT}
            />
          )}
        </Col>
      </Row>
      <Modal
        destroyOnClose
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 600 }}
        width="100%"
        onCancel={resetState}
      >
        <div style={{ ...styles.header }}>{t("user_confirmation")}</div>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder={t("select_event")}
              onChange={(event_id) => {
                if (!event_id) return;
                setSelectedEvent({
                  eventInfo: dataList.keyByEventInfo[event_id],
                  eventEnrollmentList: dataList.GroupByEnrollmentList[event_id],
                });
                setSelectedEnrollmentRc({});
                setSelectedEnrollment(null);
                setShowWarning(false);
              }}
            >
              {map(dataList.eventInfoList, (eachEvent) => {
                const {
                  event_name,
                  event_id,
                } = eachEvent;
                return (
                  <Option
                    key={event_id}
                    value={event_id}
                  >
                    {event_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {!isEmpty(selectedEvent) && (
            <>
              <Col span={24}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("select_enrollment")}
                  onChange={(event_enrollment_id) => {
                    setShowWarning(false);
                    const enrollmentInfo = dataList.keyByEnrollmentList[event_enrollment_id];
                    if (enrollmentInfo
                      && !inRange(
                        dayjs().unix(),
                        enrollmentInfo.event_start_time,
                        enrollmentInfo.event_end_time
                      )) {
                      setShowWarning(true);
                    }
                    setSelectedEnrollmentRc(enrollmentInfo);
                    setSelectedEnrollment(event_enrollment_id);
                  }}
                  value={selectedEnrollment}
                >
                  {map(selectedEvent.eventEnrollmentList, (eachEnrollment) => {
                    const {
                      event_enrollment_id,
                      event_start_time,
                      event_end_time,
                      check_in_status,
                      status,
                    } = eachEnrollment;
                    return (
                      <Option
                        key={event_enrollment_id}
                        value={event_enrollment_id}
                      >
                        {status !== 1 && (`(${t("not_approved")})`)}
                        {check_in_status && (`(${t("checked_in")})`)}
                        {`${dayjs.unix(event_start_time).format("YYYY-MM-DD HH:mm")}
                      - ${dayjs.unix(event_end_time).format("YYYY-MM-DD HH:mm")}`}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              {map(selectedEnrollmentRc, (value, key) => {
                if (includes(NON_DISPLAY_KEY, key) || !value) {
                  return;
                }
                return (
                  <React.Fragment key={key}>
                    <Col
                      span={10}
                      style={{
                        ...FONT.bodyText2Regular,
                        minWidth: "30px"
                      }}
                    >
                      {t(key)}
                    </Col>
                    <Col span={1}>:</Col>
                    <Col
                      span={10}
                      style={{
                        ...FONT.bodyText2Regular,
                        minWidth: "30px",
                        color: COLOR.primary.primary500
                      }}
                    >
                      {includes(TIME_KEY, key) ? dayjs.unix(value).format("YYYY-MM-DD HH:mm") : value}
                    </Col>
                  </React.Fragment>
                );
              })}
              {showWarning && (
                <div
                  style={{
                    color: COLOR.warning.warning300
                  }}
                >
                  {t("check_in_warning")}
                </div>
              )}
              <Col span={24}>
                <Button
                  disabled={disableConfirmButton}
                  style={{
                    ...FONT.bodyText2Regular,
                    width: "100%",
                    height: 33,
                    color: COLOR.text.whiteText,
                    backgroundColor: disableConfirmButton
                      ? COLOR.function.disable : COLOR.primary.primary400,
                    borderRadius: 4
                  }}
                  onClick={() => confirmCheckIn()}
                  loading={loading}
                >
                  {t("confirm")}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </NavAndSideFrame>
  );
};

const styles = {
  button: {
    background: "#308DDE",
    boarderRadius: "4px",
    color: "white",
    width: "77px",
    height: "33px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontSize: 16,
    paddingBottom: 8,
    borderBottom: "0.5px solid #D4D4D4",
    color: "#1E73BE",
    fontWeight: 700,
    lineHeight: "24px",
    marginBottom: 20
  },
  data: {
    color: "#1E73BE",
    AlignSelf: "center"
  }
};

export default EventCheckIn;
