import React from "react";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import CreateBookingOrderForm from "./CreateBookingOrderForm";
import { useTranslation } from 'react-i18next';

const CreateBookingOrder = () => {
  const { t } = useTranslation();
  const selectedKey = "create_booking_order";
  const openKeys = "booking_management";
  return (
    <NavAndSideFrame
      title={t("create_booking_order")}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <CreateBookingOrderForm />
    </NavAndSideFrame>
  );
};

export default CreateBookingOrder;
