import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Row,
  Col,
  Card,
  Switch,
  Collapse,
  InputNumber
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DownOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Service from "../core/Service";

const { Option } = Select;
const { Panel } = Collapse;

const LOGIN_METHOD = {
  USERNAME: 1,
  EMAIL: 2,
  MOBILE: 3,
  BOTH_REQUIRE_WITH_EMAIL_LOGIN: 4,
};

const passwordReg = /.{8,50}$/;

const UserAcInfoForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const {
    initialValues,
    setUser,
    companyConfigRc,
    isAdmin
  } = props;
  const id = initialValues.company_user_id;
  const [loading, setLoading] = useState(false);
  const [initialValuesForm, setInitialValues] = useState({
    mobilePrefix: "+852",
    is_admin: 0,
  });
  const [userInitialData, setUserInitialData] = useState({});
  const [UserForm] = Form.useForm();
  const [UserStatusForm] = Form.useForm();
  const [UserResetPWForm] = Form.useForm();
  const history = useHistory();
  const { key } = queryString.parse(location.search);

  useEffect(() => {
    UserForm.resetFields();
    UserResetPWForm.resetFields();
    UserStatusForm.resetFields();
    if (id > 0) {
      // initialValues append to initialValuesForm
      const [mobilePrefix, mobile] = _.split(initialValues.mobile, "-");
      const newObject = {
        ...initialValuesForm,
        ...initialValues,
        mobile,
        mobilePrefix
      };

      setUserInitialData({
        mobilePrefix,
        mobile,
        email: initialValues.email,
      });
      if (mobilePrefix === "+0") {
        newObject.mobilePrefix = "";
        newObject.mobile = "";
      }
      if (_.startsWith(initialValues.email, "user") && _.endsWith(initialValues.email, "@vms.io")) {
        newObject.email = "";
      }
      UserForm.setFieldsValue(newObject);
    }
  }, [props]);

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      const {
        mobile,
        mobilePrefix,
        email
      } = formData;
      let postData = {
        ...formData,
        preferred_language: "zh",
        company_user_id: id,
        is_admin: formData.is_admin === true ? 1 : 0,
        mobile: _.isEmpty(mobile) || _.isEmpty(mobilePrefix)
          ? initialValues.mobile : `${mobilePrefix}-${mobile}`,
        email: _.isEmpty(email)
          ? initialValues.email : email,
      };
      // Check password when creating user
      if ((id === 0 || !id) && !validatePassword(formData.password)) {
        return;
      }
      let method = "PUT";
      if (id > 0) {
        method = "PATCH";
      }
      let resp = await Service.call(method, `/api/company/user`, postData);

      if (!resp || resp.status === -1) {
        setLoading(false);
        notification.error({
          message: `${t("error")}: ${resp.errorMessage}[${resp.errorCode}]`,
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      const { data } = resp;
      setUser(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8 || password.length > 50) {
      notification.error({
        message: t("error"),
        description: t("password_length_too_short"),
      });
      return false;
    }
    // if (password.search(/[a-z]/i) < 0) {
    //   notification.error({
    //     message: t("error"),
    //     description: t("password_should_include_character"),
    //   });
    //   return false;
    // }
    // if (password.search(/[0-9]/i) < 0) {
    //   notification.error({
    //     message: t("error"),
    //     description: t("password_should_include_number"),
    //   });
    //   return false;
    // }
    return true;
  };

  const patchUserStatus = async (formData) => {
    if (id > 0) {
      let response = await Service.call("patch", `/api/company/user/status`, formData);
      if (response.status === -1) {
        notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
    }
  };

  const editPassword = async (formData) => {
    try {
      setLoading(true);
      let patchData = { ...formData, company_user_id: id };
      // Check password
      if (!validatePassword(formData.password)) {
        return;
      }
      if (id > 0) {
        let response = await Service.call(
          "patch",
          `/api/company/user/password`,
          patchData
        );
        if (response.status === -1) {
          notification.error({
            message: t(`error`),
            description: t(response.errorCode),
          });
          return;
        }
        notification.success({
          message: t("success"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      onFinish={onFinish}
    >
      <Row gutter={[24, 24]}>
        <Col md={24} lg={16}>
          <Card>
            <Form.Item
              label={t("username")}
              name="username"
              rules={[
                {
                  required: _.includes(
                    [LOGIN_METHOD.USERNAME],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_username"),
                },
              ]}
            >
              {/* {...userDetailMap.username} */}
              <Input
                maxLength={40}
                disabled={
                  LOGIN_METHOD.USERNAME !== companyConfigRc?.login_method
                }

              />

            </Form.Item>
            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("invalid_email"),
                },
                {
                  required: _.includes(
                    [
                      LOGIN_METHOD.EMAIL,
                      LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                    ],
                    companyConfigRc?.login_method
                  ) || (id > 0 && !_.startsWith(initialValues.email, "user") && !_.endsWith(initialValues.email, "@vms.io")),
                  message: t("please_input_email"),
                },
              ]}
            >
              <Input maxLength={128} />
            </Form.Item>

            <Form.Item
              // contact_person_mobile
              label={t("mobile")}
              name="mobile"
              rules={[
                {
                  required: _.includes(
                    [
                      LOGIN_METHOD.MOBILE,
                      LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                    ],
                    companyConfigRc?.login_method
                  ) || (id > 0 && userInitialData.mobilePrefix !== "+0"),
                  message: t("please_input_mobile"),
                },
              ]}
            >
              <Input
                addonBefore={(
                  <Form.Item
                    name="mobilePrefix"
                    noStyle
                    rules={[
                      {
                        required: _.includes(
                          [
                            LOGIN_METHOD.MOBILE,
                            LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                          ],
                          companyConfigRc?.login_method
                        ) || (id > 0 && userInitialData.mobilePrefix !== "+0"),
                        message: t("select_required"),
                      },
                    ]}
                  >
                    <Select style={{ width: 80 }}>
                      <Option value="+852">+852</Option>
                      <Option value="+853">+853</Option>
                      <Option value="+86">+86</Option>
                    </Select>
                  </Form.Item>
                )}
                style={{ width: "100%" }}
                maxLength={15}
              />
            </Form.Item>

            {!id && (
              <Form.Item
                label={t("password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                  {
                    pattern: passwordReg,
                    message: t("password_validation")
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            {!id && (
              <Form.Item
                label={t("confirm_password")}
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("password_not_match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}
            <Form.Item
              label={t("nickname")}
              name="nickname"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label={t("remarks")}
              name="remarks"
            >
              <Input maxLength={50} />
            </Form.Item>
            {/* <Form.Item label={t("gender")} name="gender">
              <Select>
                <Option value="M">{t("male")}</Option>
                <Option value="F">{t("female")}</Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label={t('preferred_language')}
              name="preferred_language"
            >
              <Select>
                <Option value="zh">{t('zh')}</Option>
                <Option value="en">{t('en')}</Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label={t("is_admin")}
              name="is_admin"
              valuePropName="checked"
            >
              <Switch checked={initialValues.is_admin === 1} />
            </Form.Item> */}
            <Form.Item>
              <div style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("save_change")}
                </Button>
              </div>
            </Form.Item>
          </Card>
          {id && (
            <>
              <Collapse
                bordered={false}
                ghost
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                style={{ marginTop: 24 }}
              >
                <Panel header={t("user_status")} key="1">
                  <Form
                    form={UserStatusForm}
                    name="UserStatusForm"
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={patchUserStatus}
                  >
                    <Form.Item noStyle={true} name="company_user_id" value={id} />
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label={t("active_status")}
                          name="is_active"
                          rules={[
                            {
                              required: true,
                              message: t("select_required"),
                            },
                          ]}
                        >
                          <Select style={{ width: "100%" }} placeholder={t("selectLabel")}>
                            <Option value={1}>{t("active")}</Option>
                            <Option value={0}>{t("inactive")}</Option>
                          </Select>
                        </Form.Item>


                      </Col>
                    </Row>
                    <Form.Item>
                      <div style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit">
                          {t("save_change")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Panel>
              </Collapse>
              <Collapse
                bordered={false}
                ghost
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                style={{ marginTop: 24 }}
              >
                <Panel header={t("edit_password")} key="2">
                  <Col span={8}>
                    <Form
                      form={UserResetPWForm}
                      name="UserResetPWForm"
                      layout="vertical"
                      onFinish={editPassword}
                    >
                      <Form.Item
                        label={t("edit_password")}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("input_required"),
                          },
                          {
                            pattern: /\S{8,50}/,
                            message: t("password_validation")
                          }
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label={t("confirm_password")}
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            message: t("input_required"),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(t("password_not_match"));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item>
                        <div style={{ textAlign: "right" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {t("save_change")}
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </Col>
                </Panel>
              </Collapse>
            </>
          )}
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <h4>{t("last_active_time")}</h4>
            <p>
              {initialValues.last_active_time
                ? moment
                  .unix(initialValues.last_active_time)
                  .format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            <h4>{t("create_time")}</h4>
            <p>
              {initialValues.ctime
                ? moment.unix(initialValues.ctime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            {/* <Form.Item
              label={t("is_verified_email")}
              name="is_email_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item>
            <Form.Item
              label={t("is_verified_mobile")}
              name="is_mobile_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item> */}
          </Card>
        </Col>
      </Row>
    </Form >
  );
};

export default UserAcInfoForm;
