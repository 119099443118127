import React, { Component, useState, useEffect } from "react";
import { Button, Layout, Popconfirm, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  CopyOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const BookingItemRuleSetTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "booking_item_price_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    isActiveChangeCallBack,
    handleDuplicateRecord
  } = props;

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  isActiveChangeCallBack(
                    record.booking_item_timeslot_id,
                    is_active
                  );
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={record.is_active ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={t("confirm_duplicate")}
                onConfirm={() => {
                  console.log("record: ", record);
                  handleDuplicateRecord(record.booking_item_timeslot_id);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={t("duplicate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("name"),
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t("remark"),
        dataIndex: "remark",
        render: (value) => _.truncate(value, { length: 30 }),
        sorter: (a, b) => a.remark.localeCompare(b.remark),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>
  );
};
export default BookingItemRuleSetTable;
