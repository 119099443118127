import React, { useState, useEffect } from "react";
import {
  Button, Layout, message, Modal, Popconfirm, Table, Tag, Tooltip, notification, Spin
} from "antd";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  MailOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as Service from "../../core/Service";
import SendEmailToolTip from "./SendEmailToolTip";

const EventTable = (props) => {
  const [dataList, setDataList] = useState([]);
  const [mapKeys, setMapKeys] = useState({
    periodMapKeys: {},
  });

  const tableIDName = "event_id";
  const admin = useSelector((state) => state.app.admin);
  const { t } = useTranslation();
  const { dataObj, periodList } = props;

  const {
    loading,
    setLoading,
    getAllData = () => null,
  } = props;

  useEffect(() => {
    const periodMapKeys = _.groupBy(periodList, tableIDName);
    setMapKeys({
      periodMapKeys
    });
  }, [periodList]);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          const { is_active, is_deleted } = record;
          return (
            <span>
              <Tooltip title="Edit">
                <Link to={{
                  pathname: `/company/event/${record.event_key}`
                }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              {/* <Popconfirm
                title={
                  is_active === 1
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  handleIsActiveChange(value);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={is_active === 1 ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      is_active === 1 ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm> */}
              {/* <Tooltip title={<SendEmailToolTip />} overlayClassName="send-mail-tooltip">
                <Button
                  shape="circle"
                  icon={<MailOutlined />}
                  style={{ marginRight: 4 }}
                />
              </Tooltip> */}
              {/* <Tooltip title="Delete Event">
                <Popconfirm
                  placement="top"
                  title="Are you sure to delete event ?"
                  disabled={is_deleted === 1}
                  onConfirm={async () => {
                    await deleteEvent(value);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    shape="circle"
                    disabled={is_deleted === 1}
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Tooltip> */}
            </span>
          );
        }
      },
      {
        title: t("title"),
        dataIndex: "event_name",
        sorter: (a, b) => a.event_name.localeCompare(b.event_name),
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("type"),
        dataIndex: "event_type",
        render: (value) => displayType(value),
        sorter: (a, b) => a.event_type.localeCompare(b.event_type),
      },
      {
        title: t("event_key"),
        dataIndex: "event_key",
      },
      {
        title: t("event_section"),
        dataIndex: tableIDName,
        // align: "left",
        // render: (value) => displayMoment(value),
        // sorter: (a, b) => a.event_start_time - b.event_start_time,
        render: (value) => {
          const periods = mapKeys.periodMapKeys[value];
          if (_.isUndefined(periods)) return "-";
          // console.log(periods)
          return _.map(periods, ({ event_start_date, event_end_date }, index) => (
            <div>
              <Tag key={`period_${index}`}>
                {displayMoment(event_start_date)}
                {" "}
                -
                {" "}
                {displayMoment(event_end_date)}
              </Tag>
            </div>
          ));
        }
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.ctime - b.ctime
      }
      // {
      //   title: "Registration Start Time",
      //   dataIndex: "event_registration_start",
      //   render: (value) => displayMoment(value),
      //   sorter: (a, b) => a.event_registration_start - b.event_registration_start,
      // },
      // {
      //   title: "Registration End Time",
      //   dataIndex: "event_registration_end",
      //   render: (value) => displayMoment(value),
      //   sorter: (a, b) => a.event_registration_end - b.event_registration_end,
      // },
      // {
      //   title: t("price"),
      //   dataIndex: "price",
      // },
      // {
      //   title: t("deleted"),
      //   dataIndex: `is_deleted`,
      //   render: (value) => displayIsDeleted(value),
      //   sorter: (a, b) => a.is_deleted - b.is_deleted,
      // }
    ];
    return columns;
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    let displayTime = "";
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = "-";
    }
    return displayTime;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Active";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Inactive";
        tagColor = "red";
        break;
      case -1:
        displayStr = "Disable";
        tagColor = "#9A9A9A";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayType = (value) => {
    let displayStr = "";
    let tagColor = "cyan";
    switch (value) {
      case "public":
        displayStr = "Public";
        tagColor = "cyan";
        break;
      case "private":
        displayStr = "Special Member Only";
        tagColor = "blue";
        break;
      default: {
        displayStr = "Error";
        tagColor = "#f50";
        break;
      }
    }
    return <Tag color={tagColor} style={{ width: "100%", textAlign: "center" }}>{displayStr}</Tag>;
  };

  const displayIsDeleted = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Yes";
        tagColor = "red";
        break;
      case 0:
        displayStr = "No";
        tagColor = "green";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  // const deleteEvent = async (event_id) => {
  //   setLoading(true);
  //   try {
  //     let resp = await Service.call("delete", `/api/event/`, { event_id });
  //     handleResp(resp);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleResp = (resp) => {
  //   try {
  //     if (resp.status === 1) {
  //       message.success("Success");
  //     }
  //     if (resp.status !== 1) {
  //       return message.error(resp.errorMessage);
  //     }
  //     if (admin.admin_id == null) {
  //       props.getAllData();
  //     }
  //     if (admin.admin_id != null) {
  //       props.adminChangeData();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleIsActiveChange = async (event_id) => {
  //   setLoading(true);
  //   try {
  //     let postData = {
  //       event_id,
  //     };
  //     let submit = await Service.call(
  //       "patch",
  //       "/api/event/is_active",
  //       postData
  //     );
  //     if (submit.status < 1) {
  //       return notification.error({
  //         message: t("fail"),
  //         description: t(`${submit.errorCode}`),
  //       });
  //     }
  //     notification.success({
  //       message: t("success"),
  //     });
  //     getAllData();
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Layout>
      <Spin spinning={loading}>
        <Table
          loading={loading}
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={dataList}
          columns={setTableHeader()}
          size="small"
        />
      </Spin>
    </Layout>
  );
};

export default EventTable;
