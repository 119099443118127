import React, { Component, useState, useEffect } from "react";
import { Button, Layout, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const CompanyHolidayListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "booking_item_holiday_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    isActiveChangeCallBack,
  } = props;
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  onClick={() => {
                    let active_value = record.is_active ? 0 : 1;
                    isActiveChangeCallBack(
                      record.booking_item_holiday_id,
                      active_value
                    );
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("desc"),
        dataIndex: "description",
        sorter: (a, b) => a.description - b.description,
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value),
      },
      {
        title: t("date"),
        dataIndex: "start_time",
        sorter: (a, b) => a.start_time,
        render: (value) => {
          return moment.unix(value).format("YYYY-MM-DD");
        },
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (utime) => {
          return moment.unix(utime).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>
  );
};

export default CompanyHolidayListTable;
