import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Spin,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  Switch
} from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import RangePickerProps from "antd/es/date-picker";
import dayjs from "dayjs";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import TinymceWrapper from "../../components/TinymceWrapper";
import * as Service from "../../core/Service";
import T9Button from "../../t9UIComponents/t9Button";
import * as Main from "../../core/Main";
import { formItemLayout } from "../../components/ModalLayout";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DAY_IN_SECONDS = 86400;

const TokenPackageForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [TokenPackage, setTokenPackage] = useState({
    description: ""
  });
  const [switchBool, setSwitchBool] = useState({
    effective_all_time: 0
  });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state.token_package_id > 0) {
      form.setFieldsValue({
        ...location.state,
        expiry_time: location.state.expiry_time / DAY_IN_SECONDS,
        effective_start_end: [
          moment.unix(location.state.effective_start),
          moment.unix(location.state.effective_end),
        ],
      });
      setSwitchBool({
        effective_all_time: location.state.effective_all_time === 1,
      });
    }
    setLoading(false);
  }, [location]);

  const onFinish = async (formData) => {
    setLoading(true);
    const {
      effective_start_end,
      expiry_time
    } = formData;
    let start_time = _.head(effective_start_end);
    let end_time = _.last(effective_start_end);
    try {
      let postData = {
        ...formData,
        package_desc: TokenPackage.description,
        effective_all_time: switchBool.effective_all_time ? 1 : 0,
        effective_start: switchBool.effective_all_time
          ? 0
          : start_time.unix(),
        effective_end: switchBool.effective_all_time
          ? 0
          : end_time.unix(),
        expiry_time: expiry_time * DAY_IN_SECONDS
      };
      console.log(postData);

      let method = "post";
      const url = "/api/token/package";

      if (formData.token_package_id > 0) {
        method = "patch";
      }
      const resp = await Service.call(method, url, postData);
      if (!resp || resp.status !== 1) {

        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      history.push('/company/token/list')
      //form.setFieldsValue(resp.data.tokenPackageRc);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={t("add_token_package")}>
      <T9Button
        type="primary"
        onClick={() => history.push("/company/token/list")}
      >
        {t("back")}
      </T9Button>
      <Divider />
      <Spin spinning={loading}>
        <Form
          form={form}
          name="TokenPackageForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="token_package_id"
            hidden
          >
            <Input />
          </Form.Item>
          <Col span={6}>
            <Form.Item
              name="package_name"
              label={t("token_package_name")}
              rules={[{ required: true, message: t("name_required") }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Form.Item label={t("desc")}>
            <TinymceWrapper
              initialValue={TokenPackage.description}
              onChange={(value) => {
                setTokenPackage((prevState) => ({
                  ...prevState,
                  description: value
                }));
              }}
            />
          </Form.Item> */}

          <Col span={6}>
            <Form.Item
              name="token_value"
              label={t("token_amount")}
              rules={[{ required: true, message: t("name_required") }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="package_price"
              label={t("token_price")}
              rules={[{ required: true, message: t("name_required") }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="expiry_time"
              label={t("token_expiry_time_day")}
              rules={[{ required: true, message: t("name_required") }]}

            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* <Form.Item
              name="limit_per_user"
              label={t("limit_per_user")}
              rules={[{ required: true, message: t("name_required") }]}
              style={{ display: "inline-block", width: "calc(40% - 8px)" }}

            >
              <InputNumber min={0} style={{ width: "80%" }} />
            </Form.Item> */}
          <Row>
            <Col span={8}>
              <Form.Item
                label={t("effective_all_time")}
                name="effective_all_time"
                key="effective_all_time"
              >
                <Switch
                  checked={switchBool.effective_all_time}
                  onChange={(bool) => {
                    setSwitchBool({
                      ...switchBool,
                      effective_all_time: bool === true ? 1 : 0,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("effective_start_end")}
                name="effective_start_end"
                key="effective_start_end"
                rules={[
                  {
                    required: Boolean(!switchBool.effective_all_time),
                    message: t("please_select_date"),
                  },
                ]}
              >
                <RangePicker
                  placeholder={[t("start"), t("end")]}
                  disabled={switchBool.effective_all_time}
                  showTime={{ format: "HH:mm" }}
                  disabledDate={(current) => {
                    return dayjs().add(-1, "days") >= current;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </NavAndSideFrame>
  );
};

export default TokenPackageForm;
