import React, { useEffect, useState, useCallback } from "react";
import { Form, TreeSelect } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const CategoryTreeSelect = ({
  required = true,
  action,
  primary_id,
  parent_id,
  needRefresh = false,
  setNeedRefresh = () => null
}) => {
  const { t } = useTranslation();
  const [categoryCascaderList, setCategoryCascaderList] = useState([]);

  useEffect(() => {
    if (needRefresh) {
      getCategoryList();
    }
    setNeedRefresh(false);
  }, [needRefresh]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = useCallback(async () => {
    let resp = await Service.call("get", action); // get all category
    const tempCategory = _.map(resp.data, (item) => {
      const children = _.find(resp.data, {
        [parent_id]: item[primary_id],
      });
      return {
        ...item,
        key: item[primary_id],
        title: item.category_name,
        value: item[primary_id],
        disabled: !item.is_active,
        isLeaf: !item.is_active || _.isEmpty(children),
        children: [], // default - no children
      };
    });

    const categoryOptions = [];
    _.map(tempCategory, (category) => {
      if (!category[primary_id]) {
        return;
      }

      const parent = _.find(tempCategory, {
        [primary_id]: category[parent_id],
      });
      if (_.isUndefined(parent)) {
        categoryOptions.push(category);
        return;
      }

      if (_.isUndefined(parent.children)) {
        parent.children = [];
      }

      return parent.children.push(category);
    });

    setCategoryCascaderList(categoryOptions);
  }, []);

  return (
    <Form.Item
      label={t("category")}
      name="category_ids"
      rules={[{ required, message: t("input_required") }]}
    >
      <TreeSelect
        treeData={categoryCascaderList}
        showSearch
        placeholder={t("select")}
        treeDefaultExpandAll
        treeCheckable
      />
    </Form.Item>
  );
};

export default CategoryTreeSelect;
