import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Button,
  InputNumber,
  Switch,
  DatePicker,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import * as Service from "../../../core/Service";
import TimeSlotSection, { convertTimeStringToMinutes } from "../TimeSlotSection";


dayjs.extend(customParseFormat);

const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun", "holiday"];

const BookingItemRuleSetModal = (props) => {
  const { t } = useTranslation();
  const { selectedRecord, modalVisible, setModalVisible, handleFormSubmit } =
    props;
  const [ItemInfoForm] = Form.useForm();
  const [itemInfoFormData, setItemInfoFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [switchBool, setSwitchBool] = useState({
    effective_all_time: false,
  });
  const [timeSlotList, setTimeSlotList] = useState({});

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  useEffect(() => {
    ItemInfoForm.resetFields();
    if (
      selectedRecord.booking_item_timeslot_id !== 0 &&
      !_.isUndefined(selectedRecord.booking_item_timeslot_id)
    ) {
      const {
        section_duration,
        max_duration,
        min_duration,
        buffer_before,
        buffer_after,
      } = selectedRecord;

      setSwitchBool({
        effective_all_time: selectedRecord.effective_all_time === 1,
      });

      let newFormValues = {
        ...selectedRecord,
        section_duration: section_duration / 60,
        max_duration: max_duration / 60,
        min_duration: min_duration / 60,
        buffer_before: buffer_before / 60,
        buffer_after: buffer_after / 60,
        effective_start_end: [
          selectedRecord.effective_start ? moment.unix(selectedRecord.effective_start) : null,
          selectedRecord.effective_end ? moment.unix(selectedRecord.effective_end) : null,
        ],
      };
      ItemInfoForm.setFieldsValue({ ...newFormValues });
      setItemInfoFormData({ ...newFormValues });
    } else {
      setSwitchBool({ effective_all_time: true });
    }
  }, [selectedRecord]);

  const rulesetSubmit = async (id, bookingTimeSlot) => {
    setLoading(true);
    try {
      let method = "post";
      const postObj = {
        booking_item_timeslot_id: id,
        timeSlotList: bookingTimeSlot,
      };

      let resp = await Service.call(
        method,
        "/api/booking_item/timeslot/ruleset",
        postObj
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      // notification.success({
      //   message: t("success"),
      // });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getBookingConfigRuleset = async () => {
    try {
      setLoading(true);
      let url = `/api/booking_item/timeslot/ruleset?booking_item_timeslot_id=${selectedRecord.booking_item_timeslot_id}`;
      let resp = await Service.call("get", url);
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      let itemRulesetList = resp?.data?.itemRulesetList;
      const bookingSchedule = _.groupBy(itemRulesetList, (slot) => days.indexOf(slot.day));
      _.map(bookingSchedule, (timeSlot, key) => {
        bookingSchedule[key] = timeSlot.sort((a, b) => convertTimeStringToMinutes(a.start_time) - convertTimeStringToMinutes(b.start_time));
      });
      setTimeSlotList(bookingSchedule);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      let bookingTimeSlot = [];
      let timeSlotsAreComplete = true;
      let timeSlotsLongEnough = true;
      let timeSlotsFitSectionDuration = true;
      _.map(timeSlotList, (timeSlot, i) => {
        _.forEach(
          timeSlot,
          ({
            start_time,
            end_time,
            booking_item_timeslot_ruleset_id,
            section_price,
          }) => {
            if (!start_time || !end_time || typeof section_price !== "number") {
              timeSlotsAreComplete = false;
              return false;
            }

            let startTimeInMinutes = convertTimeStringToMinutes(start_time);
            let endTimeInMinutes = convertTimeStringToMinutes(end_time);
            let sectionLength = endTimeInMinutes - startTimeInMinutes;

            if (sectionLength < formData.min_duration) {
              timeSlotsLongEnough = false;
              return false;
            }

            if (sectionLength % formData.section_duration) {
              timeSlotsFitSectionDuration = false;
              return false;
            }

            bookingTimeSlot.push({
              day: days[i],
              start_time,
              end_time,
              section_price,
              booking_item_timeslot_ruleset_id,
            });
          }
        );
      });

      if (!timeSlotsAreComplete) {
        return notification.warning({
          message: t("warning"),
          description: t("time_slot_incomplete")
        });
      }

      if (!timeSlotsLongEnough) {
        return notification.warning({
          message: t("warning"),
          description: t("time_slot_too_short")
        });
      }

      if (!timeSlotsFitSectionDuration) {
        return notification.warning({
          message: t("warning"),
          description: t("time_slot_not_fit_section_duration")
        });
      }

      const {
        section_duration,
        max_duration,
        min_duration,
        buffer_before,
        buffer_after,
        effective_start_end,
      } = formData;

      let start_time = _.head(effective_start_end);
      let end_time = _.last(effective_start_end);
      await handleFormSubmit({
        ...selectedRecord,
        ...formData,
        section_duration: section_duration * 60,
        max_duration: max_duration * 60,
        min_duration: min_duration * 60,
        buffer_before: buffer_before * 60,
        buffer_after: buffer_after * 60,
        effective_all_time: switchBool.effective_all_time ? 1 : 0,
        effective_start: switchBool.effective_all_time
          ? 0
          : start_time.startOf("day").unix(),
        effective_end: switchBool.effective_all_time
          ? 0
          : end_time.endOf("day").unix(),
      });

      await rulesetSubmit(
        selectedRecord?.booking_item_timeslot_id,
        bookingTimeSlot
      );
      return getBookingConfigRuleset();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const durationValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      const section_duration = getFieldValue("section_duration");
      if (!value || !section_duration || (value % section_duration === 0 && value >= section_duration)) {
        return Promise.resolve();
      }
      return Promise.reject(t("must_be_multiple_of_section_duration"));
    },
  });

  const minDurationValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      const max_duration = getFieldValue("max_duration");
      if (!value || !max_duration || value <= max_duration) {
        return Promise.resolve();
      }
      return Promise.reject(t("min_duration_must_be_shorter"));
    }
  });

  const maxDurationValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      const min_duration = getFieldValue("min_duration");
      if (!value || !min_duration || value >= min_duration) {
        return Promise.resolve();
      }

      return Promise.reject(t("min_duration_must_be_shorter"));
    }
  });

  const prebookingValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      const book_after_day = getFieldValue("book_after_day");
      if (typeof value !== "number" || !book_after_day || value > book_after_day) {
        return Promise.resolve();
      }

      return Promise.reject(t("prebooking_must_be_longer"));
    }
  });

  const bookAfterDaysValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      const prebooking_type = getFieldValue("prebooking_type");
      if (typeof value !== "number" || !prebooking_type || value < prebooking_type) {
        return Promise.resolve();
      }

      return Promise.reject(t("prebooking_must_be_longer"));
    }
  });

  return (
    <Modal
      maskClosable={false}
      title={
        selectedRecord.booking_item_timeslot_id === 0
          ? t("add_item")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setTimeSlotList({});
        setModalVisible(false);
      }}
      destroyOnClose
    >
      <Form
        {...layout}
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        onValuesChange={(values) => setItemInfoFormData((previousValues) => ({ ...previousValues, ...values }))}
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("name")}
              name="name"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <Input maxLength={55} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("remark")}
              name="remark"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("priority")}
              name="priority"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item
              label={t("section_duration")}
              name="section_duration"
              rules={[
                { required: true, message: t("please_input_section_duration") },
                {
                  validator(rule, value) {
                    if (!value || value % 15 === 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("must_be_multiple_of_15"));
                  },
                },
              ]}
            >
              <InputNumber min={15} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("max_duration")}
              name="max_duration"
              rules={[
                { required: true, message: t("please_input_max_duration") },
                durationValidator,
                maxDurationValidator
              ]}
            >
              <InputNumber min={15} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("min_duration")}
              name="min_duration"
              rules={[
                { required: true, message: t("please_input_min_duration") },
                durationValidator,
                minDurationValidator
              ]}
            >
              <InputNumber min={15} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("capacity")}
              name="capacity"
              rules={[{ required: true, message: t("please_input_capacity") }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("buffer_before")} name="buffer_before">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("buffer_after")} name="buffer_after">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("book_after_day")}
              name="book_after_day"
              rules={[
                { required: true, message: t("please_input_book_after_day") },
                bookAfterDaysValidator
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("prebooking_type")}
              name="prebooking_type"
              rules={[
                { required: true, message: t("please_input_prebooking_days") },
                prebookingValidator
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("effective_all_time")}
              name="effective_all_time"
              key="effective_all_time"
            >
              <Switch
                checked={switchBool.effective_all_time}
                onChange={(bool) => {
                  setSwitchBool({
                    ...switchBool,
                    effective_all_time: bool === true ? 1 : 0,
                  });
                }}
              />
            </Form.Item>
          </Col>
          {
            !switchBool.effective_all_time && (
              <Col span={12}>
                <Form.Item
                  label={t("effective_start_end")}
                  name="effective_start_end"
                  key="effective_start_end"
                  rules={[
                    {
                      required: Boolean(!switchBool.effective_all_time),
                      message: t("please_select_date"),
                    },
                  ]}
                >
                  <RangePicker
                    placeholder={[t("start"), t("end")]}
                    disabled={switchBool.effective_all_time}
                    disabledDate={(current) => {
                      return dayjs().add(-1, "days") >= current;
                    }}
                  />
                </Form.Item>
              </Col>
            )
          }
        </Row>
        {selectedRecord.booking_item_timeslot_id > 0 && (
          <TimeSlotSection
            days={days}
            timeSlotList={timeSlotList}
            setTimeSlotList={setTimeSlotList}
            selectedRecord={selectedRecord}
            getBookingConfigRuleset={getBookingConfigRuleset}
            section_duration={itemInfoFormData.section_duration}
          />
        )}
        {/* <Divider /> */}
        <Row justify="end" style={{ marginTop: 24 }}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BookingItemRuleSetModal;
