import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Tabs,
  Modal,
  PageHeader,
  Row,
  Col,
  Pagination,
  Button,
  Drawer,
  Space,
  Form,
  Input,
  notification,
  message,
} from "antd";
import { CloseOutlined, InboxOutlined } from "@ant-design/icons";
import Image from "./ImageComponent";
import FormUploadFile from "./form/FormUploadFile";
import * as Service from "../core/Service";
import { openNotificationWithIcon, onFinishFailed } from "./form/ErrorMessage";

const PAGE_SIZE = 10;
const { TabPane } = Tabs;

const MediaLibraryComponent = (props) => {
  const config = useSelector((state) => state.app.config);
  const { t } = useTranslation();
  const {
    selectCallback,
    cancelCallback,
    mediaObj,
    showModal,
    type = "normal",
    fromEditor,
    showImage = true,
    company_id
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [mediaFileList, setMediaFileList] = useState([]);
  const [entireMediaFileList, setEntireMediaFileList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [selectConfirm, setSelectConfirm] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tab, setTab] = useState("media_library");

  useEffect(() => {
    getAllDefaultMedia();
    if (fromEditor && showModal) {
      setModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (mediaObj) {
      setSelectConfirm(true);
      setSelectedMedia(mediaObj);
    }
  }, [props]);

  useEffect(() => {
    let startIndex = (currentPage - 1) * PAGE_SIZE;
    let slicedArray = _.slice(
      entireMediaFileList,
      startIndex,
      PAGE_SIZE + startIndex
    );
    setMediaFileList(slicedArray);
  }, [currentPage]);

  const getAllDefaultMedia = async () => {
    let result = await Service.call("get", "/api/admin/media", {
      is_default: 1,
      company_id,
    });


    if (!_.isEmpty(result.status)) {
      return message.error(result.errorMessage);
    }
    result = _.filter(result, (value) => {
      return !value.filepath.includes("offline/");
    });
    setEntireMediaFileList(result);
    let slicedArray = _.slice(result, 0, PAGE_SIZE);
    setMediaFileList(slicedArray);
    return slicedArray;
  };
  const onChange = async (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      if (response.status > 0) {
        openNotificationWithIcon("success", t("common:upload_success"), "");
        let mediaList = await getAllDefaultMedia();
        setTab("media_library");
        onEdit(_.first(mediaList));
      } else {
        openNotificationWithIcon("warning", t("common:uploaded_fail"), "");
      }
    }
  };

  const onSelect = async (newSelectMedia) => {
    if (!selectedMedia.alt && !newSelectMedia) { return openNotificationWithIcon("error", t("common:alt_required")); }
    setDrawerVisible(false);
    setModalVisible(false);
    setSelectConfirm(true);
    selectCallback(newSelectMedia || selectedMedia);
  };

  const onEdit = (media) => {
    setSelectedMedia(media);
    setDrawerVisible(true);
  };

  const editCallback = async () => {
    getAllDefaultMedia();
  };

  const resetChosen = async () => {
    setDrawerVisible(false);
    // setSelectConfirm(false);
    if (mediaObj) setSelectedMedia(mediaObj);
  };

  const MediaEditDrawer = (mediaProps) => {
    const {
      drawerVisible,
      callback,
      editCallback,
      mediaObj: mediaInfo,
      t,
      type
    } = mediaProps;
    const [form] = Form.useForm();
    let imageUrl = `${config.STATIC_SERVER_URL}/${mediaInfo.filepath}`;

    useEffect(() => {
      if (!_.isEmpty(mediaInfo)) {
        form.setFieldsValue({
          alt: mediaInfo.alt,
        });
      }
    }, [mediaInfo]);

    const onSubmitEditForm = async () => {
      let editObj = form.getFieldsValue();
      let updateResult = await Service.call("patch", "/api/admin/media",
        {
          ...editObj,
          media_upload_id: mediaInfo.media_upload_id,
          originalname: mediaInfo.originalname,
          // status: mediaObj.status
        });
      // console.log('update.status>>>', updateResult)
      if (updateResult.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(updateResult.errorCode),
        });
      }
      notification.success({
        message: t("common:edit_success"),
      });
      setSelectedMedia({ ...selectedMedia, alt: editObj.alt });
      if (type === "normal") onSelect({ ...selectedMedia, alt: editObj.alt });
      editCallback();
    };

    return (
      <Drawer
        title={(
          <Row gutter={[24, 0]} align="middle">
            <Col span={6}>{t("media_record")}</Col>
            <Col span={18} style={{ textAlign: "right" }}>
              <Space>
                {type !== "mediaList" && (
                  <Button onClick={() => onSelect(mediaInfo)} type="primary">
                    {t("select")}
                  </Button>
                )}
                <Button onClick={() => callback()}>
                  {t("cancel")}
                </Button>
                <Button onClick={() => form.submit()} type="dashed">
                  {t("edit")}
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        closable={false}
        width={700}
        visible={drawerVisible}
        key="right"
        bodyStyle={{ padding: 0, paddingBottom: 50 }}
        drawerStyle={{ padding: 0 }}
        onClose={() => callback()}
        destroyOnClose
      >
        <div className="drawer-container" style={{ textAlign: "center" }}>
          <img
            src={imageUrl}
            style={{
              width: "50%",
              backgroundSize: "contain",
            }}
            alt={mediaInfo.alt}
          />
          <Form
            form={form}
            {...formItemLayout}
            layout="vertical"
            onFinish={() => onSubmitEditForm()}
          >
            <Form.Item
              name="alt"
              label={t("alt")}
              rules={[
                {
                  required: true,
                  message: `${t("common:alt")} ${t("common:is_required")}`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    );
  };

  // const FileUploadList = (({ mediaFileList }) => {
  //   console.log(mediaFileList)
  //   const renderList = mediaFileList.map((element, index) =>
  //     <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
  //       {element.filename}
  //     </li>
  //   )


  //   return (
  //     <>
  //       {(mediaFileList.length > 0) && (<h3>Uploaded Files</h3>)}
  //       <ul className="list-groups p-0">
  //         {renderList}
  //       </ul>
  //     </>
  //   )
  // })

  return (
    <>
      {!fromEditor && (
        <Row>
          <Col
            span={12}
            className="upload-box"
            onClick={() => setModalVisible(true)}
          >
            {selectConfirm && !_.isEmpty(selectedMedia.filepath) && showImage ? (
              <>
                <Image
                  src={`${config.STATIC_SERVER_URL}/${selectedMedia.filepath}`}
                  alt={selectedMedia.alt}
                  width={160}
                  height={90}
                />
              </>
            ) : (
              <>
                <div>
                  <Button
                    type="dashed"
                    icon={<InboxOutlined />}
                    style={{ width: 500, height: 200 }}
                  >
                    {t("upload_msg1")}

                  </Button>
                </div>
              </>
            )}
          </Col>
          {!_.isEmpty(selectedMedia.filepath) && (
            <a>
              <Col
                style={{
                  alignSelf: "center",
                }}
                onClick={() => {
                  setSelectedMedia({
                    filename: "",
                    filepath: ""
                  });
                  selectCallback({
                    filename: "",
                    filepath: ""
                  });
                }}
              >
                <CloseOutlined />
              </Col>
            </a>
          )}
        </Row>
      )}
      <Modal
        visible={modalVisible}
        width="90%"
        closable
        bodyStyle={{ padding: 16, paddingTop: 10, paddingBottom: 30, }}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
          if (cancelCallback) cancelCallback();
        }}
        destroyOnClose
        title={(
          <PageHeader
            backIcon={false}
            style={{ padding: 0, borderBottom: "0 none" }}
            title={t("media_library")}
          />
        )}
      >
        <Tabs
          type="line"
          defaultActiveKey={tab}
          activeKey={tab}
          onChange={setTab}
        >
          <TabPane tab={t("upload_image")} key="upload_image">
            <FormUploadFile
              key="1"
              type="button"
              action="/api/admin/media"
              data={{ scope: "public", type: "button", company_id }}
              onChange={(info) => onChange(info)}
            />
            {/* <FileUploadList mediaFileList={mediaFileList} /> */}
          </TabPane>

          <TabPane tab={t("media_library")} key="media_library">
            <div className="modal-container">
              {!_.isEmpty(mediaFileList) && (
                <>
                  <Row gutter={[24, 24]}>
                    {_.map(mediaFileList, (val) => {
                      let imageUrl = `${config.STATIC_SERVER_URL}/${val.filepath}`;
                      return (
                        <>
                          <Col span={1} />
                          <Col span={5}>
                            <div
                              style={{ cursor: "pointer" }}
                              className={
                                selectedMedia.media_upload_id
                                  === val.media_upload_id
                                  ? "selected-image"
                                  : null
                              }
                              onClick={() => onEdit(val)}
                            >
                              <Image
                                alt={val.filepath}
                                src={imageUrl}
                                width={160}
                                height={90}
                              />
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                  <Pagination
                    style={{ marginTop: 20 }}
                    current={currentPage}
                    total={entireMediaFileList.length}
                    onChange={(page) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                    showSizeChanger
                  />
                </>
              )}
            </div>
          </TabPane>
        </Tabs>
      </Modal>
      <MediaEditDrawer
        t={t}
        drawerVisible={drawerVisible}
        callback={() => {
          resetChosen();
        }}
        editCallback={editCallback}
        mediaObj={selectedMedia}
        type={type}
      />
    </>
  );
};

const styles = {
  formSection: {
    width: "95%",
    maxWidth: "95%",
    margin: "auto",
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    // sm: { span: 8 },
  },
  // wrapperCol: {
  //   xs: { span: 24 },
  //   sm: { span: 16 },
  // },
};

export default MediaLibraryComponent;
