import {
  Spin,
  Tabs,
  Button,
  Badge,
  Table,
  Modal,
  notification,
  Form,
  Input,
  Select,
  Tag
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CompanyConfigForm from "../../components/CompanyConfigForm";
import { openNotificationWithIcon } from "../../components/form/ErrorMessage";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";
import StaticPageEditForm from "../../components/form/StaticPageEditForm";

const { TabPane } = Tabs;

const SiteMeta = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const selectedKey = "company_site_meta";
  const openKeys = "website_management";
  return (
    <NavAndSideFrame title={t("company_site_meta")} selectedKey={selectedKey} openKeys={openKeys}>
      <Spin spinning={loading}>
        <Tabs type="card">
          {/* <TabPane tab={t('logo')} key="logo">
          <TabComponent metaKey="site_logo" pathname="/company/site_logo_form" />
        </TabPane> */}
          <TabPane tab={t("company_info")} key="company">
            <TabComponent metaKey="company" />
          </TabPane>

          <TabPane tab={t("social_media")} key="media">
            <TabComponent metaKey="media" />
          </TabPane>

          <TabPane tab={t("about_us")} key="about_us">
            <TabComponent metaName="about_us" t={t} metaKey="about_us" />
          </TabPane>

          <TabPane tab={t("terms_and_conditions")} key="tc">
            <TabComponent metaName="tc" t={t} metaKey="tc" />
          </TabPane>

          <TabPane tab={t("blog_post")} key="blog_post">
            <TabComponent metaName="blog_post" t={t} metaKey="blog_post" />
          </TabPane>

          <TabPane tab={t("news")} key="news">
            <TabComponent metaName="news" t={t} metaKey="news" />
          </TabPane>

          <TabPane tab={t("token")} key="token">
            <TabComponent metaName="token" t={t} metaKey="token" />
          </TabPane>

          <TabPane tab={t("contact_us")} key="contact_us">
            <TabComponent metaName="contact_us" t={t} metaKey="contact_us" />
          </TabPane>

        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

const TabComponent = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    metaKey,
  } = props;

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Service.call("get", `/api/company/meta_by_key?meta_key=${metaKey}`);
    if (!_.isEmpty(result.data)) setShowAddButton(false);
    else setShowAddButton(true);
    setDataSource(result.data);
    setLoading(false);
  };

  const getColumns = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "company_site_meta_id",
        key: "company_site_meta_id",
        render: (value, record, index) => {
          let { company_site_meta_id, meta_key } = record;

          return (
            <span>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                style={{ color: "#00AA00" }}
                onClick={() => {
                  switch (meta_key) {
                    case "site_logo": //  Media
                      history.push(`${props.pathname}?meta_key=${metaKey}&id=${company_site_meta_id}`);
                      break;

                    default: //  Input Form
                      setRecordId(value);
                      setSelectedFormData(record);
                      setModalVisible(true);
                      break;
                  }
                }}
              />
              {/* <Button shape="circle" icon={<DeleteOutlined />} style={{color: '#AA0000' }} onClick={() => onDelete(site_meta_id, record)} /> */}
            </span>
          );
        }
      },
      {
        title: t("key"),
        dataIndex: "meta_key",
        key: "meta_key",
      },
      {
        title: t("value"),
        dataIndex: "meta_value",
        key: "meta_value",
        render: (value, record) => {
          if (record.meta_key.includes("enable")) {
            const displayStr = _.toInteger(value) ? "enable" : "disable";
            const color = _.toInteger(value) ? "blue" : "red";
            return <Tag color={color}>{t(displayStr)}</Tag>;
          }
          return value;
        }
      },
    ];

    return columns;
  };


  const confirmDelete = async (site_meta_id) => {
    let result = await Service.call("delete", "/api/admin/meta", { site_meta_id });

    if (!result || result.status <= 0) {
      notification.error({
        message: "刪除失敗，請稍後再試",
        description: t(result.errorCode),
      });
      return;
    }

    notification.success({
      message: "成功刪除記綠",
      description: "",
    });

    fetchData();
  };

  return (
    <Spin spinning={loading}>
      {showAddButton && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            switch (metaKey) {
              // case 'company_info_name_en':
              // case 'company_info_name_zh_hant':
              // case 'company_info_address_en':
              // case 'company_info_address_zh_hant':
              // break;

              case "site_logo":
                history.push(`${props.pathname}?meta_key=${metaKey}`);
                break;

              default:
                notification.info({ message: t("not_supported") });
                break;
            }
          }}
          style={{ marginBottom: 10 }}
        >
          {t("create")}
        </Button>
      )}
      <Table rowKey={() => "site_meta"} scroll={{ x: true }} dataSource={dataSource} columns={getColumns()} />

      <Modal
        title={recordId > 0 ? t("edit") : t("create")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <StaticPageEditForm
          selectedFormData={selectedFormData}
          recordId={recordId}
          closeMethod={async () => {
            fetchData();
            setModalVisible(false);
          }}
          type="company"
        />
      </Modal>
    </Spin>
  );
};


export default SiteMeta;
