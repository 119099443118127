import React, { Component, useState } from "react";
import {
  LockOutlined, MailOutlined, KeyOutlined, UserOutlined
} from "@ant-design/icons";
import {
  Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setAuth, setCompany, setCompanyAdmin } from "../../redux/actions/common";
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from "../../core/Service";
import logo from "../../assets/ninesmart_logo.png";


const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector((state) => state.app.config);
  const auth = useSelector((state) => state.app.auth);

  const onFinish = async (formData) => {
    try {
      let data = await Service.call("post", `/api/login/company_admin`, formData);
      if (data.status === 1) {
        let allPermission = [];
        let resp = await Service.call("get", "/api/config");
        if (resp && resp.status > 0) {
          allPermission = resp.allPermission;
        } else {
          throw new Error("failed to get app config");
        }
        message.success(`${t("login_success")}`);
        dispatch(setCompanyAdmin(data.userData));
        dispatch(setAuth(true));
        dispatch(setCompany(data.companyData));
        // Set Permission By CSS
        let myPermission = data.permissionList;
        if (!_.includes(myPermission, "p_any")) {
          const missingPermission = _.difference(allPermission, myPermission);
          const el = document.getElementsByTagName("body")[0];
          _.each(missingPermission, (perm) => {
            el.classList.add(`${perm}_none`);
          });
        }
        history.push("/company/home");
      } else {
        message.error(`${t("login_fail")}`);
      }
    } catch (error) {
      message.error(`${t("login_fail")}`);
    }
  };

  const displayLogin = () => {
    return (
      <div className="" style={{display: "flex", justifyContent: "center", marginBottom: "150px"}}>
        <Form
          className="login-form col-12 mt-2"
          style={{width: "100%"}}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="company_key"
            rules={[{ required: true, message: "Please input company key." }]}
          >
            <Input
              prefix={<KeyOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={t("companyKey")}
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t("username")}
            />
          </Form.Item>
          {/* <Form.Item
            name="mobile"
            rules={[{ required: true, message: 'Please input mobile.' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Mobile"
            />
          </Form.Item> */}

          <Form.Item
            name="password"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("password")}
            />
          </Form.Item>

          <Button
            style={{width: "100%"}}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
          {/* <p className="forgot-password text-right">
              Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
          </p> */}
        </Form>
      </div>
    );
  };

  return (
    <Layout>
      <Layout.Content>
        <div style={{height: "10px", background: "linear-gradient(to right, #000046, #1cb5e0)"}} />
        <Row type="flex" justify="center" align="middle" style={{padding: "50px", minHeight: "100vh", background: "white"}}>
          <Col xs={24} sm={16} md={14} lg={10} xl={8} style={{alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center", marginBottom: "50px"}}>
              <div>
                <img alt="" src={logo} style={{width: "100%", maxWidth: "300px", marginBottom: "30px"}} />
              </div>
              <h2>{t("vms")}</h2>
              <h3>{t("company_admin_login")}</h3>
            </div>
            {
              displayLogin()
            }
          </Col>
        </Row>
        {/* <Row type="flex" justify="center">
          <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
            <a href={`${config.STATIC_SERVER_URL}/admin/login`}>Technine admin login</a>
          </Col>
        </Row> */}
      </Layout.Content>
    </Layout>
  );
};

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);
