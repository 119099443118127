import {
  Layout, Table, Button, Tooltip, Tag
} from "antd";
import {
  EditOutlined,
} from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const DisplayListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "display_id";

  const {
    setModalVisible, setSelectedRecord, loading, dataObj, API_ORIGIN
  } = props;

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value)
      },
      {
        title: t("name"),
        dataIndex: "display_name"
      },
      {
        title: t("booking_item"),
        dataIndex: "booking_item",
        render: (value) => {
          return (_.map(value, (i, index) => (<Tag key={index} color="blue">{i.item_name}</Tag>)));
        }
      },
      {
        title: t("background_image"),
        dataIndex: "background_image",
        render: (value) => {
          return (
            <div>
              <img src={`${API_ORIGIN}${value}`} alt={value} style={{ maxWidth: 300, maxHeight: 100 }} />
            </div>
          );
        }
      },
      {
        title: t("text_color"),
        dataIndex: "text_color",
        render: (value) => {
          return (
            <div>
              <p style={{ color: value }}>{value}</p>
            </div>
          );
        }
      },
    ];
    return columns;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataObj}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={25}
        loading={loading}
        locale={{ triggerDesc: t('sort_descend'), triggerAsc: t('sort_ascend'), cancelSort: t('cancel_sort') }}
      />
    </Layout>
  );
};

export default DisplayListTable;
