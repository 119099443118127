import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Input,
  Modal,
  Spin,
  Upload,
  message,
  Divider,
  Select,
  notification,
} from "antd";
import Papa from "papaparse";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import _ from "lodash";
import * as Service from "../../core/Service";


const { Dragger } = Upload;
const { TextArea } = Input;


const UpdateEventUserCsvFile = (props) => {
  const {
    event_key
  } = props;
  const { t } = useTranslation();
  const [importVisible, setImportVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvValidation, setCsvValidation] = useState(false);
  const [eventRegistrationFormFieldList, setEventRegistrationFormFieldList] = useState([]);
  const [eventData, setEventData] = useState({});
  const [eventRegistrationType, setEventRegistrationType] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [errorLog, setErrorLog] = useState([]);
  const [optionsEventDateList, setOptionsEventDateList] = useState([]);
  const [selectedEventDate, setSelectedEventDate] = useState(0);
  const MAX_LIMIT_ROW = 100;

  useEffect(() => {
    if (importVisible === true) {
      getEventData();
    }
  }, [importVisible]);

  const cleanUp = () => {
    setImportVisible(false);
    setLoading(false);
    setCsvValidation(false);
    setCsvData([]); // remember to reset csv data
    setErrorLog([]);
    setEventRegistrationFormFieldList([]);
    setOptionsEventDateList([]);
    setSelectedEventDate(0);
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    let displayTime = "";
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = "-";
    }
    return displayTime;
  };

  const getEventData = async () => {
    setLoading(true);
    try {
      const resp = await Service.call("get", `/api/event/${event_key}`);

      if (resp.status === 1) {
        let formFieldsJsonString = resp.data.eventData.eventMeta.event_registration_form_fields;
        let registrationType = resp.data.eventData.eventMeta.registration_type;
        if (formFieldsJsonString !== undefined) {
          let fieldList = JSON.parse(formFieldsJsonString);
          setEventRegistrationFormFieldList(fieldList);
        }
        if (registrationType !== undefined) {
          setEventRegistrationType(registrationType);
        }
        setEventData(resp.data.eventData);
        let optionsList = _.map(resp.data.eventData.eventSection, (eachElement) => {
          let option = {};
          option.value = eachElement.event_date_id; // number
          option.key = eachElement.event_date_id.toString();
          option.label = "(id: " + eachElement.event_date_id.toString() + ") "
            + displayMoment(eachElement.event_start_date)
            + " - " + displayMoment(eachElement.event_end_date);

          return option;
        });
        setOptionsEventDateList(optionsList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // return string message
  const validation = (valueArr) => {
    try {
      // Step 1) check email first
      // custom email checking because joi library has bug on email checking
      let errorEmailIndex = _.findIndex(valueArr, (eachObj) => {
        return !isValidEmail(eachObj.email);
      });
      if (errorEmailIndex !== -1) {
        return "email is not correct in Row Index: " + errorEmailIndex.toString() + " with email: \"" + valueArr[errorEmailIndex].email + "\"";
      }

      // Step 2) validate other fields
      // validate data
      const resultObj = getJoiScheme().validate(valueArr, {abortEarly: false});
      if ("error" in resultObj) {
        let errorArr = resultObj.error.details.map((item) => {
          return `Row ${item.path[0] + 1} Column "${item.path[1]}" ${item.message.split("\" ").slice(1).reduce((previousValue, currentValue) => { return previousValue + " " + currentValue; })}`;
        });
        let errorString = errorArr.reduce((previousValue, currentValue) => {
          return previousValue + "\n" + currentValue;
        });
        return errorString;
      }
      return "";
    } catch (error) {
      console.error(error);
    }
  };

  const getJoiScheme = () => {
    let defaultObj = {
      email: Joi.string()
        .min(1)
        .max(100)
        .empty(""), // allow empty string
      mobile: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      first_name: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      last_name: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      nickname: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      company_name: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      position: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      referral_code: Joi.string()
        .max(100)
        .empty(""),
      contact_no: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      salutation: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      reference_id: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      university_name: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      programme_name: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      guest_number: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
      seat_number: Joi.string()
        .min(1)
        .max(100)
        .empty(""),
    };
    _.map(eventRegistrationFormFieldList, (eachElement) => {
      // e.g. email, first_name, ...
      let fieldKey = eachElement.field_type;

      // check "mandatory"
      if (eachElement.mandatory === true && fieldKey in defaultObj) {
        defaultObj[fieldKey] = Joi.string() // ONLY support "string" first for easier validation
          .max(100)
          .empty("")
          .required();
      }
    });
    // * NOTE: for debugging,
    // rules can be found in Console the object ('$_terms' -> items[0] -> '$_terms' -> keys)
    const dynamicSchema = Joi.array().items(Joi.object(defaultObj));
    return dynamicSchema;
  };

  const customRequest = async (options) => {
    const { onError, onSuccess, file } = options;
    try {
      const isCSV = file.type === "text/csv";
      if (!isCSV) throw new Error(`${file.name} is not a csv file`);

      const reader = new FileReader();
      // Read File as Text
      reader.readAsText(file);

      reader.onload = async function () {
        // Parse CSV string to object
        const parseResult = Papa.parse(
          reader.result,
          {
            header: true, // set true to convert as Array of objects (header columns become keys)
            skipEmptyLines: true,
          }
        );
        console.log("uploaded csv (converted): ", parseResult);
        if (parseResult.data.length > MAX_LIMIT_ROW) {
          setErrorLog("csv row number is " + parseResult.data.length.toString() + " which excess max: " + MAX_LIMIT_ROW.toString());
          return;
        }

        // parseResult.data is array of json object
        // validationResult is string message
        const validationResult = validation(parseResult.data);

        // check if validationResult is empty string or not
        if (validationResult) {
          setErrorLog(validationResult);
          setCsvValidation(false);
          onError("Fail");
          setCsvData([]);
          message.error(`Please check error log!`);
        } else {
          setErrorLog("");
          setCsvValidation(true);
          onSuccess("OK");
          setCsvData(parseResult.data);
          message.success(`${file.name}, ${parseResult.data.length} row loaded success.`);
        }
      };

      reader.onerror = function() {
        console.error(reader.error);
      };
    } catch (err) {
      onError(err);
      setErrorLog(err);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    cleanUp();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const url = "/api/event/update_event_user";
      const method = "put";
      const requestObj = {
        event_id: eventData.eventInfo.event_id,
        event_date_id: selectedEventDate,
        event_user_update_list: csvData,
      };
      const resp = await Service.call(method, url, requestObj);
      if (resp.status !== 1) {
        notification.error({
          message: t("error"),
          description: resp.errorMessage,
        });
        setErrorLog(resp.errorMessage);
        return;
      }
      notification.success({
        message: t("Success"),
      });
    } catch (err) {
      setErrorLog(err);
      notification.error({
        message: t("error"),
        description: err,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEventDate = (value) => {
    setSelectedEventDate(value);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ marginLeft: 8 }}
        onClick={() => setImportVisible(true)}
      >
        Update Event User CSV
      </Button>
      <Modal
        title="Update Event User CSV"
        visible={importVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="100%"
        maskClosable={false} // NOTE: block user to click outside, wait for the csv successfully updated
        onCancel={onCancel}
      >
        <Spin spinning={loading}>
          <>
            <p>Event Date</p>
            <Select
              style={{ width: "100%" }}
              options={optionsEventDateList}
              onChange={handleChangeEventDate}
            />
            <Divider />
            <p>File</p>
            <Dragger
              name="file"
              multiple={false}
              showUploadList={false}
              customRequest={customRequest}
              onDrop={(e) => {
                console.log("Dropped files", e.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Only support single file upload.</p>
            </Dragger>
            <Divider />
            <p>Error Log</p>
            <TextArea
              title="Error Log"
              rows={5}
              value={errorLog}
              style={{color: "red"}}
              disabled
            />
            <Divider />
            <div
              style={{textAlign: "right"}}
            >
              <Button
                type="primary"
                disabled={!csvValidation}
                style={{ marginLeft: 8 }}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </>
        </Spin>
      </Modal>
    </>
  );
};
export default UpdateEventUserCsvFile;
