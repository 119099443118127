import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  notification,
  Table,
  Popconfirm,
  Button,
  Switch,
  Select,
  Spin,
} from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import * as Service from "../../../core/Service";

import MediaLibraryComponent from "../../../components/MediaLibraryComponent";
import CategoryTreeSelect from "../../../components/CategoryTreeSelect";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BookingItemRuleSetModal from "../BookingItemRuleSet/BookingItemRuleSetModal";
import CategoryInfoModal from "../ItemGroup/CategoryInfoModal";
import ProductItemModal from "../ProductItem/ProductItemModal";

const { Option } = Select;
const selectedKey = "booking_items_list";
const openKeys = "booking_management";

const ItemInfoForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const config = useSelector((state) => state.app.config);
  const [ItemInfo] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [needRefresh, setNeedRefresh] = useState(false);
  const [defaultImg, setDefaultImg] = useState(null);
  let sorting = useRef({});
  const [fileinfo, setFileinfo] = useState([]);
  const [groupByIdLinkage, setGroupByIdLinkage] = useState({});
  const [itemDesc, setItemDesc] = useState(null);
  const [sortState, setSortState] = useState({});
  const [selectedObj, setSelectedObj] = useState({
    selectedProduct: [],
    selectedTimeslot: [],
    selectedRulesetGroup: [],
    selectedLinkage: [],
  });

  const [rulesetModalVisible, setRulesetModalVisible] = useState(false);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [productItemModalVisible, setProductItemModalVisible] = useState(false);

  const [dataList, setDataList] = useState({
    bookingItemArr: [],
    bookingProductArr: [],
    bookingItemTimeSlotArr: [],
    bookingItemLinkageArr: [],
  });

  const { selectedRecord } = location.state;
  const [itemRecord, setItemRecord] = useState(selectedRecord);

  useEffect(() => {
    setItemDesc("");
    ItemInfo.resetFields();
    setSelectedObj({
      selectedProduct: [],
      selectedTimeslot: [],
      selectedRulesetGroup: [],
      selectedLinkage: [],
    });
    getBookingItemSelectionData();
    if (
      itemRecord.booking_item_id !== 0
      && !_.isUndefined(itemRecord.booking_item_id)
    ) {
      getBookingItemDetail(itemRecord);
      setItemDesc(itemRecord.item_desc);
    }
  }, [itemRecord]);

  const getBookingItemSelectionData = async () => {
    try {
      setLoading(true);
      let url = "/api/booking_item/data";
      let resp = await Service.call("get", url);

      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setDataList({
        bookingItemArr: resp.data.bookingItemList,
        bookingProductArr: resp.data.allProduct,
        bookingItemTimeSlotArr: resp.data.bookingItemTimeSlotList,
        bookingItemLinkageArr: resp.data.bookingItemLinkageList,
      });
      setGroupByIdLinkage(
        _.groupBy(resp.data.bookingItemLinkageList, "father_item_id")
      );
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const getBookingItemDetail = async () => {
    setLoading(true);
    try {
      let resp = await Service.call(
        "get",
        `/api/booking_item/detail/${itemRecord.booking_item_id}`
      );
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: resp.errorMessage,
        });
      }
      let {
        itemImageArr,
        bookingCategoryRefList,
        linkageList,
        linkedProductRef,
        linkedItemRulesetRefArr,
      } = resp.data;
      const groupById_LinkageList = _.groupBy(linkageList, "father_item_id");
      setGroupByIdLinkage(groupById_LinkageList);
      ItemInfo.setFieldsValue({
        ...itemRecord,
        category_ids: _.map(bookingCategoryRefList, "booking_category_id"),
      });
      setSelectedObj({
        selectedProduct: _.map(linkedProductRef, "product_item_id"),
        selectedTimeslot: _.map(
          linkedItemRulesetRefArr,
          "booking_item_timeslot_id"
        ),
        selectedLinkage: _.map(
          groupById_LinkageList[itemRecord.booking_item_id],
          "son_item_id"
        ),
      });
      setFileinfo(itemImageArr);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getItemImage = async () => {
    setLoading(true);
    try {
      let resp = await Service.call(
        "get",
        `/api/booking_item/image/${itemRecord.booking_item_id}`
      );
      setFileinfo(resp.data);
      let [defaultImage] = _.filter(resp.data, { is_default: 1 });
      if (defaultImage) {
        setDefaultImg(defaultImage.booking_item_image_id);
      }

      let sort = {};
      _.each(resp.data, (eachImage) => {
        sort[eachImage.booking_item_image_id] = eachImage.sorting;
      });
      sorting.current = sort;
      setSortState(sorting.current);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySubmit = async (submitRecord) => {
    try {
      let resp = await Service.call("put", "/api/booking_category", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
      });
      setCategoryModalVisible(false);
      setNeedRefresh(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductItemSubmit = async (submitRecord) => {
    try {
      let resp = await Service.call("put", "/api/product_item", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
      });
      setProductItemModalVisible(false);
      getBookingItemSelectionData();
    } catch (error) {
      console.error(error);
    }
  };
  const itemImageColumns = [
    {
      title: t("image"),
      render: (value, record) => {
        let path = `${config.STATIC_SERVER_URL}/${record.filepath}`;
        return <img src={path} width="100px" height="100px" alt="item" />;
      },
    },
    {
      title: t("is_default"),
      dataIndex: "is_default",
      render: (value, record) => (
        <Switch
          checked={defaultImg === record.booking_item_image_id}
          onChange={(event) => {
            if (event) {
              setDefaultImg(record.booking_item_image_id);
            } else {
              setDefaultImg(null);
            }
          }}
        />
      ),
    },
    {
      title: t("sorting"),
      dataIndex: "sorting",
      render: (value, record) => (
        <Input
          value={sortState[record.booking_item_image_id]}
          onChange={(e) => {
            setSortState({
              ...sortState,
              [record.booking_item_image_id]: _.toInteger(e.target.value),
            });
          }}
        />
      ),
    },
    {
      title: t("delete"),
      dataIndex: "operation",
      render: (value, record) => (
        <Popconfirm
          title="confirm_delete"
          onConfirm={() => onDeleteImage(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ color: "#AA0000" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const onDeleteImage = async (record) => {
    setLoading(true);
    try {
      await Service.call("post", "/api/booking_item_image", record);
      await getItemImage();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      let dataObj = {
        ...formData,
        short_desc: "",
        short_desc_2: "",
        booking_item_id: itemRecord.booking_item_id || 0,
        booking_category_id_list: formData.category_ids,
        is_default: defaultImg,
        image_sorting: sortState,
        item_desc: itemDesc || "",
        booking_item_linkage_id_list: selectedObj.selectedLinkage,
        booking_product_id_list: selectedObj.selectedProduct,
        booking_item_timeslot_id_list: selectedObj.selectedTimeslot,
      };
      let { booking_item_timeslot_id_list } = dataObj;
      if (_.isEmpty(booking_item_timeslot_id_list)) {
        return notification.error({
          message: t("error"),
          description: t("booking_item_ruleset_group_id_list_not_empty"),
        });
      }
      let method = dataObj.booking_item_id === 0 ? "put" : "patch";
      let resp = await Service.call(method, "/api/booking_item", dataObj);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      setItemRecord(resp.data.bookingItemObj);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      title={
        itemRecord.booking_item_id === 0
          ? t("create_booking_item")
          : t("edit_booking_item")
      }
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Form
          form={ItemInfo}
          name="ItemInfo"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[20, 20]}>
            <Col xs={8} sm={8} md={9} lg={10}>
              <Form.Item
                label={t("name")}
                name="item_name"
                rules={[{ required: true, message: t("input_required") }]}
              >
                <Input maxLength={55} />
              </Form.Item>
            </Col>
            <Col xs={8} sm={8} md={9} lg={10}>
              <CategoryTreeSelect
                edit={!_.isEmpty(itemRecord)}
                id={itemRecord.booking_category_id}
                action="/api/booking_category/list"
                primary_id="booking_category_id"
                parent_id="parent_booking_category_id"
                needRefresh={needRefresh}
                setNeedRefresh={setNeedRefresh}
              />

              {/* <Col
                xs={4}
                sm={4}
                md={3}
                lg={2}
                style={{
                  alignSelf: "center",
                  marginTop: "5px",
                  textAlign: "center",
                }} */}
            </Col>
            <Col xs={4} sm={4} md={3} lg={2} style={{ alignSelf: "center", marginTop: "5px", textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => setCategoryModalVisible(true)}
              >
                {t("add")}
              </Button>
            </Col>
            <Col xs={8} sm={8} md={9} lg={10}>
              <Form.Item label={t("linkage")}>
                <Select
                  allowClear
                  mode="multiple"
                  value={selectedObj.selectedLinkage}
                  onChange={(value) => {
                    setSelectedObj((prevState) => ({
                      ...prevState,
                      selectedLinkage: value,
                    }));
                  }}
                >
                  {_.map(dataList.bookingItemArr, (eachData) => {
                    return (
                      // Disable case: self / already is father
                      <Option
                        disabled={
                          itemRecord.booking_item_id
                          === eachData.booking_item_id
                          || !_.isEmpty(groupByIdLinkage[eachData.booking_item_id])
                        }
                        value={eachData.booking_item_id}
                      >
                        {eachData.item_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

            </Col>
            <Col xs={8} sm={8} md={9} lg={10}>
              <Form.Item label={t("product_item")}>
                <Select
                  allowClear
                  mode="multiple"
                  value={selectedObj.selectedProduct}
                  onChange={(value) => {
                    setSelectedObj((prevState) => ({
                      ...prevState,
                      selectedProduct: value,
                    }));
                  }}
                >
                  {_.map(dataList.bookingProductArr, (eachData) => {
                    return (
                      <Option value={eachData.product_item_id}>
                        {eachData.product_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={4} sm={4} md={3} lg={2} style={{ alignSelf: "center", marginTop: "5px", textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => setProductItemModalVisible(true)}
              >
                {t("add")}
              </Button>
            </Col>
            <Col xs={8} sm={8} md={9} lg={10}>
              {/* for new timeslot ruleset */}
              <Form.Item label={t("ruleset_group")}>
                <Select
                  allowClear
                  mode="multiple"
                  value={selectedObj.selectedTimeslot}
                  onChange={(value) => {
                    setSelectedObj((prevState) => ({
                      ...prevState,
                      selectedTimeslot: value,
                    }));
                  }}
                  optionLabelProp="label"
                >
                  {_.map(dataList.bookingItemTimeSlotArr, (eachData) => {
                    return (
                      <Option
                        key={eachData.booking_item_timeslot_id}
                        value={eachData.booking_item_timeslot_id}
                        label={eachData.name}
                      >
                        {eachData.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={3}
              lg={2}
              style={{
                alignSelf: "center",
                marginTop: "5px",
                textAlign: "center",
              }}
            >
              <Button
                type="primary"
                onClick={() => setRulesetModalVisible(true)}
              >
                {t("add")}
              </Button>
            </Col>
          </Row>
          {itemRecord.booking_item_id !== 0 && (
            <>
              <Form.Item label={t("banner")}>
                <MediaLibraryComponent
                  showImage={false}
                  selectCallback={async (mediaObj) => {
                    try {
                      setLoading(true);
                      await Service.call("put", "/api/booking_item_image", {
                        ...mediaObj,
                        booking_item_id: itemRecord.booking_item_id,
                      });
                      await getItemImage();
                    } catch (error) {
                      console.error(error);
                    } finally {
                      setLoading(false);
                    }
                  }}
                />
              </Form.Item>
              <Table
                loading={loading}
                columns={itemImageColumns}
                dataSource={fileinfo}
                pagination={false}
              />
            </>
          )}
          <Divider />
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <BookingItemRuleset
        rulesetModalVisible={rulesetModalVisible}
        setRulesetModalVisible={setRulesetModalVisible}
        onUpdate={() => getBookingItemSelectionData()}
      />

      <CategoryInfoModal
        modalVisible={categoryModalVisible}
        setModalVisible={setCategoryModalVisible}
        selectedRecord={{ booking_category_id: 0 }}
        handleFormSubmit={handleCategorySubmit}
      />

      <ProductItemModal
        modalVisible={productItemModalVisible}
        setModalVisible={setProductItemModalVisible}
        selectedRecord={{ product_item_id: 0 }}
        handleFormSubmit={handleProductItemSubmit}
      />

    </NavAndSideFrame>
  );
};

const BookingItemRuleset = (props) => {
  let { rulesetModalVisible, setRulesetModalVisible, onUpdate } = props;
  const { t } = useTranslation();

  const [timeslotRc, setTimeslotRc] = useState({ booking_item_timeslot_id: 0 });

  const rulesetFormSubmit = async (formData) => {
    try {
      let method = formData.booking_item_timeslot_id === 0 ? "put" : "patch";
      let resp = await Service.call(
        method,
        "/api/booking_item/timeslot",
        formData
      );
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode),
        });
      }
      setTimeslotRc(resp.data);
      onUpdate();
      notification.success({
        message: t("success"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BookingItemRuleSetModal
      modalVisible={rulesetModalVisible}
      setModalVisible={setRulesetModalVisible}
      selectedRecord={timeslotRc}
      handleFormSubmit={rulesetFormSubmit}
    />
  );
};

export default ItemInfoForm;
