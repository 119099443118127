import React, {useState} from "react";
import { notification, Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const { Option } = Select;

const RegistrationTable = (props) => {
  const { dataSource, getAllData, setLoading, loading} = props;
  const { t } = useTranslation();
  const [registrationStatus, setRegistrationStatus] = useState("");

  const registrationStatusChange = async(value, record) => {
    const patchObj = {event_registration_id: record.event_registration_id, registration_status: value};
    const response = await Service.call("patch", "/api/registration_status", patchObj);
    if (response?.status !== 1) {
      return notification("Status Change Faild");
    }
    setRegistrationStatus(value);
    getAllData();
  };

  const setTableHeader = () => {
    return [{
      title: t("status"),
      dataIndex: "registration_status",
      render: (value, record) => {
        setRegistrationStatus(value);
        return (
          <Select value={registrationStatus} onChange={(val) => registrationStatusChange(val, record)}>
            <Option value="accept">{t("accept")}</Option>
            <Option value="reject">{t("reject")}</Option>
          </Select>
        );
      }
    },
    {
      title: t("name"),
      dataIndex: "nickname",
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
    }];
  };

  return (
    <Table
      dataSource={dataSource}
      columns={setTableHeader()}
    />
  );
};

export default RegistrationTable;
