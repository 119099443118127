import React, { useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Select, DatePicker, Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CompanyHolidayInfoModal = (props) => {
  const { t } = useTranslation();
  const { selectedRecord, dataList, modalVisible, setModalVisible, handleFormSubmit } =
    props;
  const [HolidayInfoForm] = Form.useForm();

  useEffect(() => {
    HolidayInfoForm.resetFields();
    if (selectedRecord.booking_item_holiday_id !== 0) {
      HolidayInfoForm.setFieldsValue({
        ...selectedRecord,
        start_time: moment.unix(selectedRecord.start_time),
      });
    }
  }, [selectedRecord]);

  const isDisable = (current) => {
    const holidayDates = _.map(dataList, (holiday) => moment(holiday.start_time * 1000).format('MM-DD'));
    const currentMonthAndDay = current.format('MM-DD');

    return holidayDates.includes(currentMonthAndDay);
  };

  return (
    <Modal
      title={
        selectedRecord.booking_item_holiday_id === 0
          ? t("add_new_record")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={HolidayInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        onFinish={(formData) => {
          handleFormSubmit({
            ...formData,
            start_time: moment(formData.start_time).startOf("d").unix(),
            end_time: moment(formData.start_time).endOf("d").unix(),
            booking_item_holiday_id: selectedRecord.booking_item_holiday_id,
          });
        }}
      >
        <Form.Item
          label={t("desc")}
          name="description"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Input maxLength={100} />
        </Form.Item>

        {selectedRecord.booking_item_holiday_id === 0 && (
          <Form.Item label={t("active_status")} name="is_active" initialValue={1}>
            <Select>
              <Option value={1}>{t("active")}</Option>
              <Option value={0}>{t("inactive")}</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={t("date")}
          name="start_time"
          rules={[{ required: true, message: t("select_required") }]}
        >
          <DatePicker disabledDate={isDisable} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CompanyHolidayInfoModal;
