import React, { useState, useEffect } from "react";
import {
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Collapse,
  Divider,
  Modal,
  Tag,
  Space,
  notification,
  Spin
} from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Service from "../../core/Service";
import TinymceWrapper from "../TinymceWrapper";
import EventCategoryForm from "./EventCategoryForm";
import { openNotificationWithIcon } from "../form/ErrorMessage";
import FormUploadImage from "../form/FormUploadImage";
import NavAndSideFrame from "../NavAndSideFrame";


const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;


const JsonPlaceholder = `[
  {
    "position": "top",
    "type": "checkbox",
    "required": "true",
    "message": "Please select checkbox.",
    "field": "Session",
    "options": [
      {
        "key": "morning",
        "value": "Morning Session (10:30 - 12:00)"
      },
      {
        "key": "afternoon",
        "value": "Afternoon Session (13:00 - 13:30)"
      }
    ]
  },
  {
    "field": "Industry/ Type of Business",
    "options": [
      {
        "key": "financial_services",
        "value": "Financial Services"
      },
      {
        "key": "accounting",
        "value": "Accounting"
      },
      {
        "key": "others",
        "value": "Other"
      }
    ]
  }
]`;

const EventForm = (props) => {
  const {
    dataObj,
    eventMeta,
    onSuccess = () => null,
    categories,
    getEventCategoryList,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const event_key = _.last(location.pathname.split("/")) || params.event_key;
  const { companyRc } = useSelector((state) => state.app.company);

  const [loading, setLoading] = useState(false);
  const [resetForm, setResetForm] = useState(true);

  const [longDesc, setLongDesc] = useState(null);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [metaValue, setMetaValue] = useState({});
  const [dataList, setDataList] = useState([]);
  const [periodList, setPeriodList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const history = useHistory();

  const [showLoginLogo, setShowLoginLogo] = useState(true);
  const [logoPath, setLogoPath] = useState(null);
  const [bannerPath, setBannerPath] = useState({
    0: { filepath: null, sorting: 1 },
  });

  useEffect(() => {
    setResetForm(true);
  }, [dataObj, eventMeta]);

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
      setLongDesc("");
      setShowLoginLogo(true);
      setLogoPath(null);
      setBannerPath({
        0: { filepath: null, sorting: 1 },
      });
      if (dataObj?.eventInfo?.event_id > 0) {
        setMetaValue(eventMeta);
        setLongDesc(dataObj?.eventInfo.event_description);
        setShowLoginLogo(dataObj?.eventInfo.show_login_logo);
        setLogoPath(dataObj?.eventInfo.event_logo);
        const banners = dataObj.eventBannerList;
        setBanner(banners);
        const formObj = _.clone(dataObj.eventInfo);
        form.setFieldsValue({
          ...formObj,
          event_category: _.map(dataObj.eventCategoryList, "event_category_id")
        });
      }
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    getEventCategoryList();
  }, []);

  const setBanner = (banners) => {
    const bannerList = {};
    _.map(banners, (banner, index) => {
      bannerList[index] = banner;
    });
    setBannerPath(bannerList);
  };


  const onFinish = async (formObj) => {
    try {
      const event_banner = [];
      _.forEach(bannerPath, (banner) => {
        if (!_.isNull(banner.filepath)) event_banner.push(banner);
      });
      const event_id = dataObj?.eventInfo?.event_id;
      let obj = {
        ...formObj,
        event_description: longDesc,
        event_logo: logoPath || "",
        event_banner,
        show_login_logo: _.toInteger(showLoginLogo),
        event_id,
      };
      setLoading(true);
      const url = `/api/event`;
      const method = dataObj?.eventInfo?.event_id > 0 ? "patch" : "post";

      let resp = await Service.call(method, url, obj);
      if (!resp || resp.status !== 1) {
        message.error(resp.errorMessage);
        return;
      }
      message.success("success");
      onSuccess(resp.data?.event_key);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const multiSelectWithNewItem = (list, addItemAction) => {
    return (
      <Select
        mode="multiple"
        allowClear
        placeholder={t("select")}
        options={list}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItemAction}>
              Add New Category
            </Button>
          </>
        )}
      />
    );
  };

  const onChange = async (info, setState, sorting = null) => {
    if (_.isEmpty(info)) return setState(null);
    const { status, response } = info.file;
    if (status === "done") {
      if (response.status > 0) {
        openNotificationWithIcon("success", t("common:upload_success"), "");
        _.isNil(sorting)
          ? setState(info.file.response.filepath)
          : setState(info.file.response.filepath, sorting);
      } else {
        openNotificationWithIcon("warning", t("common:uploaded_fail"), "");
      }
    }
  };

  const onBannerDelete = async (banner, updateBanner) => {
    if (banner.event_image_id === 0) {
      return updateBanner(null);
    }
    let resp = await Service.call("patch", "/api/event/image", {
      event_image_id: banner.event_image_id,
      event_key
    });
    if (!resp || resp.status !== 1) {
      return notification.error({
        message: t("error"),
        description: t(resp.errorCode),
      });
    }
    const { bannerList } = resp.data;
    setBanner(bannerList);
  };

  const renderEditor = (meta_key) => {
    return (
      <Form.Item name={meta_key} label={t(meta_key)}>
        <TinymceWrapper
          initialValue={metaValue[meta_key]}
          onChange={(value) => setMetaValue({ ...metaValue, [meta_key]: value })}
        />
      </Form.Item>
    );
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form"
        onFinish={onFinish}
        initialValues={{ show_login_logo: 1, event_banner: [{}] }}
        categories={categories}
        getEventCategoryList={getEventCategoryList}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item label={t("event_title_name")} name="event_name" rules={[{ required: true, message: t("input_required") }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label={t("event_key")} name="event_key" rules={[{ required: true, message: t("input_required") }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("event_category")}
              name="event_category"
              rules={[{ required: true, message: t("input_required") }]}
            >
              {multiSelectWithNewItem(categories, () => setCategoryModalVisible(true))}
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("event_type")}
              name="event_type"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <Select>
                <Option value="public">{t("public")}</Option>
                <Option value="private">{t("member")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("event_description")}
            >
              <TinymceWrapper initialValue={longDesc} onChange={setLongDesc} />
            </Form.Item>
          </Col>

          <Col span={24} className="event-banner-list">
            <div style={{ marginBottom: 8 }}>{t("event_logo")}</div>
            <FormUploadImage
              type="company"
              headers={[t("logo"), t("remove"), t("login_page_show_logo")]}
              imageURL={logoPath}
              actions={(
                <Tag color={showLoginLogo ? "green" : "red"} onClick={() => setShowLoginLogo(!showLoginLogo)}>
                  {showLoginLogo ? "Yes" : "No"}
                </Tag>
              )}
              onChange={(info) => onChange(info, setLogoPath)}
            />
          </Col>

          <Col span={24} className="event-banner-list">
            <div style={{ marginBottom: 8 }}>{t("event_banner_image")}</div>
            {_.map(_.keys(bannerPath), (bannerIndex) => {
              const banner = bannerPath[bannerIndex];
              const { filepath, sorting, event_image_id } = banner;

              function updateBanner(filepath, sorting) {
                const updateObj = _.clone(banner);
                updateObj.filepath = filepath;
                updateObj.sorting = sorting;
                setBannerPath({
                  ...bannerPath,
                  [bannerIndex]: updateObj,
                });
              }

              return (
                <FormUploadImage
                  key={bannerIndex}
                  type="banner"
                  headers={[t("event_banner"), t("operation"), t("sorting")]}
                  // title={bannerIndex === "0" ? t('event_banner') : ''}
                  imageURL={filepath}
                  onChange={(info, sorting) => onChange(info, updateBanner, sorting)}
                  sorting={sorting}
                  onDelete={() => onBannerDelete(banner, updateBanner)}
                />
              );
            })}
            {_.keys(bannerPath).length < 5 && (
              <Button
                type="dashed"
                onClick={() => setBannerPath({
                  ...bannerPath,
                  [_.keys(bannerPath).length]: {
                    event_image_id: 0,
                    filepath: null,
                    sorting: 1,
                  },
                })}
                block
                icon={<PlusOutlined />}
                style={{ marginBottom: 24 }}
              >
                {t("add_event_banner")}
              </Button>
            )}
          </Col>
          {/*
          {!_.isEmpty(dataObj) && (
            <Col span={24}>
              <Collapse
                bordered={false}
                defaultActiveKey={["basic"]}
                style={{ marginBottom: 40 }}
                expandIconPosition="right"
                className="no-padding-collapse"
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
              >
                <Panel header={t("advanced_setting")} key="advanced_setting">
                  <Row gutter={[16, 16]}>
                    {_.map(
                      [
                        "event_registration_info",
                        "event_registration_term",
                        "event_login_info",
                        "event_login_support",
                      ],
                      (formItem) => (
                        <Col xs={24} lg={12}>
                          {renderEditor(formItem)}
                        </Col>
                      )
                    )}

                    {_.map(
                      ["registration_option_list", "login_option_list"],
                      (formItem) => (
                        <Col xs={24} lg={12}>
                          <Form.Item
                            name={formItem}
                            label={t(formItem)}
                            rules={[
                              {
                                validator: (rule, value, callback) => {
                                  if (!value) {
                                    callback();
                                    return;
                                  }

                                  try {
                                    JSON.parse(value);
                                  } catch (e) {
                                    return callback(new Error("Not JSON"));
                                  }
                                  callback();
                                },
                              },
                            ]}
                          >
                            <TextArea rows={20} placeholder={JsonPlaceholder} />
                          </Form.Item>
                        </Col>
                      )
                    )}
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          )} */}

          <Col span={24}>
            <Space>
              {!_.isEmpty(dataObj) && (
                <Button onClick={() => setResetForm(true)}>{t("reset")}</Button>
              )}
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                >
                  {t("submit")}
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>

      <Modal
        title={t("add_category")}
        width="75%"
        visible={categoryModalVisible}
        onCancel={() => setCategoryModalVisible(false)}
        footer={null}
      >
        <EventCategoryForm
          onSuccess={() => {
            setCategoryModalVisible(false);
            getEventCategoryList();
          }}
        />
      </Modal>
    </Spin>

  );
};

export default EventForm;
