import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Spin, notification, message, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import * as Service from "../../core/Service";
import { formItemLayout, tailLayout } from "../ModalLayout";

const UserAddTokenForm = (props) => {
  const { dataObj, setAddTokenModalVisible, getAllData } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(dataObj);
  }, [dataObj]);
  const onFinish = async (postObj) => {
    setLoading(true);
    if (_.isEmpty(dataObj)) {
      return message.error(t("user ID not Found"));
    }
    postObj.quantity = _.toNumber((postObj.operand || "") + (postObj.quantity));
    postObj.company_user_id = dataObj.company_user_id;
    delete postObj.operand;
    let result = await Service.call("put", "/api/token/company/user", postObj);
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(result.errorCode),
      });
    }
    notification.success({ message: "Success!" });
    getAllData();
    setAddTokenModalVisible(false);
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        size="small"
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item label={t("name")} name="username">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("email")} name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("quantity")} required>
          <Input.Group compact>
            <Form.Item name="operand" noStyle>
              <Select
                defaultValue=""
                options={[
                  { label: "+", value: "" },
                  { label: "-", value: "-" }
                ]}
              />
            </Form.Item>
            <Form.Item
              noStyle
              rules={[{ required: true, message: t("please_input_token_number") }]}
              name="quantity"
            >
              <InputNumber
                min={0}
                style={{ width: 120 }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label={t("reason")}
          name="remark"
          rules={[{ required: true, message: t("please_input_reason") }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserAddTokenForm;
