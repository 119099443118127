import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Tabs,
  notification,
} from "antd";
// import 'antd/dist/antd.css';
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BlogPostListTable from "./BlogPostListTable";
import BlogPostModal from "./BlogPostModal";

const debug = require("debug")("app:admin:client:src:BlogPostList");

const involvedModelName = "company";
const selectedKey = "blog_post_item";
const tableIDName = "blog_post_id";
const openKeys = "blog";
const { TabPane } = Tabs;

const BlogPostList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("blog");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [keyByIdCategoryList, setKeyByIdCategoryList] = useState({
    blogPostCategory: {},
    newsCategory: {}
  });
  const title = t("blog_post");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let resp = await Service.call("get", "/api/blog/post");
    if (resp.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(resp.errorCode),
      });
    }
    const {
      blogPostCategoryArr,
      newsCategoryArr,
      blogPostArr
    } = resp.data;
    setKeyByIdCategoryList({
      blogPostCategory: _.keyBy(
        blogPostCategoryArr,
        "blog_post_category_id"
      ),
      newsCategory: _.keyBy(
        newsCategoryArr,
        "news_category_id"
      ),
    });
    const contentArr = _.filter(blogPostArr, (rc) => rc.type_name === activeKey);
    setDataList(blogPostArr);
    setFilterList(contentArr);
    setLoading(false);
  };

  const handleFormSubmit = (submitRecord) => {
    if (selectedRecord.blog_post_id === 0) {
      handlePutRecord(submitRecord);
    }
    if (selectedRecord.blog_post_id > 0) {
      handlePatchRecord(submitRecord);
    }
  };

  const handlePutRecord = async (submitRecord) => {
    setLoading(true);
    let submit = await Service.call("put", "/api/blog/post", submitRecord);
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData(activeKey);
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true);
    let patchData = {
      blog_post_id: selectedRecord.blog_post_id,
      ...submitRecord,
    };
    let submit = await Service.call("patch", "/api/blog/post", patchData);
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData(activeKey);
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true);
    let postData = {
      blog_post_id: deleteRecord,
    };
    let submit = await Service.call("delete", `/api/blog/post${postData.blog_post_id}`);
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (blog_post_id, value) => {
    setLoading(true);
    let postData = {
      blog_post_id,
      is_active: value,
    };
    let submit = await Service.call("patch", "/api/blog/post/is_active", postData);
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ blog_post_id: 0 });
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />

      <Tabs
        defaultActiveKey="blog"
        activeKey={activeKey}
        onChange={(key) => {
          const filterResult = _.filter((dataList), (rc) => rc.type_name === key);
          // console.log(keyByIdCategoryList);
          setFilterList(filterResult);
          setActiveKey(key);
        }}
      >
        <TabPane tab={t("blog")} key="blog">
          <BlogPostListTable
            keyByIdCategoryList={keyByIdCategoryList}
            dataObj={filterList}
            adminChangeData={getAllData}
            setSelectedRecord={(record) => setSelectedRecord(record)}
            handleDeleteRecord={(record) => handleDeleteRecord(record)}
            setModalVisible={(value) => setModalVisible(value)}
            isActiveChangeCallBack={(blog_post_id, value) => handleIsActiveChange(blog_post_id, value)}
            loading={loading}
          />
        </TabPane>
        <TabPane tab={t("news")} key="news">
          <BlogPostListTable
            keyByIdCategoryList={keyByIdCategoryList}
            dataObj={filterList}
            adminChangeData={getAllData}
            setSelectedRecord={(record) => setSelectedRecord(record)}
            handleDeleteRecord={(record) => handleDeleteRecord(record)}
            setModalVisible={(value) => setModalVisible(value)}
            isActiveChangeCallBack={(blog_post_id, value) => handleIsActiveChange(blog_post_id, value)}
            loading={loading}
          />
        </TabPane>
      </Tabs>
      <BlogPostModal
        keyByIdCategoryList={keyByIdCategoryList}
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getAllData();
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default BlogPostList;
