import _ from "lodash";
import React, { Component, useEffect } from "react";
import QrReader from "react-qr-scanner";
import { message } from "antd";


const EventQRScanner = (props) => {
  const { onScan, facingMode } = props;

  return (
    <div className="container" style={{ width: "100%" }}>
      <div
        className="QRtitle"
        style={{ margin: "20px 0" }}
      >
        Scan to Check-in
      </div>
      <div style={{
        width: "100%",
        // backgroundImage: `url(${QRcodeboarder3})`,
        marginBottom: "50px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

      }}
      >
        <QrReader
          style={{
            border: "10px solid #1E73BE",
            borderRadius: "15px",
            // objectFit: "fill",
            width: "100%",
            transform: facingMode === "user" ? "scaleX(-1)" : "scaleX(1)"
          }}
          constraints={{
            video: {
              facingMode: facingMode === "user" ? facingMode : { exact: facingMode }
            }
          }}
          onScan={(obj = {}) => {
            if (_.isEmpty(obj)) {
              return;
            }
            const {
              text = "",
            } = obj;

            if (!text) {
              return;
            }
            onScan(text);
          }}
        />
      </div>
    </div>
  );
};


export default EventQRScanner;
