import _ from 'lodash';
import moment from 'moment';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import { getStore } from '../redux/store/configureStore';
import * as Service from './Service'

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Tag } from "antd";
import React from "react";

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    })
  })
}

export function convertOrderInfoDisplayData(orderInfoObject) {
  //  Basic Info
  let [prefixMobile, owner_mobile] = _.split(orderInfoObject.owner_mobile, '-');
  if (!_.isEmpty(prefixMobile)) orderInfoObject.prefixMobile = prefixMobile;
  if (!_.isEmpty(owner_mobile)) orderInfoObject.owner_mobile = owner_mobile;
  if (_.isInteger(orderInfoObject.pick_up_time)) orderInfoObject.pick_up_time = moment.unix(orderInfoObject.pick_up_time);

  //  Backup Info
  if (!_.isEmpty(orderInfoObject.pet_birthday)) orderInfoObject.pet_birthday = moment(orderInfoObject.pet_birthday);
  let [contactPersonMobilePrefix, contact_person_mobile] = _.split(orderInfoObject.contact_person_mobile, '-');
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contactPersonMobilePrefix = contactPersonMobilePrefix;
  if (!_.isEmpty(contactPersonMobilePrefix)) orderInfoObject.contact_person_mobile = contact_person_mobile;

  //  Deposit
  if (_.isInteger(orderInfoObject.payment_date)) orderInfoObject.payment_date = moment.unix(orderInfoObject.payment_date);

  //  Service Detail
  if (_.isInteger(orderInfoObject.meet_up_time)) orderInfoObject.meet_up_time = moment.unix(orderInfoObject.meet_up_time);
  if (_.isInteger(orderInfoObject.ceremony_time)) orderInfoObject.ceremony_time = moment.unix(orderInfoObject.ceremony_time);
  if (_.isInteger(orderInfoObject.ashes_pick_up_time)) orderInfoObject.ashes_pick_up_time = moment.unix(orderInfoObject.ashes_pick_up_time);
  if (!_.isEmpty(orderInfoObject.ceremony_hair_treatment)) {
    orderInfoObject.ceremony_hair_treatment = JSON.parse(orderInfoObject.ceremony_hair_treatment);
  } else {
    orderInfoObject.ceremony_hair_treatment = []
  }

  //  Ashes Space
  if (_.isInteger(orderInfoObject.ashes_space_start_date)) orderInfoObject.ashes_space_start_date = moment.unix(orderInfoObject.ashes_space_start_date);
  if (_.isInteger(orderInfoObject.ashes_space_end_date)) orderInfoObject.ashes_space_end_date = moment.unix(orderInfoObject.ashes_space_end_date);

  //  Plant Space
  if (_.isInteger(orderInfoObject.plant_space_start_date)) orderInfoObject.plant_space_start_date = moment.unix(orderInfoObject.plant_space_start_date);
  if (_.isInteger(orderInfoObject.plant_space_end_date)) orderInfoObject.plant_space_end_date = moment.unix(orderInfoObject.plant_space_end_date);

  return orderInfoObject
}

export async function fetchFormOption(type) {
  let results = await Service.call('get', `/api/form_option/${type}`);
  // console.log('results', results);
  if (results.status !== 1) {
    return;
  }

  let options = results.data.map((item) => {
    return { value: item.name }
  })

  return options
}

export async function exportToCSV(dataList, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(dataList);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function exportTableToCSV(dataList, fileName) {
  const header = dataList.length >= 0
    ? [_.map(dataList[0], (value, key) => { return key; })]
    : [[]];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, header);
  XLSX.utils.sheet_add_json(ws, dataList, { skipHeader: true, origin: "A2" });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function exportCsvFile(dataList, header, fileName, dataStartRow = "A2") {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let [firstRc] = dataList;

  // Flex length of excel columns
  let wscols = _.map(Object.keys(firstRc), (eachKey) => {
    let maxLengthRc = _.maxBy(dataList, (eachData) => {
      return _.toString(eachData[eachKey]).length;
    });

    if (_.isUndefined(maxLengthRc[eachKey])) {
      return;
    }

    let maxLength = maxLengthRc[eachKey].length;
    if (maxLength > eachKey.length) {
      return { width: maxLength };
    }
    return { width: eachKey.length };
  });

  const worksheet = XLSX.utils.json_to_sheet(dataList);
  worksheet["!cols"] = wscols;

  XLSX.utils.sheet_add_aoa(worksheet, header);
  XLSX.utils.sheet_add_json(worksheet, dataList, { skipHeader: true, origin: dataStartRow });
  const wb = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export function intToBinary(num, largest_length) {
  let number = num;
  let result = [];
  // Find out the binary number format of the let 'num'
  while (number >= 1) {
    result.unshift(Math.floor(number % 2));
    number /= 2;
  }
  // Fill the zero missed
  while (result.length < largest_length) {
    result.unshift(0);
  }
  return result;
}

export const ITEM_TYPE = {
  BOOKING: 1,
  PRODUCT: 2,
  EVENT: 3,
  MEMBERSHIP: 4,
  TOKEN: 5,
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 1,
  ACTUAL_PRICE: 2
};

export const DAY_IN_SECONDS = 86400;

export const displayOrderStatus = (value, t) => {
  let displayStr = value ? t(value) : t("invalid");
  let tagColor = renderBackgroundColor(value);
  return <Tag color={tagColor}>{displayStr}</Tag>;
};

export const renderBackgroundColor = (status) => {
  let backgroundColor = "";
  switch (status) {
    case "placed":
      backgroundColor = "pink";
      break;
    case "pending":
    case "pending_approve":
      backgroundColor = "orange";
      break;
    case "payment_confirmed":
      backgroundColor = "green";
      break;
    case "payment_refund":
    case "payment_refund_token":
    case "payment_refund_offline":
    case "partial_payment_refund":
    case "partial_payment_refund_token":
    case "partial_payment_refund_offline":
      backgroundColor = "geekblue";
      break;
    case "payment_failed":
    case "cancelled":
    case "voided":
    case "cancel_after_payment":
      backgroundColor = "red";
      break;
    case "expired":
      backgroundColor = "grey";
      break;
    default:
      backgroundColor = "geekblue";
      break;
  }
  return backgroundColor;
};
