import React, { useState, useEffect } from "react";
import {
  Tabs,
  Spin,
  notification,
  Divider
} from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import UserAcInfoForm from "../../../components/UserAcInfoForm";
import T9Button from "../../../t9UIComponents/t9Button";
import BookingUserList from "./CompanyUserBooking";
import EventUserList from "./CompanyUserEvent";
import TokenUserList from "./CompanyUserToken";

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const selectedKey = "user_list";
const openKeys = "user";

const CompanyUserAcContainer = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const {
    userInfo,
    companyConfigRc
  } = location.state || {};
  let query = useQuery();
  let defaultKey = query.get("key") || 1;
  const [userObj, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState({
    userMembershipList: [],
    orderItemList: [],
    eventCheckInList: [],
    tokenLogList: []
  });
  useEffect(() => {
    fetchUser();
  }, []);


  const fetchUser = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", "/api/company/user/detail", {
        company_user_id: userInfo.company_user_id || defaultKey
      });
      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      const {
        userDetail: {
          eventCheckInList,
          orderItemList,
          userInfo: userData,
          userMembershipList,
          tokenLogList
        }
      } = resp.data;
      setUser(userData);
      setDataList({
        userMembershipList,
        orderItemList,
        eventCheckInList,
        tokenLogList
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      {...props}
      title={userObj.company_user_id > 0 ? `${t("edit")} ${t("user")}` : `${t("add")} ${t("user")}`}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <T9Button
        onClick={() => history.goBack()}
      >
        {t("back")}
      </T9Button>
      <Divider />
      <Spin size="large" spinning={loading}>
        <Tabs type="card" defaultActiveKey={defaultKey} onChange={(key) => null}>
          <TabPane tab={t("user_info")} key={1}>
            <UserAcInfoForm
              initialValues={userObj}
              companyConfigRc={companyConfigRc}
              isAdmin
              setUser={setUser}
            />
          </TabPane>
          {/* {userObj.company_user_id > 0 && (
            <TabPane tab={t("membership")} key={3}>
              <MembershipUserList
                dataList={dataList.userMembershipList}
              />
            </TabPane>
          )} */}

          {userObj.company_user_id > 0 && (
            <TabPane tab={t("booking")}>
              <BookingUserList
                orderItemList={dataList.orderItemList}
              />
            </TabPane>
          )}
          {userObj.company_user_id > 0 && (
            <TabPane tab={t("event")} key={5}>
              <EventUserList
                orderItemList={dataList.orderItemList}
                checkInList={dataList.eventCheckInList}
              />
            </TabPane>
          )}

          {userObj.company_user_id > 0 && (
            <TabPane tab={t("token")} key={6}>
              <TokenUserList
                orderItemList={dataList.orderItemList}
                tokenLogList={dataList.tokenLogList}
              />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyUserAcContainer;
