import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Select, Tag, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CalendarOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _, { times } from "lodash";
import COLOR from "../../theme/color";
import CalendarTimeSlotModal from "./CalendarTimeSlotModal";
import moment from "moment";
import * as Service from "../../core/Service";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const CreateBookingOrderForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const [bookingDetails, setBookingDetails] = useState({});
  const [companyUserList, setCompanyUserList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setSubmitLoading(true);
      let order_detail_list = [];
      let company_user_id = values.booking_order_list[0].company_user_id;
      _.map(values.booking_order_list, (order) => {
        const { booking_item_id, booking_timeslot_list } = order;
        _.map(
          booking_timeslot_list,
          ({ start_time, end_time, bookingProduct, capacity }) => {
            order_detail_list.push({
              item_type: 1,
              item_id: booking_item_id,
              start_time,
              end_time,
              capacity,
              bookingProduct: _.map(
                bookingProduct,
                ({ product_item_id, quantity }) => ({
                  product_item_id,
                  quantity,
                })
              ),
            });
            return;
          }
        );
      });

      const postObj = {
        company_user_id,
        order_detail_list,
        item_type: 1,
      };
      let url = `/api/order`;
      const resp = await Service.call("post", url, postObj);
      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      history.push("/company/order/list");
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  const getBookingItemList = async () => {
    try {
      setLoading(true);
      let url = `/api/booking_item/list`;
      const resp = await Service.call("get", url);
      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      const { bookingCategoryArr, bookingCategoryRefArr, bookingItemList } =
        resp?.data;

      const activeBookingCategoryArr = _.filter(bookingCategoryArr, {
        is_active: 1,
        is_delete: 0,
      });
      const activeBookingItemList = _.filter(bookingItemList, {
        is_active: 1,
        is_delete: 0,
      });
      const keyByBookingItemsId = _.keyBy(
        activeBookingItemList,
        "booking_item_id"
      );

      const bookingItemDetailByCategory = _.map(
        bookingCategoryRefArr,
        (item) => {
          const { item_name } =
            keyByBookingItemsId[item?.booking_item_id] || {};
          if (!item_name) return;
          return { ...item, booking_item_name: item_name };
        }
      );

      const bookingItemsByCategory = _.groupBy(
        bookingItemDetailByCategory,
        "booking_category_id"
      );
      setBookingDetails({
        bookingCategoryArr: activeBookingCategoryArr,
        bookingItemsByCategory,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getCompanyUserList = async () => {
    try {
      setLoading(true);
      let url = "/api/company/user/by/company";
      const resp = await Service.call("get", url);
      if (!resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setCompanyUserList(_.filter(resp, (user) => user?.is_active));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBookingItemList();
  }, []);

  useEffect(() => {
    getCompanyUserList();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="form"
        onFinish={onFinish}
        className="booking-order-form"
        initialValues={{
          booking_order_list: [
            {
              company_user_id: "",
              booking_category: "",
              booking_item_name: "",
              booking_timeslot_list: [],
            },
          ],
        }}
      >
        <Form.List name="booking_order_list">
          {(fields, { add, remove }) => (
            <>
              {/* {_.isEmpty(fields) && add()} */}
              {fields.map(({ key, name, ...restField }, index) => (
                <BookingForm
                  users={companyUserList}
                  data={bookingDetails}
                  key={key}
                  fields={fields}
                  index={index}
                  name={name}
                  listName="booking_order_list"
                  remove={remove}
                  form={form}
                />
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined style={{ color: "#1E73BE" }} />}
                  style={{
                    color: "#1E73BE",
                    borderColor: "#1E73BE",
                  }}
                >
                  {t("add_new_booking")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item style={{ textAlign: "right" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateBookingOrderForm;

const BookingForm = ({
  key,
  users,
  fields,
  index,
  remove,
  name,
  data,
  form,
  listName,
}) => {
  const { t } = useTranslation();
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [confirmTimeSlot, setConfirmTimeSlot] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoomCategory, setSelectedRoomCategory] = useState(null);
  const { bookingCategoryArr, bookingItemsByCategory } = data;

  useEffect(() => {
    const orgFormObj = form.getFieldsValue();
    const updateObj = _.clone(orgFormObj[listName]);
    updateObj[name]["booking_timeslot_list"] = confirmTimeSlot;
    form.setFieldsValue({
      ...orgFormObj,
      [listName]: updateObj,
    });
  }, [confirmTimeSlot]);

  const handleRoomCategoryChange = (value) => {
    const orgFormObj = form.getFieldsValue();
    const updateObj = _.clone(orgFormObj[listName]);
    updateObj[name]["booking_item_id"] = "";
    form.setFieldsValue({
      ...orgFormObj,
      [listName]: updateObj,
    });
    setSelectedRoomCategory(value);
  };

  return (
    <>
      <Row
        className="booking-row"
        align="start"
        style={
          fields.length > 0 && {
            marginBottom: 48,
            border: `1px solid ${COLOR.border}`,
            borderRadius: 8,
          }
        }
      >
        {index > 0 && (
          <Col span={24} style={{ padding: 16 }}>
            <Row justify="end">
              <Col>
                {fields.length > 1 && (
                  <CloseOutlined onClick={() => remove(name)} />
                )}
              </Col>
            </Row>
          </Col>
        )}

        {index === 0 && (
          <Col span={24}>
            <Form.Item
              label={t("select_user")}
              name={[name, "company_user_id"]}
              rules={[
                {
                  required: true,
                  message: t("missing_field_type"),
                },
              ]}
            >
              <Select>
                {_.map(
                  users,
                  ({ company_user_id, nickname, first_name, username }) => (
                    <Option key={company_user_id} value={company_user_id}>
                      {nickname || first_name || username}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={24}>
          <Form.Item
            label={t("select_booking_category")}
            name={[name, "booking_category"]}
            rules={[
              {
                required: true,
                message: t("missing_field_type"),
              },
            ]}
          >
            <Select onChange={handleRoomCategoryChange}>
              {_.map(
                bookingCategoryArr,
                ({ booking_category_id, category_name }) => (
                  <Option key={booking_category_id} value={booking_category_id}>
                    {category_name}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("select_booking")}
            name={[name, "booking_item_id"]}
            rules={[
              {
                required: true,
                message: t("missing_field_type"),
              },
            ]}
          >
            <Select
              disabled={_.isNil(selectedRoomCategory)}
              onChange={(value) => {
                setSelectedRoom(value);
                setConfirmTimeSlot([]);
              }}
            >
              {!_.isNil(selectedRoomCategory) && (
                <>
                  {_.map(
                    bookingItemsByCategory[selectedRoomCategory] || {},
                    ({ booking_item_id, booking_item_name }) => (
                      <Option key={booking_item_id} value={booking_item_id}>
                        {booking_item_name}
                      </Option>
                    )
                  )}
                </>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("select_booking_timeslot")}
            name={[name, "booking_timeslot_list"]}
            rules={[
              {
                required: true,
                message: t("missing_field_type"),
              },
            ]}
          >
            <div
              style={{
                minHeight: 32,
                border: "1px solid #d9d9d9",
                padding: "4px",
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {_.map(confirmTimeSlot, (timeSlot, i) => {
                  return (
                    <TimeSlotTag
                      key={timeSlot?.id}
                      currentIndex={i}
                      selectedRoom={selectedRoom}
                      timeslot={timeSlot}
                      confirmTimeSlot={confirmTimeSlot}
                      setConfirmTimeSlot={setConfirmTimeSlot}
                    />
                  );
                })}
              </div>
              <Button
                className="calendar-btn"
                disabled={
                  _.isNil(selectedRoomCategory) || _.isNil(selectedRoom)
                }
                onClick={() => selectedRoom && setCalendarVisible(true)}
              >
                <CalendarOutlined
                  style={{
                    color: "#B0AFAF",
                    cursor: "pointer",
                  }}
                />
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <CalendarTimeSlotModal
        type="add"
        selectedRoom={selectedRoom}
        modalVisible={calendarVisible}
        setModalVisible={setCalendarVisible}
        selectedTimeSlot={{}}
        confirmTimeSlot={confirmTimeSlot}
        setConfirmTimeSlot={setConfirmTimeSlot}
        date={moment()}
      />
    </>
  );
};

const TimeSlotTag = (props) => {
  const { currentIndex, selectedRoom, timeslot, confirmTimeSlot, setConfirmTimeSlot } = props;
  const [calendarVisible, setCalendarVisible] = useState(false);
  const { id, start_time, end_time } = timeslot;

  const handleTimeSlotCancel = (id) => {
    setConfirmTimeSlot((prev) =>
      _.filter(prev, (timeSlot) => timeSlot.id !== id)
    );
  };
  return (
    <>
      <Tag
        color="blue"
        key={`timeslot_${currentIndex}`}
        closable
        onClose={() => handleTimeSlotCancel(id)}
        style={{ borderRadius: 4, margin: "4px" }}
        onClick={() => setCalendarVisible(true)}
      >
        {`${moment.unix(start_time).format("YYYY-MM-DD HH:mm")} - ${moment
          .unix(end_time)
          .format("HH:mm")}`}
      </Tag>
      <CalendarTimeSlotModal
        currentIndex={currentIndex}
        selectedRoom={selectedRoom}
        modalVisible={calendarVisible}
        setModalVisible={setCalendarVisible}
        selectedTimeSlot={timeslot}
        setConfirmTimeSlot={setConfirmTimeSlot}
        date={start_time ? moment.unix(start_time) : moment()}
      />
    </>
  );
};
